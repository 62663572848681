import { useMemo } from 'react';

import { GeofeatureFolderItem } from '@/features/Geofeatures/interfaces';
import { GeofeaturesList } from '@/features/Geofeatures/components/GeofeaturesList';

import {
  FolderWithGeofeatures,
  GeofeaturesByFolderListProps,
} from './GeofeaturesByFolderList.types';
import { FolderName } from './GeofeaturesByFolderList.styles';

export const GeofeaturesByFolderList = ({
  folders,
  geofeatures,
  selectedGeofeaturesIds,
  onGeofeatureSelect,
  onGeofeatureVisibilityToggle,
  onGeofeatureEditClick,
}: GeofeaturesByFolderListProps) => {
  const foldersWithGeofeaturesList = useMemo<Array<FolderWithGeofeatures>>(() => {
    const foldersSet = new Set<GeofeatureFolderItem>();

    geofeatures.forEach(geofeature => {
      const folder = folders.find(folderItem => folderItem.id === geofeature.folderId);

      if (folder) {
        foldersSet.add(folder);
      }
    });

    const foldersList = Array.from(foldersSet).sort((a, b) => a.name.localeCompare(b.name));

    return foldersList.map(folder => {
      const geofeaturesList = geofeatures.filter(geofeature => geofeature.folderId === folder.id);

      return {
        ...folder,
        geofeatures: geofeaturesList,
      };
    });
  }, [folders, geofeatures]);

  return foldersWithGeofeaturesList.map(folder => (
    <div key={folder.id}>
      <FolderName>{folder.name}</FolderName>
      <GeofeaturesList
        geofeatures={folder.geofeatures}
        selectedGeofeaturesIds={selectedGeofeaturesIds}
        onGeofeatureSelect={onGeofeatureSelect}
        onGeofeatureVisibilityToggle={onGeofeatureVisibilityToggle}
        onGeofeatureEditClick={onGeofeatureEditClick}
      />
    </div>
  ));
};

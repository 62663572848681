import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 425px;
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.21);
  border-radius: 12px;
  padding: 40px;
  background-color: ${({ theme }) => theme.colors.modalBackground};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

import styled from 'styled-components';

import { getVariant } from '@/themes/typographyUtilis';

export const Container = styled.div`
  position: relative;
  width: 676px;
  box-shadow: 0 4px 74px 0 #00000040;
  border-radius: 22px;
  padding: 32px 37px;
  background: ${({ theme }) => theme.colors.modalBackground};
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 4px;
  background: none;
  cursor: pointer;
`;

export const Header = styled.header`
  display: flex;
`;

export const SearchContainer = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
`;

export const SearchInput = styled.input`
  border: none;
  padding: 0;
  background: transparent;
  color: ${({ theme }) => theme.colors.secondaryText};
  ${getVariant({ variant: 'body1-normal' })}

  &::placeholder {
    color: ${({ theme }) => theme.colors.secondaryText};
  }

  &:focus {
    outline: none;
  }
`;

export const IconsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 25px;
`;

export const ColorButton = styled.button<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border: none;
  border-radius: 6px;
  padding: 0;
  background: ${({ isActive, theme }) => (isActive ? theme.colors.border : 'transparent')};
  cursor: pointer;

  path {
    fill: ${({ isActive, theme }) =>
      isActive ? theme.colors.primaryText : theme.colors.secondaryText};
  }
`;

export const ButtonsContainer = styled.footer`
  display: flex;
  gap: 10px;
  margin-top: 70px;
`;

import { createContext, useCallback, useEffect, useState } from 'react';
import { MapRef } from 'react-map-gl/maplibre';

import { MapProviderProps } from './MapContext.types';

export type MapContextType = {
  mapRef: MapRef | null;
  isMapLoaded: boolean;
  disableMapHoverInteraction?: boolean;
  setMapRef: (ref: MapRef | null) => void;
  setDisableMapHoverInteraction: (disable: boolean) => void;
};

export const MapContext = createContext<MapContextType>({
  mapRef: null,
  isMapLoaded: false,
  disableMapHoverInteraction: false,
  setMapRef: () => {},
  setDisableMapHoverInteraction: () => {},
});

export const MapProvider = ({ children }: MapProviderProps) => {
  const [mapRef, setMapRef] = useState<MapRef | null>(null);
  const [disableMapHoverInteraction, setDisableMapHoverInteraction] = useState<boolean>(false);
  const [isMapLoaded, setIsMapLoaded] = useState<boolean>(false);

  const setMapRefHandler = useCallback<MapContextType['setMapRef']>(ref => {
    if (ref) {
      setMapRef(ref);
    } else {
      setIsMapLoaded(false);
    }
  }, []);

  useEffect(() => {
    if (mapRef) {
      mapRef.on('load', () => {
        setIsMapLoaded(true);
      });
    }
  }, [mapRef]);

  return (
    <MapContext.Provider
      value={{
        mapRef,
        isMapLoaded,
        disableMapHoverInteraction,
        setMapRef: setMapRefHandler,
        setDisableMapHoverInteraction,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

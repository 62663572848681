import { useMemo } from 'react';

import { decodeHtml } from '@/utils/helpers';

export function useDecodedCategories(categories: Array<string>) {
  const decodedCategories = useMemo(() => {
    return categories.map(decodeHtml);
  }, [categories]);

  return decodedCategories;
}

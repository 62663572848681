import styled from 'styled-components';

export const RadiusRow = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 20px;
`;

export const RadiusInput = styled.div`
  width: 82px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 70px;
`;

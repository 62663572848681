import { useCallback, useState } from 'react';

import { InputLabel } from '@/core/components/FormElements.styles';

import { ColorPickerProps } from './ColorPicker.types';
import { PickedColor, PickedColorContainer } from './ColorPicker.styles';
import { ColorsModal } from './components/ColorsModal';

export const ColorPicker = ({ color, label, onColorChange }: ColorPickerProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onPickerClick = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const onModalClose = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  return (
    <div>
      {label && <InputLabel>{label}</InputLabel>}
      <PickedColorContainer>
        <PickedColor
          type="button"
          onClick={onPickerClick}
          color={color}
        />
      </PickedColorContainer>
      <ColorsModal
        isOpen={isModalVisible}
        color={color}
        onColorApply={onColorChange}
        onClose={onModalClose}
      />
    </div>
  );
};

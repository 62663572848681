import { Modal } from '@/core/components/Modal/Modal';
import { Heading } from '@/core/components/Typography';
import { Button, ButtonVariant } from '@/core/components/Button';

import { Container, ButtonsContainer } from './DeleteModal.styles';
import { DeleteModalProps } from './DeleteModal.types';
import { OverlayStyle } from '../Modal/Modal.types';

export const DeleteModal = ({
  isOpen,
  onClose,
  title,
  content,
  confirmButtonText,
  onDelete,
}: DeleteModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      overlayStyle={OverlayStyle.BLUR}
    >
      <Container>
        <Heading type="h1">{title}</Heading>
        {content}
        <ButtonsContainer>
          <Button
            variant={ButtonVariant.WARNING}
            onClick={onDelete}
          >
            {confirmButtonText}
          </Button>
          <Button
            variant={ButtonVariant.TERTIARY}
            onClick={onClose}
          >
            CANCEL
          </Button>
        </ButtonsContainer>
      </Container>
    </Modal>
  );
};

import { useTheme } from 'styled-components';

import { MultiButtonGroup, MultiButtonItem } from './MultiButton.styles';
import { MultiButtonProps, MultiButtonValueType } from './MultiButton.types';
import { Icon } from '../Icons/Icon';

export const MultiButton = <MultiButtonValue extends MultiButtonValueType>({
  isMulti,
  isDisabled,
  active,
  tabsData,
  onChange,
  isSmall = false,
}: MultiButtonProps<MultiButtonValue>) => {
  const { colors } = useTheme();

  return (
    <MultiButtonGroup isSmall={isSmall}>
      {tabsData.map(({ label, value, iconName }) => {
        const isActive = isMulti ? active.includes(value) : active === value;

        return (
          <MultiButtonItem
            type="button"
            disabled={isDisabled}
            key={value}
            isSmall={isSmall}
            isActive={isActive}
            onClick={() => {
              if (isMulti) {
                if (isActive) {
                  onChange(active.filter(activeItem => activeItem !== value));
                } else {
                  onChange([...active, value]);
                }
              } else {
                onChange(value);
              }
            }}
          >
            {iconName && (
              <Icon
                name={iconName}
                color={isActive ? colors.primaryText : colors.secondaryText}
              />
            )}
            {label}
          </MultiButtonItem>
        );
      })}
    </MultiButtonGroup>
  );
};

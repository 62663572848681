import styled from 'styled-components';

import { Button } from '@/core/components/Button';
import { ASIDE_MENU_Z_INDEX } from '@/core/constants/zIndex';

export const ASIDE_WIDTH = '252px';
export const HEADER_HEIGHT = '64px';

export const PageHeader = styled.header`
  display: flex;
  align-items: center;
  height: ${HEADER_HEIGHT};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.background.medium};
`;

export const PageHeaderLogo = styled.div`
  flex-shrink: 0;
  width: ${ASIDE_WIDTH};
  padding: 0 0 0 20px;
`;

export const Main = styled.main<{ isFullWidth: boolean }>`
  position: relative;
  display: grid;
  grid-template-columns: ${({ isFullWidth }) =>
    isFullWidth ? 'auto' : `${ASIDE_WIDTH} calc(100% - ${ASIDE_WIDTH})`};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background.dark};
`;

export const PageAsideMenu = styled.aside`
  z-index: ${ASIDE_MENU_Z_INDEX};
  overflow: auto;
  height: calc(100vh - ${HEADER_HEIGHT});
  box-shadow: 3px 0 11px 0 rgba(45, 62, 89, 0.08);
  background-color: ${({ theme }) => theme.colors.background.dark};
  scrollbar-width: thin;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 38px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const PageContent = styled.div`
  position: relative;
  height: 100%;
`;

import styled from 'styled-components';

import { ThemeVariant } from '@/core/interfaces/common';

export const StyledContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

export const StyledFilterButton = styled.button.attrs({ type: 'button' })<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  outline: none;
  border: 1px solid
    ${({ isActive, theme }) => (isActive ? theme.colors.focuses.active : theme.colors.border)};
  border-radius: 10px;
  padding: 9px 14px;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.focuses.active : 'transparent'};
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.focuses.activeButtonText : theme.colors.secondaryText};
  transition: all 0.3s;
  cursor: pointer;
`;

export const ClearButton = styled.button.attrs({ type: 'button' })`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  outline: none;
  border: none;
  padding: 9px 14px;
  background-color: transparent;
  color: ${({ theme }) =>
    theme.theme === ThemeVariant.DARK ? theme.colors.secondaryText : theme.colors.teal};
  font-weight: 500;
  cursor: pointer;
`;

export const FilterAmountBadge = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.focuses.activeButtonText};
  font-size: 10px;
`;

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  dropdownTimeframeOptions,
  timeframeCustomTimeData,
  timeframeRadioButtonData,
} from '@/core/components/MapFilters/TimeframeFilter/timeframeRadioButtonData';
import {
  SelectTimeframeOptionType,
  TimeframeDateTabsProps,
  TimeframeDropdownOptions,
  TimeframeFilterItem,
  TimeframeFilterProps,
  TimeframeTabViewType,
} from '@/core/components/MapFilters/TimeframeFilter/TimeframeFilter.types';
import { AppliedFilterData, FilterType } from '@/core/interfaces/filters';
import { MultiButton, MultiButtonItem } from '@/core/components/MultiButton';
import {
  applyFilter,
  getAppliedFilterSelector,
  isFilterOpenedSelector,
} from '@/core/store/reducers/filtersSlice';
import { useAppDispatch } from '@/core/store/store';

import { formatToDefaultDate } from '@/utils/helpers';

import { PublicationDateTimeframeView } from './components/PublicationDateTimeframeView/PublicationDateTimeframeView';
import { EventDateTimeframeView } from './components/EventDateTimeframeView/EventDateTimeframeView';
import { FilterContainer } from '../../FilterContainer';
import { FilterWrapper } from './TimeframeFilter.styles';

import 'react-datepicker/dist/react-datepicker.css';

const timeframeFilterDateTabs: Array<MultiButtonItem<TimeframeTabViewType>> = [
  {
    label: 'Publication date',
    value: TimeframeTabViewType.PUBLICATION_DATE,
  },
  {
    label: 'Event date',
    value: TimeframeTabViewType.EVENT_DATE,
  },
];

const todaysDate = new Date();
const tomorrowsDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000); //tomorrows date

const radioButtonDefaultValue = timeframeCustomTimeData[0].value;
const eventDateDefaultOption = dropdownTimeframeOptions[0].value;

export const TimeframeFilter = ({ timeframeFiltersData }: TimeframeFilterProps) => {
  const appliedFilters = useSelector(state =>
    getAppliedFilterSelector(state, FilterType.TIMEFRAME)
  );
  const dispatch = useAppDispatch();

  const setAppliedFilters = useCallback(
    (filterToApply?: TimeframeFilterItem | TimeframeDateTabsProps) => {
      let filterData: AppliedFilterData = [];

      if (filterToApply) {
        filterData = [
          {
            label: filterToApply.name as string,
            value: filterToApply.value === null ? '' : String(filterToApply.value),
          },
        ];
      }

      dispatch(
        applyFilter({
          filterType: FilterType.TIMEFRAME,
          filterData,
        })
      );
    },
    [dispatch]
  );

  const isFilterVisible = useSelector(state => isFilterOpenedSelector(state, FilterType.TIMEFRAME));
  const [selectedFilter, setSelectedFilter] = useState<TimeframeFilterItem | undefined>(
    timeframeFiltersData[0]
  );

  const [selectedDate, setSelectedDate] = useState(timeframeFilterDateTabs[0]);
  const [datepickerVisibility, setDatepickerVisibility] = useState(false);
  const [isInlineDatepickerVisible, setIsInlineDatepickerVisible] = useState(false);
  const [radioButtonValue, setRadioButtonValue] = useState(radioButtonDefaultValue);
  const [radioButtonInputValue, setRadioButtonInputValue] = useState(0);

  const [startDate, setStartDate] = useState<Date | null>(todaysDate);
  const [endDate, setEndDate] = useState<Date | null>(tomorrowsDate);
  const [eventDateOption, setEventDateOption] = useState<number>(eventDateDefaultOption);

  const showDatepicker = () => setDatepickerVisibility(true);
  const hideDatepicker = () => setDatepickerVisibility(false);
  const hideInlineDatepicker = () => setIsInlineDatepickerVisible(false);

  const applyFilters = useCallback(() => {
    let filterToSet: TimeframeFilterItem | TimeframeDateTabsProps | undefined = undefined;
    let filterToApply: TimeframeFilterItem | TimeframeDateTabsProps | undefined = undefined;

    if (selectedDate.value === TimeframeTabViewType.PUBLICATION_DATE) {
      filterToSet = selectedFilter;
      filterToApply = selectedFilter;

      if (selectedFilter?.value === 'custom') {
        const radioButtonPickedOption = timeframeCustomTimeData.find(
          option => option.value === radioButtonValue
        );

        if (!radioButtonPickedOption) {
          return;
        }

        const filterValue = `${radioButtonInputValue} ${radioButtonPickedOption.label}`;

        const radioButtonFilterToApply = {
          label: filterValue,
          name: filterValue,
          value: filterValue,
        };

        filterToApply = radioButtonFilterToApply;
      }

      setEventDateOption(eventDateDefaultOption);
      setStartDate(todaysDate);
      setEndDate(tomorrowsDate);
      hideDatepicker();
    }

    if (selectedDate.value === TimeframeTabViewType.EVENT_DATE) {
      if (datepickerVisibility) {
        const datepickerValue = `${formatToDefaultDate(startDate)} - ${formatToDefaultDate(endDate)}`;

        const datepickerFilterToSet = {
          label: datepickerValue,
          name: datepickerValue,
          value: datepickerValue,
        };

        filterToApply = datepickerFilterToSet;
        filterToSet = datepickerFilterToSet;
      } else {
        const dropdownPickedOption = dropdownTimeframeOptions.find(
          option => option.value === eventDateOption
        );

        const dropdownFilterToSet = {
          label: dropdownPickedOption?.label,
          name: dropdownPickedOption?.label,
          value: `${eventDateOption}`,
        };

        filterToApply = dropdownFilterToSet;
        filterToSet = dropdownFilterToSet;
      }

      setRadioButtonValue(radioButtonDefaultValue);
      setRadioButtonInputValue(0);
    }

    if (filterToSet === undefined || filterToApply === undefined) return;

    setSelectedFilter(filterToSet);
    setAppliedFilters(filterToApply);
  }, [
    selectedDate.value,
    setAppliedFilters,
    selectedFilter,
    radioButtonInputValue,
    radioButtonValue,
    datepickerVisibility,
    startDate,
    endDate,
    eventDateOption,
  ]);

  const handleEventDateDropdownChange = (selectedOption: SelectTimeframeOptionType) => {
    setEventDateOption(selectedOption.value);

    if (selectedOption.value === TimeframeDropdownOptions.CUSTOM) {
      showDatepicker();
    } else {
      hideDatepicker();
    }
  };

  const onFilterRemove = () => {
    setSelectedFilter(timeframeFiltersData[0]);
    setAppliedFilters(undefined);
    setRadioButtonValue(radioButtonDefaultValue);
    setRadioButtonInputValue(0);
    setStartDate(todaysDate);
    setEndDate(tomorrowsDate);
    setEventDateOption(eventDateDefaultOption);
    hideDatepicker();
  };

  const onFilterReset = () => {
    if (!appliedFilters && isFilterVisible) {
      setSelectedFilter(timeframeFiltersData[0]);
      setSelectedDate(timeframeFilterDateTabs[0]);
      setRadioButtonValue(radioButtonDefaultValue);
      setRadioButtonInputValue(0);
      setStartDate(todaysDate);
      setEndDate(tomorrowsDate);
      hideDatepicker();
    }
  };

  const filterModalTitle = useMemo(() => {
    if (isInlineDatepickerVisible) {
      return 'CUSTOM TIMEFRAME';
    }

    return 'Timeframe';
  }, [isInlineDatepickerVisible]);

  useEffect(() => {
    if (appliedFilters.length === 0) {
      setSelectedFilter(timeframeFiltersData[0]);
      setSelectedDate(timeframeFilterDateTabs[0]);
      setDatepickerVisibility(false);
      setIsInlineDatepickerVisible(false);
      setRadioButtonValue(radioButtonDefaultValue);
      setRadioButtonInputValue(0);
      setStartDate(todaysDate);
      setEndDate(tomorrowsDate);
      setEventDateOption(eventDateDefaultOption);
    } else {
      const appliedFilter = appliedFilters[0];

      // Set state for event date custom timeframe
      if (typeof appliedFilter.value === 'string' && appliedFilter.value.includes(' - ')) {
        const filterDates = appliedFilter.value.split(' - ');

        setSelectedDate(timeframeFilterDateTabs[1]);
        setEventDateOption(
          dropdownTimeframeOptions.find(option => option.value === TimeframeDropdownOptions.CUSTOM)!
            .value
        );
        setDatepickerVisibility(true);
        setStartDate(new Date(filterDates[0]));
        setEndDate(new Date(filterDates[1]));
        // Set state for event date predefined options
      } else if (dropdownTimeframeOptions.some(option => option.label === appliedFilter.label)) {
        setSelectedDate(timeframeFilterDateTabs[1]);
        setEventDateOption(Number(appliedFilter.value));
        // Set state for publication date predefined options
      } else if (timeframeRadioButtonData.some(option => option.name === appliedFilter.label)) {
        setSelectedFilter(
          timeframeRadioButtonData.find(option => option.name === appliedFilter.label)
        );
        // Set state for publication date custom option
      } else {
        const [customTimeFrameTime, customTimeFrameUnit] = (appliedFilter.value as string).split(
          ' '
        );

        setSelectedFilter(timeframeRadioButtonData.find(option => option.name === 'Custom'));
        setRadioButtonValue(
          timeframeCustomTimeData.find(option => option.name === customTimeFrameUnit)!.value
        );
        setRadioButtonInputValue(Number(customTimeFrameTime));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilters]);

  return (
    <FilterContainer
      filterType={FilterType.TIMEFRAME}
      filterName="Timeframe"
      onFiltersApply={applyFilters}
      onAppliedFilterRemove={onFilterRemove}
      onFiltersToggle={onFilterReset}
      shouldShowBackButton={isInlineDatepickerVisible}
      shouldHideApplyButton={isInlineDatepickerVisible}
      modalTitle={filterModalTitle}
      onFiltersBackClick={hideInlineDatepicker}
    >
      <FilterWrapper>
        {!isInlineDatepickerVisible && (
          <MultiButton
            isSmall
            active={selectedDate.value}
            tabsData={timeframeFilterDateTabs}
            onChange={value =>
              setSelectedDate(timeframeFilterDateTabs.find(tab => tab.value === value)!)
            }
          />
        )}

        {selectedDate.value === TimeframeTabViewType.PUBLICATION_DATE && (
          <PublicationDateTimeframeView
            appliedFilter={selectedFilter ? selectedFilter : timeframeFiltersData[0]}
            setAppliedFilter={setSelectedFilter}
            radioButtonValue={radioButtonValue}
            setRadioButtonValue={setRadioButtonValue}
            radioButtonInputValue={radioButtonInputValue}
            setRadioButtonInputValue={setRadioButtonInputValue}
          />
        )}

        {selectedDate.value === TimeframeTabViewType.EVENT_DATE && (
          <EventDateTimeframeView
            datepickerVisibility={datepickerVisibility}
            isInlineDatepickerVisible={isInlineDatepickerVisible}
            dropdownOption={eventDateOption}
            startDateProp={startDate}
            endDateProp={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            showDatepicker={showDatepicker}
            hideDatepicker={hideDatepicker}
            handleDropdownChange={handleEventDateDropdownChange}
            dropdownTimeframeOptions={dropdownTimeframeOptions}
            setIsInlineDatepickerVisible={setIsInlineDatepickerVisible}
          />
        )}
      </FilterWrapper>
    </FilterContainer>
  );
};

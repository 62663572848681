import styled from 'styled-components';

import { TextBodyNormal } from '@/core/components/Typography/Typography.styles';

export const CheckboxWrapper = styled.label<{ isReducedWidth?: boolean }>`
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  width: ${({ isReducedWidth }) => (isReducedWidth ? 'auto' : '100%')};
  cursor: pointer;
`;

export const CheckboxFakeInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
  transition: 0.25s ease;

  svg {
    display: none;

    path {
      fill: ${({ theme }) => theme.colors.primaryText};
    }
  }

  input:checked + & {
    border-color: ${({ theme }) => theme.colors.teal};
    background: ${({ theme }) => theme.colors.teal};

    svg {
      display: block;
    }
  }

  ${/* sc-selector */ CheckboxWrapper}:hover & {
    border-color: ${({ theme }) => theme.colors.teal};
  }
`;

export const CheckboxLabel = styled(TextBodyNormal).attrs({ as: 'span' })<{
  htmlFor: string;
}>`
  color: ${({ theme }) => theme.colors.secondaryText};
`;

export const CheckboxInput = styled.input`
  position: absolute;
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
`;

import { timeframeRadioButtonData } from '@/core/components/MapFilters/TimeframeFilter/timeframeRadioButtonData';
import { MultiButton } from '@/core/components/MultiButton';

import { CustomTimeframeRadioGroupView } from '../CustomTimeframeRadioGroupView/CustomTimeframeRadioGroupView';
import { PublicationDateTimeframeViewProps } from './PublicationDateTimeframeView.types';
import { Title } from './PublicationDateTimeframeView.styles';

export const PublicationDateTimeframeView = ({
  appliedFilter,
  setAppliedFilter,
  radioButtonValue,
  setRadioButtonValue,
  radioButtonInputValue,
  setRadioButtonInputValue,
}: PublicationDateTimeframeViewProps) => {
  const isRadiogroupVisible = appliedFilter?.value === 'custom' || false;

  return (
    <>
      <Title>Publication time of the report</Title>
      <MultiButton
        active={appliedFilter?.value || ''}
        tabsData={timeframeRadioButtonData}
        onChange={value =>
          setAppliedFilter(timeframeRadioButtonData.find(item => item.value === value))
        }
      />
      {isRadiogroupVisible && (
        <CustomTimeframeRadioGroupView
          radioButtonValue={radioButtonValue}
          setRadioButtonValue={setRadioButtonValue}
          radioButtonInputValue={radioButtonInputValue}
          setRadioButtonInputValue={setRadioButtonInputValue}
        />
      )}
    </>
  );
};

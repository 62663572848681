import { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { createPortal } from 'react-dom';

import { Icon } from '@/core/components/Icons';
import { Button } from '@/core/components/Button';
import { TextSidebarNormalBold } from '@/core/components/Typography/Typography.styles';
import {
  getAppliedFilterSelector,
  isFilterOpenedSelector,
  openFilter,
} from '@/core/store/reducers/filtersSlice';
import { useAppDispatch } from '@/core/store/store';

import { TOOLBAR_ANIMATION_TIME } from '@/features/Map/components/Toolbar/Toolbar.styles';
import { openFocusModal } from '@/features/Focus/store';

import {
  FilterApplyButtonWrapper,
  FilterButton,
  FilterHeader,
  FilterListItem,
  FilterListItemRemoveIcon,
  FilterListItemWrapper,
  FilterModalCloseButton,
  FilterModalViewBackButton,
  FiltersModal,
} from './FilterContainer.styles';
import { FilterContainerProps } from './FilterContainer.types';

export const FilterContainer = ({
  filterType,
  shouldShowBackButton = false,
  shouldHideApplyButton = false,
  filterName,
  modalTitle,
  children,
  onFiltersToggle,
  onFiltersApply,
  onFiltersBackClick,
  onAppliedFilterRemove,
}: FilterContainerProps) => {
  const filtersModalRef = useRef<HTMLDivElement>(null);
  const appliedFilters = useSelector(state => getAppliedFilterSelector(state, filterType));
  const isContentVisible = useSelector(state => isFilterOpenedSelector(state, filterType));
  const dispatch = useAppDispatch();
  const { colors, useAnimations } = useTheme();

  const handleFilterToggle = useCallback(() => {
    if (onFiltersToggle) {
      onFiltersToggle();
    }

    if (isContentVisible) {
      dispatch(openFilter(null));
    } else {
      dispatch(openFocusModal(null));
      dispatch(openFilter(filterType));
    }
  }, [dispatch, filterType, isContentVisible, onFiltersToggle]);

  const handleFilterApply = useCallback(() => {
    onFiltersApply();
    dispatch(openFilter(null));
  }, [dispatch, onFiltersApply]);

  const mainElement = document.querySelector('main');

  return (
    <div>
      <FilterButton
        isActive={isContentVisible}
        onClick={handleFilterToggle}
      >
        {filterName}
        <Icon
          name="ChevronRight"
          size={12}
        />
      </FilterButton>
      {appliedFilters.length > 0 ? (
        <FilterListItemWrapper>
          {appliedFilters.map((item, index) => (
            <FilterListItem key={typeof item.value === 'string' ? item.value : index + 1}>
              {item.label}
              {onAppliedFilterRemove && (
                <FilterListItemRemoveIcon
                  color={colors.white}
                  size={12}
                  onClick={() => onAppliedFilterRemove(item)}
                />
              )}
            </FilterListItem>
          ))}
        </FilterListItemWrapper>
      ) : null}
      <CSSTransition
        mountOnEnter
        unmountOnExit
        key={filterType}
        in={isContentVisible}
        nodeRef={filtersModalRef}
        timeout={useAnimations ? TOOLBAR_ANIMATION_TIME * 1000 : 0}
      >
        <>
          {mainElement &&
            createPortal(
              <FiltersModal
                ref={filtersModalRef}
                data-filter-type={filterType}
              >
                <FilterHeader>
                  {shouldShowBackButton && onFiltersBackClick && (
                    <FilterModalViewBackButton onClick={onFiltersBackClick}>
                      <Icon name="ArrowLeft" />
                    </FilterModalViewBackButton>
                  )}
                  <TextSidebarNormalBold>{modalTitle || filterName}</TextSidebarNormalBold>
                  {!shouldShowBackButton && (
                    <FilterModalCloseButton onClick={handleFilterToggle}>
                      <Icon name="Close" />
                    </FilterModalCloseButton>
                  )}
                </FilterHeader>
                {children}
                {!shouldHideApplyButton && (
                  <FilterApplyButtonWrapper>
                    <Button onClick={handleFilterApply}>APPLY</Button>
                  </FilterApplyButtonWrapper>
                )}
              </FiltersModal>,
              mainElement
            )}
        </>
      </CSSTransition>
    </div>
  );
};

import { ChangeEvent, MouseEvent, useCallback, useContext } from 'react';
import { useTheme } from 'styled-components';
import { bbox } from '@turf/turf';

import { Checkbox } from '@/core/components/Checkbox';
import { Icon } from '@/core/components/Icons';
import { Badge } from '@/core/components/Badge';
import { MapContext } from '@/core/context/MapContext';
import { GEOJSONShapeType } from '@/core/interfaces/geojsons';
import { POINT_OF_INTEREST_ZOOM } from '@/core/constants/map';

import {
  ActionButton,
  GeofeatureItem,
  GeofeatureItemIconWrapper,
  GeofeatureItemName,
  GeofeatureNameWithTagsWrapper,
  GeofeatureOptionalDescription,
  InfoContainer,
  TagsContainer,
} from './GeofeatureListItem.styles';
import { GeofeatureListItemProps } from './GeofeatureListItem.types';
import { GeofeatureMenu } from '../GeofeaturesMenu/GeofeatureMenu';

export const GeofeatureListItem = ({
  id,
  name,
  description,
  tags,
  color: _color,
  icon,
  geoJSONData,
  isSelected,
  onSelect,
  isVisibleOnMap,
  onGeofeatureVisibilityToggle,
  onGeofeatureEditClick,
}: GeofeatureListItemProps) => {
  const { mapRef } = useContext(MapContext);
  const { colors } = useTheme();

  const handleItemClick = useCallback(() => {
    const map = mapRef?.getMap();

    if (!map) {
      return;
    }

    if (geoJSONData.properties.shape === GEOJSONShapeType.POI) {
      map.flyTo({
        center: geoJSONData.geometry.coordinates as [number, number],
        zoom: POINT_OF_INTEREST_ZOOM,
      });

      return;
    }

    // @ts-expect-error bbox has correct values
    map.fitBounds(bbox(geoJSONData), {
      padding: {
        top: 200,
        right: 200,
        bottom: 200,
        left: 600,
      },
    });
  }, [geoJSONData, mapRef]);

  const handleVisibilityToggle = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      onGeofeatureVisibilityToggle(id, !isVisibleOnMap);
    },
    [id, isVisibleOnMap, onGeofeatureVisibilityToggle]
  );

  const handleCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();

      onSelect(id);
    },
    [id, onSelect]
  );

  const handleGeofeatureEditClick = useCallback(() => {
    onGeofeatureEditClick(geoJSONData);
  }, [geoJSONData, onGeofeatureEditClick]);

  return (
    <GeofeatureItem
      isVisibleOnMap={isVisibleOnMap}
      onClick={handleItemClick}
    >
      <InfoContainer>
        <Checkbox
          isChecked={isSelected}
          onChange={handleCheckboxChange}
          isReducedWidth
          value={id}
        />
        <div>
          <GeofeatureNameWithTagsWrapper>
            <GeofeatureItemName>
              {icon && (
                <Icon
                  name={icon}
                  size={18}
                  color={colors.secondaryText}
                />
              )}
              {name}
            </GeofeatureItemName>
            <TagsContainer>
              {tags.map(tag => (
                <Badge
                  key={tag}
                  isNormalcase
                  backgroundColor={colors.filter.filterBadgeBgColor}
                  text={tag}
                />
              ))}
            </TagsContainer>
          </GeofeatureNameWithTagsWrapper>
          {description && (
            <GeofeatureOptionalDescription color={colors.secondaryText}>
              {description}
            </GeofeatureOptionalDescription>
          )}
        </div>
        <GeofeatureItemIconWrapper>
          <GeofeatureMenu
            geofeatureId={id}
            geoJSONData={geoJSONData}
            onGeofeatureEditClick={handleGeofeatureEditClick}
          />
          <ActionButton
            type="button"
            onClick={handleVisibilityToggle}
          >
            <Icon
              color={colors.secondaryText}
              name={isVisibleOnMap ? 'Eye' : 'EyeSlash'}
              size={20}
            />
          </ActionButton>
        </GeofeatureItemIconWrapper>
      </InfoContainer>
    </GeofeatureItem>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '@/features/RiskAssessment/api';
import {
  RiskAssessmentCountryDetails,
  RiskAssessmentListCountryItem,
} from '@/features/RiskAssessment/interfaces';

import { getActionPrefix } from '@/utils/helpers';

const actionPrefix = getActionPrefix('riskAssessment');

export const getRiskAssessments = createAsyncThunk<Array<RiskAssessmentListCountryItem>>(
  `${actionPrefix}/getRiskAssessments`,
  async () => {
    const { data } = await api.getRiskAssessments();

    return data;
  }
);

export const getRiskAssessmentDetails = createAsyncThunk<RiskAssessmentCountryDetails, string>(
  `${actionPrefix}/getRiskAssessmentDetails`,
  async iso2 => {
    const { data } = await api.getRiskAssessmentDetails(iso2);

    return data;
  }
);

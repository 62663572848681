import styled from 'styled-components';

export const ReportMeta = styled.section`
  display: flex;
  gap: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding: 48px 0;
`;

export const ReportMetaColumn = styled.div`
  padding: 0 48px;
`;

export const ReportMetaRow = styled.div`
  display: grid;
  grid-template-columns: 92px auto;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 20px;
`;

export const CategoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
`;

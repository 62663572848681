import styled from 'styled-components';

import { getVariant } from '@/themes/typographyUtilis';

import { DropdownPosition } from './Dropdown.types';

export const DropdownWrapper = styled.div`
  position: relative;
`;

export const DropdownMenu = styled.ul<{
  offsetY?: number | string;
  offsetX?: number | string;
  dropdownZIndex?: number;
  position?: DropdownPosition;
  maxWidth?: string;
}>`
  position: absolute;
  top: ${({ offsetY }) => (typeof offsetY === 'number' ? `${offsetY}px` : offsetY)};
  right: ${({ offsetX, position }) =>
    position === DropdownPosition.LEFT
      ? typeof offsetX === 'number'
        ? `${offsetX}px`
        : offsetX
      : undefined};
  left: ${({ offsetX, position }) =>
    position === DropdownPosition.RIGHT
      ? typeof offsetX === 'number'
        ? `${offsetX}px`
        : offsetX
      : undefined};
  z-index: ${({ dropdownZIndex }) => dropdownZIndex || 100};
  max-width: ${({ maxWidth }) => maxWidth};
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.21);
  margin: 0;
  border-radius: 16px;
  padding: 10px 0;
  background-color: ${({ theme }) => theme.colors.background.dark};
  list-style: none;
`;

export const DropMenuItem = styled.li<{ withSeparator?: boolean }>`
  margin: 0;
  border-top: ${({ withSeparator, theme }) =>
    withSeparator ? `1px solid ${theme.colors.border}` : 'none'};
  padding: 10px 30px;
  white-space: nowrap;
  cursor: pointer;
`;

export const DropMenuItemLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
  ${getVariant({ variant: 'body1-normal' })}
  color: ${({ theme }) => theme.colors.secondaryText};

  > * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const NestedItemsIconContainer = styled.div`
  margin-left: auto;
`;

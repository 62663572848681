import { useEffect, useState, useRef, useCallback } from 'react';

import { useOnClickOutside } from '@/core/hooks/useOnClickOutside';
import { Input } from '@/core/components/Input';
import { Icon } from '@/core/components/Icons';
import { useAppDispatch } from '@/core/store/store';
import { CoworkerList } from '@/core/components/CoworkerList';
import { useDebounce } from '@/core/hooks/useDebounce';
import { webAppRoles } from '@/core/constants/constants';

import { getOrganizations, getUsers } from '@/features/Auth/store';

import { ShareInputProps, Coworker } from './ShareInput.types';
import { SearchWrapper, SuggestionsList } from './ShareInput.styles';
import { SuggestionItem } from './components/SuggestionItem';

export const ShareInput = ({
  isDisabled = false,
  selectedCoworkers,
  selectedOrganizations,
  onUserSelect,
  onOrganizationSelect,
  handleRemoveSelectedCoworker,
  handleRemoveSelectedOrganization,
}: ShareInputProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState<Array<Coworker>>([]);
  const [organizationsSuggestions, setOrganizationsSuggestions] = useState<Array<Coworker>>([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const dispatch = useAppDispatch();

  const searchBarRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(searchBarRef, () => {
    setSuggestions([]);
    setOrganizationsSuggestions([]);
  });

  const handleSearch = useCallback(
    async (term: string) => {
      if (term.length >= 2) {
        const usersPromise = dispatch(getUsers({ userSearch: term })).unwrap();
        const organizationsPromise = dispatch(
          getOrganizations({ organizationSearch: term })
        ).unwrap();

        const [usersResponse, organizationsResponse] = await Promise.allSettled([
          usersPromise,
          organizationsPromise,
        ]);

        if (usersResponse.status === 'fulfilled') {
          setSuggestions(
            usersResponse.value?.map(user => ({
              email: user.email,
              name: `${user.firstName} ${user.lastName}`,
              id: user.id,
              photoUrl: user.profilePicture || '/assets/avatar-placeholder.png',
              role: user.groups.filter(role => webAppRoles.includes(role))[0],
            }))
          );
        }

        if (organizationsResponse.status === 'fulfilled') {
          setOrganizationsSuggestions(
            organizationsResponse.value?.map(organization => ({
              email: organization.description,
              name: organization.name,
              id: organization.id,
              photoUrl: '/assets/avatar-placeholder.png',
              role: 'Organization',
            }))
          );
        }
      }
    },
    [dispatch]
  );

  const onSelectUserSuggestion = useCallback(
    (suggestionId: string) => {
      setSearchTerm('');
      const user = suggestions.find(suggestion => suggestion.id === suggestionId)!;

      onUserSelect(user);
      // setSelectedUsersFromSuggestions(prev => [...prev, user]);
    },
    [onUserSelect, suggestions]
  );

  const onSelectOrganizationSuggestion = useCallback(
    (suggestionId: string) => {
      setSearchTerm('');
      const organization = organizationsSuggestions.find(
        suggestion => suggestion.id === suggestionId
      )!;

      onOrganizationSelect(organization);
      // setSelectedOrganizationsFromSuggestions(prev => [...prev, organization]);
    },
    [onOrganizationSelect, organizationsSuggestions]
  );

  useEffect(() => {
    handleSearch(debouncedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <>
      <SearchWrapper ref={searchBarRef}>
        <Input
          disabled={isDisabled}
          placeholder="Search for a user or an organization"
          autoComplete="off"
          suffixIcon={<Icon name="Search" />}
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
        {!isDisabled && (suggestions.length > 0 || organizationsSuggestions.length > 0) && (
          <SuggestionsList>
            {organizationsSuggestions.map(suggestion => (
              <SuggestionItem
                key={suggestion.id}
                id={suggestion.id}
                email={suggestion.email}
                name={suggestion.name}
                photoUrl={suggestion.photoUrl}
                role={suggestion.role}
                onSelectSuggestion={onSelectOrganizationSuggestion}
              />
            ))}
            {suggestions.map(suggestion => (
              <SuggestionItem
                key={suggestion.id}
                id={suggestion.id}
                email={suggestion.email}
                name={suggestion.name}
                photoUrl={suggestion.photoUrl}
                role={suggestion.role}
                onSelectSuggestion={onSelectUserSuggestion}
              />
            ))}
          </SuggestionsList>
        )}
      </SearchWrapper>
      {selectedOrganizations.length > 0 && (
        <CoworkerList
          isDisabled={isDisabled}
          coworkers={selectedOrganizations}
          handleRemoveCoworker={handleRemoveSelectedOrganization}
        />
      )}
      {selectedCoworkers.length > 0 && (
        <CoworkerList
          isDisabled={isDisabled}
          coworkers={selectedCoworkers}
          handleRemoveCoworker={handleRemoveSelectedCoworker}
        />
      )}
    </>
  );
};

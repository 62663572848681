import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import { useAppDispatch } from '@/core/store/store';
import {
  getAllAppliedFiltersSelector,
  openFilter,
  resetFilters,
} from '@/core/store/reducers/filtersSlice';
import { FocusItem } from '@/core/components/FocusItem/FocusItem';

import {
  clearDraftFocus,
  getSelectedFocusSelector,
  openFocusModal,
  selectFocus,
} from '@/features/Focus/store';

import { Container } from './ResetFiltersButton.styles';

export const ResetFiltersButton = () => {
  const appliedFilters = useSelector(getAllAppliedFiltersSelector);
  const selectedFocus = useSelector(getSelectedFocusSelector);

  const dispatch = useAppDispatch();

  const areFiltersApplied = useMemo(
    () => Object.values(appliedFilters).some(array => array.length > 0) || !!selectedFocus,
    [appliedFilters, selectedFocus]
  );

  const handleResetFilters = useCallback(async () => {
    dispatch(resetFilters());
    dispatch(openFilter(null));
    dispatch(selectFocus(null));
    // We have to wait for modal to close before clearing draft focus
    // eslint-disable-next-line @typescript-eslint/await-thenable
    unwrapResult(await dispatch(openFocusModal(null)));
    dispatch(clearDraftFocus());
  }, [dispatch]);

  return (
    <Container>
      <FocusItem
        shouldHideEditButton
        name="Reset filters"
        isSelected={!areFiltersApplied}
        onClick={handleResetFilters}
      />
    </Container>
  );
};

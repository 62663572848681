import {
  RiskAssessmentCountryDetails,
  RiskAssessmentListCountryItem,
} from '@/features/RiskAssessment/interfaces';

import { request } from '@/utils/request';
import { makeServerSideRequest } from '@/utils/serverSideRequest';

export const makeApi = (instance = request) => ({
  getCategories: () => instance.get('risk-assessment/categories/'),
  getRiskAssessments: () => instance.get<Array<RiskAssessmentListCountryItem>>('risk-assessment/'),
  getRiskAssessmentDetails: (iso2: string) =>
    instance.get<RiskAssessmentCountryDetails>(`risk-assessment/${iso2}/`),
});

export const api = makeApi();
export const makeServerSideApi = (token: string) => makeApi(makeServerSideRequest(token));

import { useCallback, useState } from 'react';

import { Button } from '@/core/components/Button';
import { FieldErrorMessage } from '@/core/components/TextField/TextField.styles';
import { Textarea } from '@/core/components/Textarea';

import { isGeoJSON, isGeoJSONValid, parseGeoJSON } from '@/utils/geoJSONImport';

import { ButtonContainer } from './GeoJSONImportForm.styles';
import { GeoJSONImportFormProps } from './GeoJSONImportForm.types';
import { Loader } from '../Loader';

export const GeoJSONImportForm = ({
  placeholder,
  isLoading = false,
  additionalValidatorFn,
  onSubmit,
}: GeoJSONImportFormProps) => {
  const [codeValue, setCodeValue] = useState('');
  const [isValueValid, setIsValueValid] = useState(true);

  const onCodeChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setIsValueValid(true);
    setCodeValue(event.target.value);
  }, []);

  const onImport = useCallback(() => {
    const isJson = isGeoJSON(codeValue);
    let isValid = codeValue !== '';

    if (isJson) {
      isValid = isGeoJSONValid(parseGeoJSON(codeValue));
    } else {
      const importRows = codeValue.split('\n');
      const headerRowColumns = importRows[0].split('\t');
      const columnsCount = importRows
        .map(line => line.split('\t').length)
        .reduce((acc, columnCount, index) => (acc + columnCount) / (index === 0 ? 1 : 2), 0);

      isValid =
        columnsCount >= 2 &&
        Number.isInteger(columnsCount) &&
        ['Name', 'Address'].every(headerText => headerRowColumns.includes(headerText));
    }

    if (additionalValidatorFn && isValid) {
      isValid = additionalValidatorFn({
        importValue: codeValue,
        isJson,
      });
    }

    setIsValueValid(isValid);

    if (!isValid) {
      return;
    }

    onSubmit({
      importValue: codeValue,
      isJson,
    });
  }, [additionalValidatorFn, codeValue, onSubmit]);

  return (
    <div>
      <Textarea
        placeholder={placeholder}
        rows={11}
        value={codeValue}
        onChange={onCodeChange}
        withError={!isValueValid}
      />
      {!isValueValid && <FieldErrorMessage>Invalid import data</FieldErrorMessage>}
      <ButtonContainer>
        <Button
          disabled={isLoading}
          onClick={onImport}
        >
          {isLoading ? <Loader size="20px" /> : 'IMPORT'}
        </Button>
      </ButtonContainer>
    </div>
  );
};

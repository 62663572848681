import { useState, useEffect, useCallback, ChangeEvent, KeyboardEvent } from 'react';
import { useTheme } from 'styled-components';

import { Input } from '@/core/components/Input';
import { Dropdown } from '@/core/components/Dropdown';
import { Icon } from '@/core/components/Icons';
import { Paragraph } from '@/core/components/Typography';
import { ButtonVariant } from '@/core/components/Button';
import { IconButton } from '@/core/components/IconButton';

import { RowContainer, DropdownControl } from './KeywordsFilterRow.styles';
import { KeywordsFilterRowProps, DropdownValues } from './KeywordsFilterRow.types';

export const KeywordsFilterRow = ({
  onAddRow,
  onRemoveRow,
  onValuesChange,
  inputInitialValue = '',
  onDropdownValueChange,
  dropdownInitialValue = DropdownValues.AND,
}: KeywordsFilterRowProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(dropdownInitialValue);
  const [inputValue, setInputValue] = useState(inputInitialValue);
  const { colors } = useTheme();

  const handleSelect = useCallback(
    (value: DropdownValues) => {
      setDropdownValue(value);
      setIsDropdownOpen(false);
      onDropdownValueChange(value);
    },
    [onDropdownValueChange]
  );

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  }, []);

  const menuItems = Object.values(DropdownValues).map(value => ({
    label: value,
    onClick: () => handleSelect(value),
  }));

  const handleAddRow = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter' && onAddRow) {
        onAddRow();
      }
    },
    [onAddRow]
  );

  useEffect(() => {
    onValuesChange(inputValue, dropdownValue);
  }, [inputValue, dropdownValue]);

  return (
    <RowContainer>
      <Input
        placeholder="Enter keywords"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleAddRow}
        autoFocus
      />
      <Dropdown
        isOpen={isDropdownOpen}
        setIsOpen={setIsDropdownOpen}
        control={
          <DropdownControl>
            <Paragraph
              type="normal"
              color={colors.secondaryText}
            >
              {dropdownValue}
            </Paragraph>
            <Icon
              name="ChevronDown"
              color={colors.secondaryText}
              size={16}
            />
          </DropdownControl>
        }
        menuItems={menuItems}
        offsetY={46}
        offsetX={-2}
      />
      <IconButton
        variant={ButtonVariant.WARNING}
        name="Trash"
        onClick={onRemoveRow}
      >
        <Icon name="Trash" />
      </IconButton>
    </RowContainer>
  );
};

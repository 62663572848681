import { GeofeatureFolderItem } from '@/features/Geofeatures/interfaces/geofeatures';

export enum FolderModalType {
  EDIT,
  ADD,
}

export type GeofeatureFolderModalProps = {
  isOpen: boolean;
  onClose: () => void;
  type?: FolderModalType;
  folder?: GeofeatureFolderItem;
  onFolderCreate?: (newFolderId: number) => void;
};

import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTheme } from 'styled-components';

import { useOnClickOutside } from '@/core/hooks/useOnClickOutside';
import { Icon } from '@/core/components/Icons';

import { DropdownProps, DropdownPosition } from './Dropdown.types';
import {
  DropdownWrapper,
  DropdownMenu,
  DropMenuItem,
  NestedItemsIconContainer,
  DropMenuItemLabel,
} from './Dropdown.styles';

export const Dropdown = ({
  isOpen,
  setIsOpen,
  control,
  menuItems,
  offsetY = 0,
  offsetX = 0,
  usePortal = false,
  dropdownZIndex,
  position = DropdownPosition.RIGHT,
  maxWidth,
}: DropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { colors } = useTheme();

  const [isNestedMenuOpen, setIsNestedMenuOpen] = useState(false);

  useOnClickOutside(dropdownRef, () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 250);
  });

  const getOffsetX = useCallback(() => {
    if (typeof offsetX === 'string') {
      return offsetX;
    }

    return (usePortal ? dropdownRef.current?.getBoundingClientRect().left || 0 : 0) + offsetX;
  }, [offsetX, usePortal]);
  const getOffsetY = useCallback(() => {
    if (typeof offsetY === 'string') {
      return offsetY;
    }

    return (usePortal ? dropdownRef.current?.getBoundingClientRect().top || 0 : 0) + offsetY;
  }, [offsetY, usePortal]);

  const [dropdownOffsetX, setDropdownOffsetX] = useState(getOffsetX());
  const [dropdownOffsetY, setDropdownOffsetY] = useState(getOffsetY());

  useEffect(() => {
    setDropdownOffsetX(getOffsetX());
    setDropdownOffsetY(getOffsetY());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    const handleResize = () => {
      setDropdownOffsetX(getOffsetX());
      setDropdownOffsetY(getOffsetY());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDropdownControllerClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();

      setIsOpen(!isOpen);
    },
    [isOpen, setIsOpen]
  );

  return (
    <DropdownWrapper ref={dropdownRef}>
      <div onClick={handleDropdownControllerClick}>{control}</div>
      {isOpen &&
        dropdownRef.current &&
        createPortal(
          <DropdownMenu
            offsetX={dropdownOffsetX}
            offsetY={dropdownOffsetY}
            dropdownZIndex={dropdownZIndex}
            position={position}
            maxWidth={maxWidth}
          >
            {menuItems.map(item => {
              if (item.nestedItems) {
                return (
                  <DropMenuItem
                    key={item.id || item.label?.toString()}
                    withSeparator={item.withSeparator}
                  >
                    <Dropdown
                      isOpen={isNestedMenuOpen}
                      menuItems={item.nestedItems}
                      setIsOpen={setIsNestedMenuOpen}
                      offsetX="calc(100% + 20px)"
                      maxWidth={item.maxWidth}
                      offsetY={0}
                      control={
                        <DropMenuItemLabel>
                          {item.label}
                          <NestedItemsIconContainer>
                            <Icon
                              name="ChevronRight"
                              size={12}
                              color={colors.secondaryText}
                            />
                          </NestedItemsIconContainer>
                        </DropMenuItemLabel>
                      }
                      position={position}
                    />
                  </DropMenuItem>
                );
              }

              return (
                <DropMenuItem
                  key={item.id || item.label?.toString()}
                  onClick={item.onClick}
                  withSeparator={item.withSeparator}
                >
                  <DropMenuItemLabel>{item.label}</DropMenuItemLabel>
                </DropMenuItem>
              );
            })}
          </DropdownMenu>,
          usePortal ? document.body : dropdownRef.current
        )}
    </DropdownWrapper>
  );
};

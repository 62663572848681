import styled from 'styled-components';

import { getVariant } from '@/themes/typographyUtilis';

export const FormRow = styled.div`
  & + & {
    margin-top: 16px;
  }
`;

export const InputLabel = styled.label`
  display: block;
  margin-bottom: 6px;
  color: ${({ theme }) => theme.colors.secondaryText};
  ${getVariant({ variant: 'body1-normal' })}
`;

export const InputHintText = styled.span`
  display: block;
  margin-top: 5px;
  color: ${({ theme }) => theme.colors.secondaryText};
  ${getVariant({ variant: 'body2-normal' })}
`;

import { useEffect } from 'react';
import { Map as MaplibreMap } from 'maplibre-gl';

import { MAP_ICONS } from '../constants/map';
import { iconPickerIcons } from '../constants/icons';

export const useLoadMapIcons = (map: MaplibreMap | null) => {
  useEffect(() => {
    // We have to check style because `hasImage` will throw an error on page change because `style` is undefined
    if (map?.style) {
      for (const [iconName, icon] of Object.entries(MAP_ICONS)) {
        if (!map.hasImage(iconName)) {
          const image = new Image();

          image.src = icon.src;
          image.onload = () => map.addImage(iconName, image);
        }
      }

      iconPickerIcons.forEach(({ iconName, iconPath }) => {
        const darkModeIconName = `${iconName}DarkMode`;
        const lightModeIconName = `${iconName}LightMode`;

        if (!map.hasImage(darkModeIconName)) {
          const darkModeImage = new Image(16, 16);

          darkModeImage.src = iconPath.replace('.svg', '-dark-mode.svg');
          darkModeImage.onload = () => map.addImage(darkModeIconName, darkModeImage);
        }

        if (!map.hasImage(lightModeIconName)) {
          const lightModeImage = new Image(16, 16);

          lightModeImage.src = iconPath.replace('.svg', '-light-mode.svg');
          lightModeImage.onload = () => map.addImage(lightModeIconName, lightModeImage);
        }
      });
    }
  }, [map]);
};

import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

import { Modal } from '@/core/components/Modal/Modal';
import { useAppDispatch } from '@/core/store/store';
import { ASIDE_WIDTH, HEADER_HEIGHT } from '@/core/components/Layout/Layout.styles';
import { Input } from '@/core/components/Input';
import { Tabs } from '@/core/components/Tabs/Tabs';
import { IconButton } from '@/core/components/IconButton';
import { TextBodyBold } from '@/core/components/Typography/Typography.styles';
import { Dropdown } from '@/core/components/Dropdown';
import { DropdownItem, DropdownPosition } from '@/core/components/Dropdown/Dropdown.types';
import { Icon } from '@/core/components/Icons';
import { ButtonSize } from '@/core/components/Button';
import { GEOJSONShapeType } from '@/core/interfaces/geojsons';
import { OverlayPosition } from '@/core/components/Modal/Modal.types';
import { ASIDE_MENU_Z_INDEX } from '@/core/constants/zIndex';
import { useAuthenticatedSession } from '@/core/hooks/useAuthenticatedSession';
import { UserRole } from '@/core/interfaces/common';

import {
  BulkActionsContainer,
  GeofeatureCloseIcon,
  GeofeatureContainer,
  GeofeatureContent,
  GeofeatureEyeIcon,
  GeofeatureGearIcon,
  GeofeatureHeader,
  GeofeatureIconWrapper,
  GeofeatureListWrapper,
  HeaderTopBar,
  InputWithActionsWrapper,
  SearchClearButton,
  SearchContainer,
  TabsContainer,
} from '@/features/Geofeatures/components/GeofeatureModal/GeofeatureModal.styles';
import {
  GeofeatureViewType,
  GeofeatureModalProps,
} from '@/features/Geofeatures/components/GeofeatureModal/GeofeatureModal.types';
import {
  closeGeofeatureModal,
  getFoldersListSelector,
  getAllGeofeaturesListSelector,
} from '@/features/Geofeatures/store';
import { GeofeaturesList } from '@/features/Geofeatures/components/GeofeaturesList';
import { GeofeaturesByFolderList } from '@/features/Geofeatures/components/GeofeaturesByFolderList/GeofeaturesByFolderList';
import { GeofeatureItem, UpdateGeofeaturesData } from '@/features/Geofeatures/interfaces';
import { GeofeaturesBulkActions } from '@/features/Geofeatures/components/GeofeaturesBulkActions';
import { GeofeatureView } from '@/features/Geofeatures/components/GeofeatureView';
import { TOOLBAR_HEIGHT } from '@/features/Map/components/Toolbar/Toolbar.styles';
import {
  getGeofeaturesFolders,
  getSharedGeofeaturesFolders,
  updateGeofeaturesData,
} from '@/features/Geofeatures/store/actions';
import { getUserSelector } from '@/features/Auth/store';

import { hasPermission } from '@/utils/helpers';

import { GeofeatureFolderModal, FolderModalType } from '../GeofeatureFolderModal';
import { GeoJSONImportModal } from '../GeoJSONImportModal';

export const GeofeatureModal = ({ isOpen }: GeofeatureModalProps) => {
  const userData = useSelector(getUserSelector);
  const geofeaturesFoldersList = useSelector(getFoldersListSelector);
  const [activeTab, setActiveTab] = useState(geofeaturesFoldersList[0]?.id);
  const geofeaturesList = useSelector(getAllGeofeaturesListSelector);
  const [searchValue, setSearchValue] = useState('');
  const [selectedGeofeaturesIds, setSelectedGeofeaturesIds] = useState<Array<GeofeatureItem['id']>>(
    []
  );
  const [isTabsDropdownOpen, setIsTabsDropdownOpen] = useState(false);
  const [isAddDropdownOpen, setIsAddDropdownOpen] = useState(false);
  const [geofeatureView, setGeofeatureView] = useState<GeofeatureViewType | null>(null);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const [editFolderModalType, setEditFolderModalType] = useState<FolderModalType | undefined>(
    undefined
  );
  const [geofeatureToEdit, setGeofeatureToEdit] = useState<
    GeofeatureItem['geoJSONData'] | undefined
  >(undefined);

  const geofeatures = useMemo(() => {
    if (searchValue === '') {
      return geofeaturesList.filter(item => item.folderId === activeTab);
    }

    const searchPhrase = searchValue.toLowerCase();

    return geofeaturesList.filter(
      item =>
        item.name.toLowerCase().includes(searchPhrase) ||
        item.geoJSONData.properties.shape.toLowerCase().includes(searchPhrase) ||
        item.tags.some(tag => tag.toLowerCase().includes(searchPhrase))
    );
  }, [activeTab, geofeaturesList, searchValue]);

  const { colors } = useTheme();

  const canCreateFolder = useMemo<boolean>(
    () =>
      hasPermission(userData!.groups, [
        UserRole.MANAGEMENT_ADMIN,
        UserRole.TEAM_LEADER,
        UserRole.ANALYST,
      ]),
    [userData]
  );
  const canCreateGeofeature = useMemo<boolean>(
    () =>
      hasPermission(userData!.groups, [
        UserRole.MANAGEMENT_ADMIN,
        UserRole.TEAM_LEADER,
        UserRole.ANALYST,
      ]),
    [userData]
  );

  const activeFolderItem = useMemo(
    () => geofeaturesFoldersList.find(folder => folder.id === activeTab),
    [activeTab, geofeaturesFoldersList]
  );

  const overlayPosition = useMemo<OverlayPosition>(
    () => ({
      top: `calc(${HEADER_HEIGHT} + ${TOOLBAR_HEIGHT})`,
      right: 'auto',
      left: ASIDE_WIDTH,
    }),
    []
  );

  const dispatch = useAppDispatch();
  const closeModal = useCallback(() => {
    dispatch(closeGeofeatureModal());
  }, [dispatch]);

  const handleSearchInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
    },
    [setSearchValue]
  );

  const handleSearchClear = useCallback(() => {
    setSearchValue('');
  }, [setSearchValue]);

  const onGeofeatureSelect = useCallback(
    (id: GeofeatureItem['id']) => {
      if (selectedGeofeaturesIds.includes(id)) {
        setSelectedGeofeaturesIds(selectedGeofeaturesIds.filter(item => item !== id));
      } else {
        setSelectedGeofeaturesIds([...selectedGeofeaturesIds, id]);
      }
    },
    [selectedGeofeaturesIds, setSelectedGeofeaturesIds]
  );

  const handleSelect = useCallback(() => {
    setSelectedGeofeaturesIds(geofeatures.map(item => item.id));
  }, [geofeatures]);

  const handleDeselect = useCallback(() => {
    setSelectedGeofeaturesIds([]);
  }, [setSelectedGeofeaturesIds]);

  useEffect(() => {
    setSelectedGeofeaturesIds([]);
  }, [activeTab, searchValue]);

  const isFolderHidden = useMemo(
    () => geofeatures.every(geofeature => !geofeature.visibleOnMap),
    [geofeatures]
  );

  const openEditFolderModal = useCallback((type: FolderModalType) => {
    setIsFolderModalOpen(true);
    setEditFolderModalType(type);
  }, []);

  const addMenuItems = useMemo<Array<DropdownItem>>(() => {
    const itemsList: Array<DropdownItem> = [];

    if (canCreateGeofeature) {
      itemsList.push(
        {
          label: 'POI',
          onClick: () => {
            setGeofeatureView(GeofeatureViewType.POI);
            setIsAddDropdownOpen(false);
          },
        },
        {
          label: 'Line',
          onClick: () => {
            setGeofeatureView(GeofeatureViewType.LINE);
            setIsAddDropdownOpen(false);
          },
        },
        {
          label: 'Polygon',
          onClick: () => {
            setGeofeatureView(GeofeatureViewType.POLYGON);
            setIsAddDropdownOpen(false);
          },
        },
        {
          label: 'Radial',
          onClick: () => {
            setGeofeatureView(GeofeatureViewType.RADIAL);
            setIsAddDropdownOpen(false);
          },
        },
        {
          label: 'Import GeoJson',
          onClick: () => {
            setIsImportModalOpen(true);
            setIsAddDropdownOpen(false);
          },
        }
      );
    }

    if (canCreateFolder) {
      itemsList.push({
        label: (
          <>
            <Icon
              name="FolderPlus"
              color={colors.secondaryText}
              size={16}
            />
            Create folder
          </>
        ),
        onClick: () => openEditFolderModal(FolderModalType.ADD),
        withSeparator: true,
      });
    }

    return itemsList;
  }, [canCreateFolder, canCreateGeofeature, colors.secondaryText, openEditFolderModal]);

  const handleFormClose = useCallback(() => {
    setGeofeatureView(null);
    setGeofeatureToEdit(undefined);
  }, []);

  const handleImportModalClose = useCallback(() => {
    setIsImportModalOpen(false);
  }, []);

  const closeEditModal = useCallback(() => {
    setIsFolderModalOpen(false);
  }, []);

  const folderForEditForm = useMemo(() => {
    return editFolderModalType === FolderModalType.ADD
      ? undefined
      : geofeaturesFoldersList.find(folder => folder.id === activeTab);
  }, [activeTab, editFolderModalType, geofeaturesFoldersList]);

  const handleGeofeatureVisibilityToggle = useCallback(
    (geofeatureId: string, visibility: boolean) => {
      const geofeature = geofeatures.find(item => item.id === geofeatureId);

      if (!geofeature) {
        return;
      }

      dispatch(
        updateGeofeaturesData([
          {
            ...geofeature.geoJSONData,
            id: geofeature.id,
            // @ts-expect-error geoJSONData has correct properties and is corelated with update data
            properties: {
              ...geofeature.geoJSONData.properties,
              display: visibility,
            },
          },
        ])
      );
    },
    [dispatch, geofeatures]
  );

  const handleGeofeaturesFolderVisibilityToggle = useCallback(() => {
    // geofeatures.forEach(item => {
    //   handleGeofeatureVisibilityToggle(item.id, isFolderHidden);
    // });
    const updatedGeofeatures = geofeatures.map<UpdateGeofeaturesData[0]>(geofeature => ({
      ...geofeature.geoJSONData,
      id: geofeature.id,
      // @ts-expect-error geoJSONData has correct properties and is corelated with update data
      properties: {
        ...geofeature.geoJSONData.properties,
        display: isFolderHidden,
      },
    }));

    dispatch(updateGeofeaturesData(updatedGeofeatures));
  }, [dispatch, geofeatures, isFolderHidden]);

  const modalTitle = useMemo(() => {
    switch (geofeatureView) {
      case GeofeatureViewType.RADIAL: {
        return 'Radial geofence';
      }
      case GeofeatureViewType.POLYGON: {
        return 'Polygon geofence';
      }
      case GeofeatureViewType.LINE: {
        return 'Line geofence';
      }
      default: {
        return 'Geofeatures';
      }
    }
  }, [geofeatureView]);

  const handleGeofeatureEditClick = useCallback((geofeature: GeofeatureItem['geoJSONData']) => {
    let viewMode: GeofeatureViewType | null = null;

    if (geofeature.properties.shape === GEOJSONShapeType.CIRCLE) {
      viewMode = GeofeatureViewType.RADIAL;
    } else if (geofeature.properties.shape === GEOJSONShapeType.POLYGON) {
      viewMode = GeofeatureViewType.POLYGON;
    } else if (geofeature.properties.shape === GEOJSONShapeType.LINE) {
      viewMode = GeofeatureViewType.LINE;
    } else if (geofeature.properties.shape === GEOJSONShapeType.POI) {
      viewMode = GeofeatureViewType.POI;
    }

    setGeofeatureToEdit(geofeature);

    setGeofeatureView(viewMode);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setActiveTab(geofeaturesFoldersList[0]?.id);
      setSearchValue('');
      setSelectedGeofeaturesIds([]);
      setIsTabsDropdownOpen(false);
      setIsAddDropdownOpen(false);
      setGeofeatureView(null);
      setIsImportModalOpen(false);
      setIsFolderModalOpen(false);
      setEditFolderModalType(undefined);
      setGeofeatureToEdit(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (
      geofeaturesFoldersList.length &&
      (!activeTab || !geofeaturesFoldersList.some(folder => folder.id === activeTab))
    ) {
      setActiveTab(geofeaturesFoldersList[0].id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geofeaturesFoldersList]);

  useAuthenticatedSession(() => {
    dispatch(getGeofeaturesFolders());
    dispatch(getSharedGeofeaturesFolders());
  }, []);

  return (
    <>
      <Modal
        matchContentSize
        isOpen={isOpen}
        onClose={() => null}
        height={`calc(100vh - ${HEADER_HEIGHT} - ${TOOLBAR_HEIGHT})`}
        overlayPosition={overlayPosition}
        overlayClassName="slide-from-left"
        zIndex={ASIDE_MENU_Z_INDEX - 1}
        closeTimeoutMS={300}
      >
        <GeofeatureContainer>
          <GeofeatureHeader>
            <HeaderTopBar>
              <TextBodyBold>{modalTitle}</TextBodyBold>
              <GeofeatureCloseIcon onClick={closeModal} />
            </HeaderTopBar>
          </GeofeatureHeader>
          {geofeatureView === null ? (
            <>
              <TabsContainer>
                <Tabs
                  tabs={geofeaturesFoldersList}
                  activeTab={activeTab}
                  onTabChange={setActiveTab}
                  isDropdownOpen={isTabsDropdownOpen}
                  setIsDropdownOpen={setIsTabsDropdownOpen}
                />
              </TabsContainer>
              <GeofeatureContent>
                <InputWithActionsWrapper>
                  <SearchContainer>
                    <Input
                      placeholder="Search in geofeatures"
                      value={searchValue}
                      onChange={handleSearchInputChange}
                      suffixIcon={
                        searchValue !== '' && (
                          <SearchClearButton onClick={handleSearchClear}>
                            <Icon name="Close" />
                          </SearchClearButton>
                        )
                      }
                    />
                  </SearchContainer>
                  {addMenuItems.length > 0 && (
                    <Dropdown
                      control={
                        <IconButton
                          name="Plus"
                          buttonSize={ButtonSize.SMALL}
                        />
                      }
                      position={DropdownPosition.LEFT}
                      menuItems={addMenuItems}
                      isOpen={isAddDropdownOpen}
                      setIsOpen={setIsAddDropdownOpen}
                      offsetY={40}
                    />
                  )}

                  {canCreateFolder && (
                    <GeofeatureIconWrapper>
                      <GeofeatureGearIcon
                        color={colors.secondaryText}
                        onClick={() => openEditFolderModal(FolderModalType.EDIT)}
                      />
                    </GeofeatureIconWrapper>
                  )}

                  <GeofeatureIconWrapper>
                    <GeofeatureEyeIcon
                      name={isFolderHidden ? 'EyeSlash' : 'Eye'}
                      color={colors.secondaryText}
                      onClick={handleGeofeaturesFolderVisibilityToggle}
                    />
                  </GeofeatureIconWrapper>
                </InputWithActionsWrapper>

                {selectedGeofeaturesIds.length > 0 && (
                  <BulkActionsContainer>
                    <GeofeaturesBulkActions
                      selectedGeofeaturesIds={selectedGeofeaturesIds}
                      geofeaturesFolders={geofeaturesFoldersList}
                      onSelectAll={handleSelect}
                      onDeselectAll={handleDeselect}
                      setSelectedGeofeatures={setSelectedGeofeaturesIds}
                    />
                  </BulkActionsContainer>
                )}

                {searchValue === '' ? (
                  <GeofeatureListWrapper>
                    <GeofeaturesList
                      geofeatures={geofeatures}
                      selectedGeofeaturesIds={selectedGeofeaturesIds}
                      onGeofeatureSelect={onGeofeatureSelect}
                      onGeofeatureVisibilityToggle={handleGeofeatureVisibilityToggle}
                      onGeofeatureEditClick={handleGeofeatureEditClick}
                    />
                  </GeofeatureListWrapper>
                ) : (
                  <GeofeaturesByFolderList
                    folders={geofeaturesFoldersList}
                    geofeatures={geofeatures}
                    selectedGeofeaturesIds={selectedGeofeaturesIds}
                    onGeofeatureSelect={onGeofeatureSelect}
                    onGeofeatureVisibilityToggle={handleGeofeatureVisibilityToggle}
                    onGeofeatureEditClick={handleGeofeatureEditClick}
                  />
                )}
              </GeofeatureContent>
            </>
          ) : (
            <GeofeatureView
              visibleGeofeature={geofeatureView}
              defaultFolder={activeFolderItem}
              initialData={geofeatureToEdit}
              handleFormClose={handleFormClose}
            />
          )}
        </GeofeatureContainer>
      </Modal>
      <GeoJSONImportModal
        isOpen={isImportModalOpen}
        currentFolderId={activeTab}
        onClose={handleImportModalClose}
      />
      {isFolderModalOpen && (
        <GeofeatureFolderModal
          type={editFolderModalType}
          isOpen={isFolderModalOpen}
          onClose={closeEditModal}
          folder={folderForEditForm}
          onFolderCreate={setActiveTab}
        />
      )}
    </>
  );
};

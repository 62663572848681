import { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { Paragraph, Caption } from '@/core/components/Typography';

import {
  ReportSourceHeader,
  ReportSourceImage,
  StyledSource,
  SourceCredibility,
  StyledLink,
  ReportSourceContent,
  SourceLink,
} from './Source.styles';
import { ReportScore, SourceProps } from './Source.types';

export const Source = ({ publicationDate, link, score }: SourceProps) => {
  const { colors } = useTheme();

  const reportColor = useMemo(() => {
    switch (score) {
      case ReportScore.LOW:
        return colors.reliability.low;
      case ReportScore.MEDIUM:
        return colors.reliability.medium;
      case ReportScore.HIGH:
        return colors.reliability.high;
      default:
        return colors.reliability.unknown;
    }
  }, [
    colors.reliability.high,
    colors.reliability.low,
    colors.reliability.medium,
    colors.reliability.unknown,
    score,
  ]);

  // Temporary content until we get the data from the API
  return (
    <StyledLink
      href={link}
      target="_blank"
      rel="noreferrer noopener"
    >
      <Paragraph type="normal">
        <SourceLink>{link}</SourceLink>
      </Paragraph>
    </StyledLink>
  );

  return (
    <StyledLink
      href={link}
      target="_blank"
      rel="noreferrer noopener"
    >
      <ReportSourceHeader>
        <ReportSourceImage
          src="https://via.placeholder.com/100x100"
          alt="Source"
        />
        <StyledSource>
          {/* TODO: get from api */}
          <Paragraph type="bold">Source Name</Paragraph>
          <Paragraph
            type="small-normal"
            color={colors.active}
          >
            News
          </Paragraph>
        </StyledSource>
      </ReportSourceHeader>
      <ReportSourceContent>
        <Paragraph
          type="small-normal"
          color={colors.secondaryText}
        >
          {`Published on ${publicationDate}`}
        </Paragraph>
        <SourceCredibility color={reportColor}>
          <Caption color={reportColor}>{score}</Caption>
        </SourceCredibility>
      </ReportSourceContent>
    </StyledLink>
  );
};

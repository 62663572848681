import { css } from 'styled-components';

export type FontVariants =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'subheadline1-normal'
  | 'subheadline1-medium'
  | 'subheadline2-medium'
  | 'body1-bold'
  | 'body1-normal'
  | 'body2-bold'
  | 'body2-normal'
  | 'body3-normal'
  | 'button1'
  | 'button2'
  | 'sidebarOne'
  | 'sidebarTwo'
  | 'caption-bold'
  | 'caption-normal';

type GetVariantTypes = {
  variant: FontVariants;
};

type FontVariantMap = {
  [key in FontVariants]: {
    fontSize: string;
    lineHeight: string;
    letterSpacing?: string;
    fontWeight: string;
    fontFamily: string;
  };
};

type FontSettingTypes = {
  fontSize: string;
  lineHeight: string;
  letterSpacing?: string;
  fontWeight: string;
  fontFamily: string;
};

export const fontFace = {
  inter: "'Inter', sans-serif",
  raleway: "'Raleway', sans-serif",
};

export const fontVariantMap: FontVariantMap = {
  h1: {
    fontSize: '32px',
    lineHeight: '40px',
    letterSpacing: '0',
    fontWeight: '700',
    fontFamily: fontFace.inter,
  },
  h2: {
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '0',
    fontWeight: '700',
    fontFamily: fontFace.inter,
  },
  h3: {
    fontSize: '20px',
    lineHeight: '28px',
    letterSpacing: '0',
    fontWeight: '700',
    fontFamily: fontFace.inter,
  },
  'subheadline1-medium': {
    fontSize: '18px',
    lineHeight: '22px',
    letterSpacing: '0',
    fontWeight: '500',
    fontFamily: fontFace.inter,
  },
  'subheadline1-normal': {
    fontSize: '18px',
    lineHeight: '22px',
    letterSpacing: '0',
    fontWeight: '400',
    fontFamily: fontFace.inter,
  },
  'subheadline2-medium': {
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '2px',
    fontWeight: '500',
    fontFamily: fontFace.inter,
  },
  'body1-bold': {
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0',
    fontWeight: '700',
    fontFamily: fontFace.inter,
  },
  'body1-normal': {
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0',
    fontWeight: '400',
    fontFamily: fontFace.inter,
  },
  'body2-bold': {
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0',
    fontWeight: '700',
    fontFamily: fontFace.inter,
  },
  'body2-normal': {
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0',
    fontWeight: '400',
    fontFamily: fontFace.inter,
  },
  'body3-normal': {
    fontSize: '11px',
    lineHeight: '13px',
    letterSpacing: '0',
    fontWeight: '400',
    fontFamily: fontFace.inter,
  },
  button1: {
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0',
    fontWeight: '700',
    fontFamily: fontFace.inter,
  },
  button2: {
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0',
    fontWeight: '700',
    fontFamily: fontFace.inter,
  },
  sidebarOne: {
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0',
    fontWeight: '700',
    fontFamily: fontFace.raleway,
  },
  sidebarTwo: {
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0',
    fontWeight: '700',
    fontFamily: fontFace.raleway,
  },
  'caption-bold': {
    fontSize: '10px',
    lineHeight: '14px',
    letterSpacing: '0',
    fontWeight: '700',
    fontFamily: fontFace.inter,
  },
  'caption-normal': {
    fontSize: '10px',
    lineHeight: '14px',
    letterSpacing: '0',
    fontWeight: '500',
    fontFamily: fontFace.inter,
  },
};

const getTypography = ({
  fontSize,
  lineHeight,
  letterSpacing = '0',
  fontWeight,
  fontFamily,
}: FontSettingTypes) => css`
  font-weight: ${fontWeight};
  font-size: ${fontSize};
  font-family: ${fontFamily};
  line-height: ${lineHeight};
  letter-spacing: ${letterSpacing};
`;

export const getVariant = ({ variant }: GetVariantTypes) => css`
  ${getTypography(fontVariantMap[variant])};
`;

import { Icon } from '@/core/components/Icons';
import { ReportSourceType, ReportCategory } from '@/core/interfaces/common';
import { MAP_ICONS } from '@/core/constants/map';

import { isG4S } from '@/utils/helpers';

import { categoryColors } from '@/themes/main';

import { impactFilterOptions, impactFilterOptionsG4S } from './filters';

type IconSizeType = number;

export const mapCategories = (
  category: string,
  size: IconSizeType = 12,
  categoryIconColor?: string
) => {
  switch (category) {
    // Sector main category
    case ReportCategory.AUTOMOTIVE:
    case ReportCategory.AVIATION:
    case ReportCategory.CHEMICAL:
    case ReportCategory.CONSTRUCTION:
    case ReportCategory.ELECTRICITY:
    case ReportCategory.HOSPITALITY_TOURISM:
    case ReportCategory.MINING:
    case ReportCategory.NUCLEAR:
    case ReportCategory.OIL_GAS:
    case ReportCategory.RENEWABLE_ENERGY:
    case ReportCategory.SPORT_ENTERTAINMENT:
    case ReportCategory.TELECOM:
    case ReportCategory.TRANSPORT_LOGISTICS:
    case ReportCategory.WATER_FOOD:
      return {
        icon: (
          <Icon
            size={size}
            name="Building"
            color={categoryIconColor}
          />
        ),
        color: categoryColors.Sector,
        imgSrc: MAP_ICONS.sector.src,
      };
    // Natural distaster main category
    case ReportCategory.AVALANCHE:
    case ReportCategory.DROUGHT:
    case ReportCategory.EARTHQUAKE:
    case ReportCategory.FLOOD:
    case ReportCategory.GROUNDWATER_POLLUTION:
    case ReportCategory.HEATWAVE:
    case ReportCategory.LANDSLIDE:
    case ReportCategory.SEVERE_COLD_WEATHER:
    case ReportCategory.STORM:
    case ReportCategory.TSUNAMI:
    case ReportCategory.VOLCANIC_ERUPTION:
    case ReportCategory.WILDFIRE:
      return {
        icon: (
          <Icon
            size={size}
            name="HouseWater"
            color={categoryIconColor}
          />
        ),
        color: categoryColors['Natural disaster'],
        imgSrc: MAP_ICONS.naturalDisaster.src,
      };
    // Security main category
    case ReportCategory.BORDER_INCIDENT:
    case ReportCategory.CIVIL_UNREST:
    case ReportCategory.CIVIL_WAR:
    case ReportCategory.CRIME:
    case ReportCategory.KIDNAPPING:
    case ReportCategory.MILITARY_EXERCISE:
    case ReportCategory.PIRACY:
    case ReportCategory.TERRORISM_INSURGENCY:
    case ReportCategory.WAR:
      return {
        icon: (
          <Icon
            size={size}
            name="SirenOn"
            color={categoryIconColor}
          />
        ),
        color: categoryColors.Security,
        imgSrc: MAP_ICONS.security.src,
      };
    // Politicts main category
    case ReportCategory.CORRUPTION:
    case ReportCategory.DIPLOMACY:
    case ReportCategory.ELECTION:
    case ReportCategory.SOCIAL_TROUBLE:
      return {
        icon: (
          <Icon
            size={size}
            name="LandmarkDome"
            color={categoryIconColor}
          />
        ),
        color: categoryColors.Politics,
        imgSrc: MAP_ICONS.politics.src,
      };
    // Cybersecutiry main category
    case ReportCategory.CYBERSECURITY_POLICY:
    case ReportCategory.DATA_BREACH:
    case ReportCategory.DDOS:
    case ReportCategory.PHISHING:
    case ReportCategory.RANSOMWARE:
    case ReportCategory.VULNERABILITY:
      return {
        icon: (
          <Icon
            size={size}
            name="BinaryLock"
            color={categoryIconColor}
          />
        ),
        color: categoryColors.Cybersecurity,
        imgSrc: MAP_ICONS.cybersecurity.src,
      };
    // Economy main category
    case ReportCategory.ECONOMIC_TREND:
    case ReportCategory.STRIKE:
    case ReportCategory.TRADE_AGREEMENT:
      return {
        icon: (
          <Icon
            size={size}
            name="ChartLineUp"
            color={categoryIconColor}
          />
        ),
        color: categoryColors.Economy,
        imgSrc: MAP_ICONS.economy.src,
      };
    // Safety main category
    case ReportCategory.HEALTH_EPIDEMIC:
    case ReportCategory.MIGRATION:
    case ReportCategory.WORKPLACE_DISASTER:
      return {
        icon: (
          <Icon
            size={size}
            name="HelmetSafety"
            color={categoryIconColor}
          />
        ),
        color: categoryColors.Safety,
        imgSrc: MAP_ICONS.safety.src,
      };

    // Travel main category
    case ReportCategory.TRAVEL:
      return {
        icon: (
          <Icon
            size={size}
            name="Passport"
            color={categoryIconColor}
          />
        ),
        color: categoryColors.Travel,
        imgSrc: MAP_ICONS.travel.src,
      };
    default:
      return {
        icon: null,
        color: null,
        imgSrc: null,
      };
  }
};

export const getSourceIcon = (source: ReportSourceType, color?: string, size?: IconSizeType) => {
  switch (source) {
    case ReportSourceType.HORIZON:
      return (
        <Icon
          size={size}
          color={color}
          name="Person"
        />
      );
    case ReportSourceType.LOOKOUT:
      return (
        <Icon
          size={size}
          color={color}
          name="MicrochipAi"
        />
      );
  }
};

export const getImpactName = (impact: string | null) => {
  let impactOptions = impactFilterOptions;

  if (isG4S) {
    impactOptions = impactFilterOptionsG4S;
  }

  return (impactOptions.find(option => option.value === impact)?.label as string) || '';
};

import { useCallback, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';

import { Modal, OverlayStyle } from '@/core/components/Modal';
import { Heading } from '@/core/components/Typography';
import { Icon } from '@/core/components/Icons';
import { GeoJSONImportArgument, GeoJSONImportForm } from '@/core/components/GeoJSONImportForm';
import { useAppDispatch } from '@/core/store/store';
import { GEOJSONCollectionData, GEOJSONData } from '@/core/interfaces/geojsons';

import { importGeofeatures } from '@/features/Geofeatures/store/actions';

import { prepareGeoJSONForImport } from '@/utils/helpers';
import { transformPointsOfInterestFromTextToGeoJSON } from '@/utils/geoJSONImport';

import { CloseModalButton, Container } from './GeoJSONImportModal.styles';
import { GeoJSONImportModalProps } from './GeoJSONImportModal.types';

export const GeoJSONImportModal = ({
  isOpen,
  currentFolderId,
  onClose,
}: GeoJSONImportModalProps) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(
    async ({ importValue, isJson }: GeoJSONImportArgument) => {
      setIsLoading(true);
      let importDataValue: string | GEOJSONData | GEOJSONCollectionData = importValue;

      if (!isJson) {
        importDataValue = await transformPointsOfInterestFromTextToGeoJSON(importValue);
      }

      const importData = prepareGeoJSONForImport(importDataValue, currentFolderId);

      try {
        unwrapResult(await dispatch(importGeofeatures(importData)));

        onClose();
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [currentFolderId, dispatch, onClose]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      overlayStyle={OverlayStyle.BLUR}
    >
      <Container>
        <Heading type="h3">GEOJSON</Heading>
        <CloseModalButton onClick={onClose}>
          <Icon
            name="Close"
            size={19}
          />
        </CloseModalButton>

        <GeoJSONImportForm
          placeholder="Paste your GeoJSON here"
          isLoading={isLoading}
          onSubmit={onSubmit}
        />
      </Container>
    </Modal>
  );
};

export type GeofeatureModalProps = {
  isOpen: boolean;
};

export enum GeofeatureViewType {
  RADIAL,
  POLYGON,
  LINE,
  POI,
}

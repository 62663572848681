import styled, { css } from 'styled-components';

import { getVariant } from '@/themes/typographyUtilis';

import { ButtonSize, ButtonVariant, ButtonProps } from './Button.types';

export const buttonStyles = css`
  display: inline-block;
  border: none;
  border-radius: 6px;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
`;

export const StyledButton = styled.button<{
  variant: ButtonVariant;
  buttonSize?: ButtonSize;
  type?: ButtonProps['type'];
}>`
  ${buttonStyles};
  padding: ${({ buttonSize }) => (buttonSize === ButtonSize.MEDIUM ? '14px 26px' : '7px 13px')};
  ${getVariant({ variant: 'button2' })};
  background-color: ${({ theme, variant }) => theme.colors.button[variant].backgroundDefault};
  color: ${({ theme, variant }) => theme.colors.button[variant].textDefault};

  &:hover {
    background-color: ${({ theme, variant }) => theme.colors.button[variant].backgroundHover};
  }

  &:active {
    background-color: ${({ theme, variant }) => theme.colors.button[variant].backgroundActive};
  }
`;

export const colorPickerColors = {
  color1: '#85CADC',
  color2: '#1A50DD',
  color3: '#6025DE',
  color4: '#DD1A9B',
  color5: '#DD1A1A',
  color6: '#DD771A',
  color7: '#DDB21A',
  color8: '#70DD1A',
};

import styled from 'styled-components';

import { TextBodyBold } from '@/core/components/Typography/Typography.styles';

import { getVariant } from '@/themes/typographyUtilis';

export const DatePickerMonthLabel = styled(TextBodyBold)`
  color: ${({ theme }) => theme.colors.secondaryText};
  cursor: pointer;
`;

export const DatepickerMonthSelectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px 10px;
`;

export const DatePickerMonthChangeButton = styled.button<{ isDisabled?: boolean }>`
  opacity: ${({ isDisabled }) => (isDisabled ? '0.2' : '1')};
  border: none;
  background: none;
  cursor: pointer;
`;

export const DatePickerContainer = styled.div`
  .react-datepicker {
    display: flex;
    flex-direction: column;
    ${getVariant({ variant: 'body1-normal' })};
    height: 100%;
    border: none;
    background-color: ${({ theme }) => theme.colors.modalBackground};

    &__portal {
      position: absolute;
      top: 0;
      width: auto;
      height: 100%;
      background-color: transparent;
    }

    &__header {
      border-bottom: none;
      background-color: ${({ theme }) => theme.colors.modalBackground};
    }

    &__current-month {
      ${getVariant({ variant: 'body1-bold' })};
      padding: 18px 0;
      color: ${({ theme }) => theme.colors.secondaryText};
    }

    &__day-names {
      padding: 16px 0;
    }

    &__day-name {
      ${getVariant({ variant: 'body2-bold' })};
      width: 44px;
      color: ${({ theme }) => theme.colors.secondaryText};
      line-height: 32px;
    }

    &__year {
      max-width: 340px;
      margin: 0;
    }

    &__year-wrapper {
      justify-content: center;
      width: 100%;
      max-width: none;
    }

    &__year-text {
      width: 80px;
    }

    &__month-text {
      width: 80px;
    }

    &__day {
      width: 44px;
    }

    &__day,
    &__month-text,
    &__year-text {
      ${getVariant({ variant: 'caption-normal' })};
      color: ${({ theme }) => theme.colors.secondaryText};
      line-height: 32px;

      &:hover {
        background-color: ${({ theme }) => theme.colors.background.extraLight};
      }

      &--disabled {
        opacity: 0.4;
        cursor: not-allowed !important;
      }

      &--in-range {
        background-color: #85cadc26;
      }

      &--in-selecting-range {
        background-color: #85cadc26;
        color: ${({ theme }) => theme.colors.active};

        &--outside-month {
          background-color: coral;
        }
      }

      &--selected,
      &--range-start,
      &--range-end {
        background-color: ${({ theme }) => theme.colors.active};
        color: ${({ theme }) => theme.colors.primaryText};
      }

      &--selecting {
        &-range {
          background-color: #85cadc26;
          color: ${({ theme }) => theme.colors.secondaryText};

          &-end,
          &-start {
            background-color: ${({ theme }) => theme.colors.active} !important;
            color: ${({ theme }) => theme.colors.primaryText} !important;

            &:hover {
              outline: 1px solid ${({ theme }) => theme.colors.border} !important;
              background-color: transparent;
            }
          }
        }
      }

      &--outside-month {
        color: ${({ theme }) => theme.colors.border};
      }
    }

    &:focus-within {
      box-shadow: none;
      outline: none;
    }

    .react-datepicker__month--selecting-range,
    .react-datepicker__year--selecting-range {
      & .react-datepicker__day--in-range {
        &.react-datepicker__day--outside-month {
          color: ${({ theme }) => theme.colors.border};
        }
      }

      & .react-datepicker__day--in-range,
      & .react-datepicker__month-text--in-range,
      & .react-datepicker__quarter-text--in-range,
      & .react-datepicker__year-text--in-range {
        background-color: #85cadc26;
        color: ${({ theme }) => theme.colors.active};

        &:not(.react-datepicker__day--in-selecting-range) {
          background-color: transparent;
          color: ${({ theme }) => theme.colors.secondaryText};

          &:hover {
            background-color: ${({ theme }) => theme.colors.active};
            color: ${({ theme }) => theme.colors.primaryText};
          }

          &.react-datepicker__day--outside-month {
            color: ${({ theme }) => theme.colors.border};
          }
        }
      }
    }
  }
`;

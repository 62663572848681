import { GeofeatureListItem } from '@/features/Geofeatures/components/GeofeatureListItem';

import { GeofeaturesListProps } from './GeofeaturesList.types';
import { List } from './GeofeaturesList.styles';

export const GeofeaturesList = ({
  geofeatures,
  onGeofeatureSelect,
  selectedGeofeaturesIds,
  onGeofeatureVisibilityToggle,
  onGeofeatureEditClick,
}: GeofeaturesListProps) => {
  return (
    <List>
      {geofeatures.map(item => {
        return (
          <GeofeatureListItem
            key={item.id}
            id={item.id}
            name={item.name}
            description={item.description}
            tags={item.tags}
            color={item.color}
            icon={item.icon}
            geoJSONData={item.geoJSONData}
            isSelected={selectedGeofeaturesIds.includes(item.id)}
            onSelect={onGeofeatureSelect}
            isVisibleOnMap={item.visibleOnMap}
            onGeofeatureVisibilityToggle={onGeofeatureVisibilityToggle}
            onGeofeatureEditClick={onGeofeatureEditClick}
          />
        );
      })}
    </List>
  );
};

import { Layer, Source } from 'react-map-gl/maplibre';
import { useTheme } from 'styled-components';

import { GEOJSONShapeType } from '@/core/interfaces/geojsons';

import {
  getLineBufferFillLayerName,
  getLineBufferPolygon,
  getLineBufferStrokeLayerName,
  getLineDataName,
} from '@/utils/map/drawLine';
import {
  POINT_OF_INTEREST_CIRCLE_OPACITY,
  POINT_OF_INTEREST_CIRCLE_STROKE_WIDTH,
  POINT_OF_INTEREST_RADIUS,
  getIconForTheme,
  getPointOfInterestFillLayerName,
} from '@/utils/map/drawPointOfInterest';
import {
  getCircleDataName,
  getCircleFillLayerName,
  getCircleStrokeLayerName,
} from '@/utils/map/drawRadial';
import {
  getPolygonDataName,
  getPolygonFillLayerName,
  getPolygonStrokeLayerName,
} from '@/utils/map/drawPolygon';

import { MapGeofeaturesProps } from './MapGeofeatures.types';

export const MapGeofeatures = ({ geofeatures }: MapGeofeaturesProps) => {
  const { theme, colors } = useTheme();

  return geofeatures.map(geofeature => {
    let geofeatureSourceName = geofeature.id;
    let geofeatureFillLayerName = `${geofeature.id}-fill`;
    let geofeatureStrokeLayerName = `${geofeature.id}-stroke`;

    if (geofeature.geoJSONData.properties.shape === GEOJSONShapeType.CIRCLE) {
      geofeatureSourceName = getCircleDataName(geofeature.id);
      geofeatureFillLayerName = getCircleFillLayerName(geofeature.id);
      geofeatureStrokeLayerName = getCircleStrokeLayerName(geofeature.id);
    } else if (geofeature.geoJSONData.properties.shape === GEOJSONShapeType.POLYGON) {
      geofeatureSourceName = getPolygonDataName(geofeature.id);
      geofeatureFillLayerName = getPolygonFillLayerName(geofeature.id);
      geofeatureStrokeLayerName = getPolygonStrokeLayerName(geofeature.id);
    } else if (geofeature.geoJSONData.properties.shape === GEOJSONShapeType.LINE) {
      geofeatureSourceName = getLineDataName(geofeature.id);
    }

    let sourcesToDisplay = [
      <Source
        key={geofeatureSourceName}
        id={geofeatureSourceName}
        type="geojson"
        data={geofeature.geoJSONData}
      >
        {[GEOJSONShapeType.CIRCLE, GEOJSONShapeType.POLYGON].includes(
          geofeature.geoJSONData.properties.shape
        ) && (
          <Layer
            key={geofeatureFillLayerName}
            id={geofeatureFillLayerName}
            type="fill"
            paint={{
              'fill-color': geofeature.color,
              'fill-opacity': 0.2,
            }}
          />
        )}
        <Layer
          key={geofeatureStrokeLayerName}
          id={geofeatureStrokeLayerName}
          type="line"
          paint={{
            'line-color': geofeature.color,
            'line-width': 2,
          }}
        />
      </Source>,
    ];

    if (geofeature.geoJSONData.properties.shape === GEOJSONShapeType.LINE) {
      const bufferData = getLineBufferPolygon(
        geofeature.geoJSONData,
        geofeature.geoJSONData.properties.radius,
        geofeature.geoJSONData.properties.radiusUnit
      );

      if (bufferData) {
        sourcesToDisplay.push(
          <Source
            key={`${geofeature.id}-buffer`}
            id={`${geofeature.id}-buffer`}
            type="geojson"
            data={getLineBufferPolygon(
              geofeature.geoJSONData,
              geofeature.geoJSONData.properties.radius,
              geofeature.geoJSONData.properties.radiusUnit
            )}
          >
            <Layer
              key={getLineBufferFillLayerName(geofeature.id)}
              id={getLineBufferFillLayerName(geofeature.id)}
              type="fill"
              paint={{
                'fill-color': geofeature.color,
                'fill-opacity': 0.2,
              }}
            />
            <Layer
              key={getLineBufferStrokeLayerName(geofeature.id)}
              id={getLineBufferStrokeLayerName(geofeature.id)}
              type="line"
              paint={{
                'line-color': geofeature.color,
                'line-width': 2,
              }}
            />
          </Source>
        );
      }
    }

    if (geofeature.geoJSONData.properties.shape === GEOJSONShapeType.POI) {
      sourcesToDisplay = [
        <Source
          key={geofeature.id}
          id={getPointOfInterestFillLayerName(geofeature.id)}
          type="geojson"
          data={geofeature.geoJSONData}
        >
          <Layer
            id={getPointOfInterestFillLayerName(geofeature.id)}
            type="circle"
            paint={{
              'circle-radius': POINT_OF_INTEREST_RADIUS,
              'circle-color': geofeature.color,
              'circle-opacity': POINT_OF_INTEREST_CIRCLE_OPACITY,
              'circle-stroke-color': geofeature.color,
              'circle-stroke-width': POINT_OF_INTEREST_CIRCLE_STROKE_WIDTH,
            }}
          />
          <Layer
            type="symbol"
            layout={{
              'icon-image': getIconForTheme(
                geofeature.geoJSONData.properties.icon,
                theme === 'dark'
              ),
              'icon-size': 1,
            }}
            paint={{
              'icon-color': colors.primaryText,
            }}
          />
        </Source>,
      ];
    }

    return sourcesToDisplay;
  });
};

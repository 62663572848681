import styled from 'styled-components';

import { getVariant } from '@/themes/typographyUtilis';

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 578px;

  & + & {
    margin-top: 16px;
  }
`;

export const SwitchLabel = styled.label`
  display: flex;
  gap: 15px;
  width: 578px;
`;

export const SwitchLabelContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

export const DeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  gap: 10px;
  outline: none;
  border: none;
  border-radius: 6px;
  padding: 7px 13px;
  background-color: ${({ theme }) => `${theme.colors.warning}40`};
  color: ${({ theme }) => theme.colors.warning};
  transition: background-color 0.2s;
  ${getVariant({ variant: 'body1-normal' })};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => `${theme.colors.warning}50`};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

import { timeframeCustomTimeData } from 'app/core/components/MapFilters/TimeframeFilter/timeframeRadioButtonData';

import { RadioButton } from '@/core/components/RadioButton';
import { Input } from '@/core/components/Input';

import { TimeframeCustomRadioGroup } from './CustomTimeframeRadioGroupView.styles';
import { CustomTimeframeRadioGroupViewProps } from './CustomTimeframeRadioGroupView.types';

export const CustomTimeframeRadioGroupView = ({
  radioButtonValue,
  setRadioButtonValue,
  radioButtonInputValue,
  setRadioButtonInputValue,
}: CustomTimeframeRadioGroupViewProps) => {
  return (
    <TimeframeCustomRadioGroup>
      <Input
        required
        type="number"
        style={{ width: '80px' }}
        value={radioButtonInputValue}
        onChange={newInputValue => setRadioButtonInputValue(Number(newInputValue.target.value))}
      />
      {timeframeCustomTimeData.map(tab => {
        return (
          <RadioButton
            onChange={() => setRadioButtonValue(tab.value)}
            key={`${tab.value}-radio`}
            label={tab.label}
            value={tab.value}
            name={tab.name}
            checked={radioButtonValue === tab.value}
          />
        );
      })}
    </TimeframeCustomRadioGroup>
  );
};

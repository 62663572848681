import { useCallback, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';

import { Modal } from '@/core/components/Modal';
import { Icon } from '@/core/components/Icons/Icon';
import { OverlayStyle } from '@/core/components/Modal/Modal.types';
import { Paragraph } from '@/core/components/Typography';
import { DeleteModal } from '@/core/components/DeleteModal';
import { useAppDispatch } from '@/core/store/store';

import { GeofeatureFolderForm } from '@/features/Geofeatures/components/GeofeatureFolderForm/GeofeatureFolderForm';
import { deleteGeofeaturesFolder } from '@/features/Geofeatures/store/actions';
import { GeofeatureCreateFolderResponse } from '@/features/Geofeatures/interfaces';

import { GeofeatureFolderModalProps, FolderModalType } from './GeofeatureFolderModal.types';
import { FolderModalWrapper, Header, CloseIconWrapper } from './GeofeatureFolderModal.styles';

export const GeofeatureFolderModal = ({
  isOpen,
  onClose,
  type = FolderModalType.EDIT,
  folder,
  onFolderCreate,
}: GeofeatureFolderModalProps) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const onSubmit = useCallback(
    (folderData: GeofeatureCreateFolderResponse) => {
      if (onFolderCreate) {
        onFolderCreate(folderData.id);
      }
      onClose();
    },
    [onClose, onFolderCreate]
  );

  const onDeleteClick = useCallback(() => {
    setIsDeleteModalOpen(true);
  }, []);

  const onDelete = useCallback(async () => {
    if (!folder?.id) {
      return;
    }

    unwrapResult(await dispatch(deleteGeofeaturesFolder(folder.id)));
    setIsDeleteModalOpen(false);

    onClose();
  }, [dispatch, folder, onClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      overlayStyle={OverlayStyle.BLUR}
    >
      <FolderModalWrapper>
        <Header>
          {type === FolderModalType.ADD ? 'New Folder' : 'Edit Folder'}
          <CloseIconWrapper onClick={onClose}>
            <Icon
              name="Close"
              size={16}
            />
          </CloseIconWrapper>
        </Header>
        <GeofeatureFolderForm
          type={type}
          folder={folder}
          onSubmit={onSubmit}
          onCancel={onClose}
          onDelete={onDeleteClick}
        />
      </FolderModalWrapper>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Delete folder"
        confirmButtonText="YES, DELETE"
        content={
          <Paragraph type="normal">
            Are you sure to delete the selected folder? This action cannot be undone.
          </Paragraph>
        }
        onDelete={onDelete}
      />
    </Modal>
  );
};

import { LineGEOJSONData, PolygonGEOJSONData, RadialGEOJSONData } from '@/core/interfaces/geojsons';

export enum GeofenceType {
  RADIAL,
  POLYGON,
  LINE,
}

export type CreatedGeofences = {
  [GeofenceType.RADIAL]?: RadialGEOJSONData;
  [GeofenceType.POLYGON]?: PolygonGEOJSONData;
  [GeofenceType.LINE]?: LineGEOJSONData;
};

export type CreateGeofenceViewProps = {
  createdGeofences?: CreatedGeofences;
  isEditMode?: boolean;
  onGeofenceClick: (geofenceType: GeofenceType) => void;
  onGeofencesSave: () => void;
  onGeofenceCancel?: () => void;
};

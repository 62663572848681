import styled from 'styled-components';

import { getVariant } from '@/themes/typographyUtilis';

export const GeofencesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const GeofenceItem = styled.button<{
  isActive?: boolean;
  backgroundColor: {
    active: string;
    inactive: string;
  };
}>`
  display: flex;
  align-items: center;
  gap: 24px;
  border: none;
  border-radius: 6px;
  padding: 24px;
  background: ${({ isActive, backgroundColor }) =>
    isActive ? backgroundColor.active : backgroundColor.inactive || 'transparent'};
  text-align: left;
  cursor: pointer;
`;

export const ItemName = styled.div<{ isActive: boolean }>`
  ${getVariant({ variant: 'sidebarOne' })}
  color: ${({ isActive, theme }) =>
    isActive
      ? theme.colors.geofenceCreateItem.textColor.active
      : theme.colors.geofenceCreateItem.textColor.inactive};
  text-transform: uppercase;
`;

export const ItemText = styled.div<{ isActive?: boolean }>`
  ${getVariant({ variant: 'body1-normal' })}
  margin-top: 4px;
  color: ${({ isActive, theme }) =>
    isActive
      ? theme.colors.geofenceCreateItem.textColor.active
      : theme.colors.geofenceCreateItem.textColor.inactive};
`;

export const ShapeNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 179px;
  margin-top: 20px;
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  padding: 6px 12px;
`;

export const ShapeNameIconContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
`;

export const ShapeName = styled.div`
  overflow: hidden;
  color: ${({ theme }) => theme.colors.white};
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SaveContainer = styled.div`
  margin-top: 32px;
`;

export const SaveInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.secondaryText};
  font-weight: 500;
  font-size: 12px;
  font-family: ${({ theme }) => theme.typography.fontFamily.inter};
  line-height: 16px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

import { useCallback } from 'react';

import { Modal } from '@/core/components/Modal/Modal';
import { Heading } from '@/core/components/Typography';
import { OverlayStyle } from '@/core/components/Modal/Modal.types';
import { Icon } from '@/core/components/Icons';

import {
  Container,
  CodeContent,
  CloseModalButton,
  CopyButton,
  CodeContainer,
} from './ExportGeojsonModal.styles';
import { ExportGeojsonModalProps } from './ExportGeojsonModal.types';

export const ExportGeojsonModal = ({ isOpen, onClose, geoJSON }: ExportGeojsonModalProps) => {
  const onCopyClick = useCallback(() => {
    navigator.clipboard.writeText(geoJSON);
  }, [geoJSON]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      overlayStyle={OverlayStyle.BLUR}
    >
      <Container>
        <Heading type="h3">GEOJSON</Heading>
        <CloseModalButton onClick={onClose}>
          <Icon
            name="Close"
            size={19}
          />
        </CloseModalButton>

        <CodeContainer>
          <CopyButton
            type="button"
            onClick={onCopyClick}
          >
            <Icon
              name="Copy"
              size={12}
            />
            copy
          </CopyButton>
          <CodeContent>{geoJSON}</CodeContent>
        </CodeContainer>
      </Container>
    </Modal>
  );
};

import { InputLabel } from '@/core/components/FormElements.styles';

import { InputContainer, StyledTextarea } from './Textarea.styles';
import { TextareaProps } from './Textarea.types';

export const Textarea = ({ label, withError, ...props }: TextareaProps) => {
  return (
    <div>
      {label && <InputLabel>{label}</InputLabel>}
      <InputContainer>
        <StyledTextarea
          withError={withError}
          {...props}
        />
      </InputContainer>
    </div>
  );
};

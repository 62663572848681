import { MultiButtonItem } from '@/core/components/MultiButton';
import { CountryLocationData } from '@/core/interfaces/locations';

import { getTransformedLocationFilterData } from '@/utils/locations';

import {
  LocationFilterItem,
  LocationFilterItemType,
  LocationFilterViewType,
} from './LocationFilter.types';

export const locationTabs: Array<MultiButtonItem<LocationFilterViewType>> = [
  {
    label: 'By country',
    value: LocationFilterViewType.COUNTRY,
  },
  {
    label: 'By geofence',
    value: LocationFilterViewType.GEOFENCE,
  },
  {
    label: 'By POI',
    value: LocationFilterViewType.POI,
  },
];

export const getSelectedCountriesFromAppliedFilters = (
  appliedFilters: Array<LocationFilterItem>
): Array<CountryLocationData> =>
  appliedFilters
    .filter(filter => filter.locationType === LocationFilterViewType.COUNTRY)
    .reduce((acc, filter) => {
      const filterValue = getTransformedLocationFilterData(String(filter.value));

      if (filterValue.locationItemType === LocationFilterItemType.COUNTRY) {
        return [
          ...acc,
          {
            name: filterValue.value.name,
            coordinates: filterValue.value.coordinates,
            id: filterValue.value.id,
          },
        ];
      }

      return acc;
    }, [] as Array<CountryLocationData>);

export enum FilterType {
  CATEGORY = 'category',
  LOCATION = 'location',
  TIMEFRAME = 'timeframe',
  KEYWORDS = 'keywords',
  MORE_FILTERS = 'moreFilters',
}

export type AppliedFilterDataItem = {
  label: string;
  value: string | number;
};

export type AppliedFilterData = Array<AppliedFilterDataItem>;

export type AppliedFilters = {
  [key in FilterType]?: AppliedFilterData;
};

export type ApplyFilterPayload = {
  filterType: FilterType;
  filterData: AppliedFilterData;
};

export enum AutoDateRange {
  TODAY = 'Today',
  PAST_7_DAYS = 'Past 7 days',
  NEXT_7_DAYS = 'Next 7 days',
  PAST_30_DAYS = 'Past 30 days',
  NEXT_30_DAYS = 'Next 30 days',
  CUSTOM_TIMEFRAME = 'Custom timeframe',
}

export type KeywordsFilterItem = {
  keyword: string;
  type: string;
};

export type KeywordsFilters = {
  keywords: Array<KeywordsFilterItem>;
};

import styled from 'styled-components';

export const StyledLink = styled.a`
  display: block;
  outline: none;
  color: inherit;
  text-decoration: none;

  & + & {
    margin-top: 30px;
  }
`;

export const ReportSourceHeader = styled.div`
  display: flex;
  gap: 12px;
`;

export const ReportSourceImage = styled.img`
  width: 57px;
  height: 57px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primaryText};
  object-fit: cover;
`;

export const StyledSource = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
`;

export const ReportSourceContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  margin-top: 10px;
`;

export const SourceCredibility = styled.span<{ color: string }>`
  display: inline-block;
  border: 1px solid ${({ color }) => color};
  border-radius: 4px;
  padding: 4px 6px;
  color: ${({ color }) => color};
  font-size: 10px;
  line-height: 14px;
`;

export const SourceLink = styled.span`
  overflow: hidden;
  display: block;
  max-width: 165px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

import { useContext, useEffect, useState } from 'react';
import { MapEventType } from 'maplibre-gl';

import { MapContext } from '@/core/context/MapContext';

export const useMapStyleName = () => {
  const { mapRef } = useContext(MapContext);
  const [mapStyleName, setMapStyleName] = useState<string>(mapRef?.getMap().getStyle()?.name || '');

  useEffect(() => {
    const map = mapRef?.getMap();

    const onStyleChange = (event: MapEventType['styledata']) => {
      const styleName = event.target.style.stylesheet?.name;

      if (styleName) {
        setMapStyleName(styleName);
      }
    };

    map?.on('styledata', onStyleChange);

    return () => {
      map?.off('styledata', onStyleChange);
    };
  }, [mapRef]);

  return mapStyleName;
};

import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html, body {
    overflow: auto;
    height: 100vh;
    margin: 0;
  }

  p {
    margin: 0;
  }

  .ReactModal__Overlay.slide-from-right {
    opacity: 1 !important;
    transition: ${({ theme }) => (theme.useAnimations ? 'transform 0.3s ease-in-out' : '')};
    transform: translateX(100%);
  }

  .ReactModal__Overlay--after-open.slide-from-right {
    opacity: 1 !important;
    transform: translateX(0);
  }

  .ReactModal__Overlay--before-close.slide-from-right {
    opacity: 1 !important;
    transform: translateX(100%);
  }

  .ReactModal__Overlay.slide-from-left {
    opacity: 1 !important;
    transition: ${({ theme }) => (theme.useAnimations ? 'transform 0.3s ease-in-out' : '')};
    transform: translateX(-100%);
  }

  .ReactModal__Overlay--after-open.slide-from-left {
    opacity: 1 !important;
    transform: translateX(0);
  }

  .ReactModal__Overlay--before-close.slide-from-left {
    opacity: 1 !important;
    transform: translateX(-100%);
  }
`;

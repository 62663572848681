import styled from 'styled-components';

import { buttonStyles } from '@/core/components/Button/Button.styles';
import { ButtonSize, ButtonVariant } from '@/core/components/Button/Button.types';

export const StyledIconButton = styled.button<{
  variant: ButtonVariant;
  buttonSize: ButtonSize;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${buttonStyles};
  padding: ${({ buttonSize }) => (buttonSize === ButtonSize.SMALL ? '10px' : '14px')};
  background-color: ${({ theme, variant }) => theme.colors.button[variant].backgroundDefault};

  &:hover {
    background-color: ${({ theme, variant }) => theme.colors.button[variant].backgroundHover};
  }

  &:active {
    background-color: ${({ theme, variant }) => theme.colors.button[variant].backgroundActive};
  }
`;

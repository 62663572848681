import styled from 'styled-components';

import { ThemeVariant } from '@/core/interfaces/common';

export const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 31px;
  min-width: 31px;
  height: 18px;
`;

export const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const SwitchSlider = styled.span`
  position: absolute;
  inset: 0;
  border: 1px solid
    ${({ theme }) =>
      theme.theme === ThemeVariant.DARK
        ? theme.colors.background.light
        : theme.colors.secondaryText};
  border-radius: 34px;
  background-color: ${({ theme }) =>
    theme.theme === ThemeVariant.DARK ? theme.colors.background.light : 'transparent'};
  transition: 0.4s;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    bottom: 1px;
    left: 2px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: ${({ theme }) =>
      theme.theme === ThemeVariant.DARK
        ? theme.colors.focuses.focusBackground
        : theme.colors.secondaryText};
    transition: 0.4s;
  }

  ${/* sc-selector */ SwitchInput}:checked + & {
    border: 1px solid ${({ theme }) => theme.colors.active};
    background-color: ${({ theme }) => theme.colors.active};

    &::before {
      left: 13px;
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { GEOJSONData, GEOJSONShapeType, RadialGEOJSONData } from '@/core/interfaces/geojsons';

import {
  GeofeatureFolderItem,
  GeofeatureItem,
  GeofeaturesState,
} from '@/features/Geofeatures/interfaces';
import {
  getGeofeaturesFolders,
  getSharedGeofeaturesFolders,
  createGeofeaturesFolder,
  editGeofeaturesFolder,
  deleteGeofeaturesFolder,
  createRadialGeofeature,
  createPolygonGeofeature,
  createLineGeofeature,
  createPointOfInterestGeofeature,
  deleteGeofeature,
  deleteGeofeatures,
  updateRadialGeofeatureData,
  updateGeofeaturesData,
  updatePolygonGeofeatureData,
  updateLineGeofeatureData,
  updatePointOfInterestGeofeatureData,
} from '@/features/Geofeatures/store/actions';
import { transformGeofeaturFromFolder } from '@/features/Geofeatures/utils';

import { getActionPrefix } from '@/utils/helpers';

const actionPrefix = getActionPrefix('geofeatures');

const initialState: GeofeaturesState = {
  isGeofeaturesModalOpen: false,
  foldersList: [],
  geofeaturesList: [],
};

export const geofeaturesSlice = createSlice({
  name: actionPrefix,
  initialState,
  reducers: {
    openGeofeatureModal: state => {
      state.isGeofeaturesModalOpen = true;
    },
    closeGeofeatureModal: state => {
      state.isGeofeaturesModalOpen = false;
    },
    changeGeofeatureVisibility: (
      state,
      { payload }: PayloadAction<{ geofeatureId: string; visibility: boolean }>
    ) => {
      const geofeature = state.geofeaturesList.find(item => item.id === payload.geofeatureId);

      if (geofeature) {
        geofeature.visibleOnMap = payload.visibility;
      }
    },
  },
  extraReducers: builder =>
    builder
      .addCase(getGeofeaturesFolders.fulfilled, (state, { payload }) => {
        const foldersList: Array<GeofeatureFolderItem> = [];
        const geofeaturesList: Array<GeofeatureItem> = [];

        payload.forEach(({ geofeatures, ...folder }) => {
          foldersList.push({
            ...folder,
            isOwnFolder: true,
          });

          geofeaturesList.push(...transformGeofeaturFromFolder(geofeatures, folder.id));
        });

        state.foldersList.push(...foldersList);
        state.geofeaturesList.push(...geofeaturesList);
      })
      .addCase(getSharedGeofeaturesFolders.fulfilled, (state, { payload }) => {
        const foldersList: Array<GeofeatureFolderItem> = [];
        const geofeaturesList: Array<GeofeatureItem> = [];

        payload.forEach(({ geofeatures, ...folder }) => {
          foldersList.push({
            ...folder,
            isOwnFolder: false,
          });

          geofeaturesList.push(...transformGeofeaturFromFolder(geofeatures, folder.id));
        });

        state.foldersList.push(...foldersList);
        state.geofeaturesList.push(...geofeaturesList);
      })
      .addCase(createGeofeaturesFolder.fulfilled, (state, { payload }) => {
        state.foldersList.push({
          ...payload,
          isOwnFolder: true,
        });
      })
      .addCase(editGeofeaturesFolder.fulfilled, (state, { payload }) => {
        const folderIndex = state.foldersList.findIndex(item => item.id === payload.id);

        if (folderIndex >= 0) {
          state.foldersList[folderIndex] = {
            ...payload,
            isOwnFolder: state.foldersList[folderIndex].isOwnFolder,
          };
        }
      })
      .addCase(deleteGeofeaturesFolder.fulfilled, (state, { payload }) => {
        state.foldersList = state.foldersList.filter(item => item.id !== payload);
        state.geofeaturesList = state.geofeaturesList.filter(item => item.folderId !== payload);
      })
      .addCase(createRadialGeofeature.fulfilled, (state, { payload }) => {
        state.geofeaturesList.push({
          id: payload.id,
          color: payload.properties.color,
          icon: payload.properties.icon,
          name: payload.properties.name,
          description: payload.properties.description,
          folderId: payload.properties.folder,
          tags: payload.properties.tags,
          visibleOnMap: payload.properties.display ?? true,
          geoJSONData: {
            ...payload,
            properties: {
              ...payload.properties,
              id: payload.id,
              center: {
                lat: payload.properties.lat,
                lng: payload.properties.long,
              },
              address: '',
            },
          },
        });
      })
      .addCase(createPolygonGeofeature.fulfilled, (state, { payload }) => {
        state.geofeaturesList.push({
          id: payload.id,
          color: payload.properties.color,
          icon: payload.properties.icon,
          name: payload.properties.name,
          description: payload.properties.description,
          folderId: payload.properties.folder,
          tags: payload.properties.tags,
          visibleOnMap: payload.properties.display ?? true,
          geoJSONData: {
            ...payload,
            properties: {
              ...payload.properties,
              id: payload.id,
            },
          },
        });
      })
      .addCase(createLineGeofeature.fulfilled, (state, { payload }) => {
        state.geofeaturesList.push({
          id: payload.id,
          color: payload.properties.color,
          icon: payload.properties.icon,
          name: payload.properties.name,
          description: payload.properties.description,
          folderId: payload.properties.folder,
          tags: payload.properties.tags,
          visibleOnMap: payload.properties.display ?? true,
          geoJSONData: {
            ...payload,
            properties: {
              ...payload.properties,
              id: payload.id,
            },
          },
        });
      })
      .addCase(createPointOfInterestGeofeature.fulfilled, (state, { payload }) => {
        state.geofeaturesList.push({
          id: payload.id,
          color: payload.properties.color,
          icon: payload.properties.icon,
          name: payload.properties.name,
          description: payload.properties.description,
          folderId: payload.properties.folder,
          tags: payload.properties.tags,
          visibleOnMap: payload.properties.display ?? true,
          geoJSONData: {
            ...payload,
            properties: {
              ...payload.properties,
              id: payload.id,
            },
          },
        });
      })
      .addCase(deleteGeofeature.fulfilled, (state, { payload }) => {
        state.geofeaturesList = state.geofeaturesList.filter(item => item.id !== payload);
      })
      .addCase(deleteGeofeatures.fulfilled, (state, { payload }) => {
        state.geofeaturesList = state.geofeaturesList.filter(item => !payload.includes(item.id));
      })
      .addCase(updateRadialGeofeatureData.fulfilled, (state, { payload }) => {
        state.geofeaturesList = state.geofeaturesList.map(item => {
          if (item.id === payload.geofeatureId) {
            return {
              ...item,
              color: payload.data.properties.color,
              description: payload.data.properties.description,
              icon: payload.data.properties.icon,
              name: payload.data.properties.name,
              tags: payload.data.properties.tags,
              visibleOnMap: payload.data.properties.display ?? true,
              id: payload.geofeatureId,
              folderId:
                typeof payload.data.properties.folder === 'number'
                  ? payload.data.properties.folder
                  : item.folderId,
              geoJSONData: {
                ...payload.data,
                properties: {
                  ...payload.data.properties,
                  id: payload.geofeatureId,
                  center: {
                    lat: payload.data.properties.lat,
                    lng: payload.data.properties.long,
                  },
                },
              },
            };
          }

          return item;
        });
      })
      .addCase(updatePolygonGeofeatureData.fulfilled, (state, { payload }) => {
        state.geofeaturesList = state.geofeaturesList.map(item => {
          if (item.id === payload.geofeatureId) {
            return {
              ...item,
              color: payload.data.properties.color,
              description: payload.data.properties.description,
              icon: payload.data.properties.icon,
              name: payload.data.properties.name,
              tags: payload.data.properties.tags,
              visibleOnMap: payload.data.properties.display ?? true,
              folderId:
                typeof payload.data.properties.folder === 'number'
                  ? payload.data.properties.folder
                  : item.folderId,
              geoJSONData: {
                ...payload.data,
                properties: {
                  ...payload.data.properties,
                  id: payload.geofeatureId,
                },
              },
            };
          }

          return item;
        });
      })
      .addCase(updateLineGeofeatureData.fulfilled, (state, { payload }) => {
        state.geofeaturesList = state.geofeaturesList.map(item => {
          if (item.id === payload.geofeatureId) {
            return {
              ...item,
              color: payload.data.properties.color,
              description: payload.data.properties.description,
              icon: payload.data.properties.icon,
              name: payload.data.properties.name,
              tags: payload.data.properties.tags,
              visibleOnMap: payload.data.properties.display ?? true,
              folderId:
                typeof payload.data.properties.folder === 'number'
                  ? payload.data.properties.folder
                  : item.folderId,
              geoJSONData: {
                ...payload.data,
                properties: {
                  ...payload.data.properties,
                  id: payload.geofeatureId,
                },
              },
            };
          }

          return item;
        });
      })
      .addCase(updatePointOfInterestGeofeatureData.fulfilled, (state, { payload }) => {
        state.geofeaturesList = state.geofeaturesList.map(item => {
          if (item.id === payload.geofeatureId) {
            return {
              ...item,
              color: payload.data.properties.color,
              description: payload.data.properties.description,
              icon: payload.data.properties.icon,
              name: payload.data.properties.name,
              tags: payload.data.properties.tags,
              visibleOnMap: payload.data.properties.display ?? true,
              folderId:
                typeof payload.data.properties.folder === 'number'
                  ? payload.data.properties.folder
                  : item.folderId,
              geoJSONData: {
                ...payload.data,
                properties: {
                  ...payload.data.properties,
                  id: payload.geofeatureId,
                },
              },
            };
          }

          return item;
        });
      })
      .addCase(updateGeofeaturesData.fulfilled, (state, { payload }) => {
        // @ts-expect-error Geofeature item is extracted in the same item
        state.geofeaturesList = state.geofeaturesList.map(item => {
          const geofeature = payload.find(payloadItem => payloadItem.id === item.id);

          if (geofeature) {
            const additionalProperties: Partial<GEOJSONData['properties']> = {
              id: geofeature.id,
            };

            if (geofeature.properties.shape === GEOJSONShapeType.CIRCLE) {
              (additionalProperties as RadialGEOJSONData['properties']).center = {
                lat: geofeature.properties.lat,
                lng: geofeature.properties.long,
              };
            }

            return {
              ...item,
              color: geofeature.properties.color,
              description: geofeature.properties.description,
              icon: geofeature.properties.icon,
              name: geofeature.properties.name,
              tags: geofeature.properties.tags,
              visibleOnMap: geofeature.properties.display ?? true,
              folderId:
                typeof geofeature.properties.folder === 'number'
                  ? geofeature.properties.folder
                  : item.folderId,
              geoJSONData: {
                ...item.geoJSONData,
                ...geofeature,
                properties: {
                  ...item.geoJSONData.properties,
                  ...geofeature.properties,
                  ...additionalProperties,
                },
              },
            };
          }

          return item;
        });
      }),
});

export const { openGeofeatureModal, closeGeofeatureModal, changeGeofeatureVisibility } =
  geofeaturesSlice.actions;

export default geofeaturesSlice.reducer;

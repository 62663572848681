import { ReportCategory, ReportCountry } from './interfaces';

export const getReportCategoriesNames = (categories: Array<ReportCategory>): Array<string> => {
  return categories.reduce((acc, category) => {
    const subcategories = category.mainCategory.subcategories.reduce(
      (subcategoryAcc, subcategory) => [...subcategoryAcc, subcategory.name],
      [] as Array<string>
    );

    return [...acc, ...subcategories];
  }, [] as Array<string>);
};

export const getReportCountriesNames = (countries: Array<ReportCountry>): string => {
  return countries
    .reduce((acc, region) => {
      const subcategories = region.region.countries.reduce(
        (countryAcc, country) => [...countryAcc, country.name],
        [] as Array<string>
      );

      return [...acc, ...subcategories];
    }, [] as Array<string>)
    .join(' - ');
};

import {
  CreateLineGeofeatureData,
  CreateLineGeofeatureResponse,
  CreatePointOfInterestGeofeatureData,
  CreatePointOfInterestGeofeatureResponse,
  CreatePolygonGeofeatureData,
  CreatePolygonGeofeatureResponse,
  CreateRadialGeofeatureData,
  CreateRadialGeofeatureResponse,
  GeofeatureCreateFolderData,
  GeofeatureCreateFolderResponse,
  GeofeatureEditFolderData,
  GeofeatureEditFolderResponse,
  UpdateLineGeofeatureData,
  UpdatePointOfInterestGeofeatureData,
  UpdatePolygonGeofeatureData,
  UpdateRadialGeofeatureData,
} from '@/features/Geofeatures/interfaces';

import { request } from '@/utils/request';
import { makeServerSideRequest } from '@/utils/serverSideRequest';

export const makeApi = (instance = request) => ({
  getFolders: () => instance.get<Array<GeofeatureCreateFolderResponse>>('folders/'),
  getSharedFolders: () => instance.get<Array<GeofeatureCreateFolderResponse>>('shared/folders/'),
  createFolder: (data: GeofeatureCreateFolderData) =>
    instance.post<GeofeatureCreateFolderResponse>('folder/create/', data),
  editFolder: (folderId: number, data: GeofeatureEditFolderData) =>
    instance.patch<GeofeatureEditFolderResponse>(`folder/${folderId}/edit/`, data),
  deleteFolder: (folderId: number) => instance.delete(`folder/${folderId}/delete/`),
  createRadialGeofeature: (data: CreateRadialGeofeatureData) =>
    instance.post<CreateRadialGeofeatureResponse>('geofeature/create/radial/', data),
  createPolygonGeofeature: (data: CreatePolygonGeofeatureData) =>
    instance.post<CreatePolygonGeofeatureResponse>('geofeature/create/polygon/', data),
  createLineGeofeature: (data: CreateLineGeofeatureData) =>
    instance.post<CreateLineGeofeatureResponse>('geofeature/create/line/', data),
  createPointOfInterestGeofeature: (data: CreatePointOfInterestGeofeatureData) =>
    instance.post<CreatePointOfInterestGeofeatureResponse>('geofeature/create/point/', data),
  deleteGeofeature: (geofeatureId: string) => instance.delete(`geofeature/${geofeatureId}/delete/`),
  updateRadialGeofeatureData: (geofeatureId: string, data: UpdateRadialGeofeatureData) =>
    instance.patch<UpdateRadialGeofeatureData>(`geofeature/${geofeatureId}/edit/radial/`, data),
  updatePolygonGeofeatureData: (geofeatureId: string, data: UpdatePolygonGeofeatureData) =>
    instance.patch<UpdatePolygonGeofeatureData>(`geofeature/${geofeatureId}/edit/polygon/`, data),
  updateLineGeofeatureData: (geofeatureId: string, data: UpdateLineGeofeatureData) =>
    instance.patch<UpdateLineGeofeatureData>(`geofeature/${geofeatureId}/edit/line/`, data),
  updatePointOfInterestGeofeatureData: (
    geofeatureId: string,
    data: UpdatePointOfInterestGeofeatureData
  ) =>
    instance.patch<UpdatePointOfInterestGeofeatureData>(
      `geofeature/${geofeatureId}/edit/point/`,
      data
    ),
});

export const api = makeApi();
export const makeServerSideApi = (token: string) => makeApi(makeServerSideRequest(token));

import { ChangeEvent, useCallback, useState } from 'react';
import { useTheme } from 'styled-components';

import { Modal } from '@/core/components/Modal';
import { Heading } from '@/core/components/Typography';
import { Icon } from '@/core/components/Icons';
import { Button, ButtonVariant } from '@/core/components/Button';
import { IconPickerIcon, IconPickerIcons, iconPickerIcons } from '@/core/constants/icons';

import { IconsModalProps } from './IconsModal.types';
import {
  ButtonsContainer,
  IconsList,
  CloseButton,
  Container,
  ColorButton,
  SearchContainer,
  SearchInput,
} from './IconsModal.styles';

export const IconsModal = ({ isOpen, icon, onIconApply, onClose }: IconsModalProps) => {
  const { colors } = useTheme();
  const [pickedIcon, setPickedIcon] = useState(icon);
  const [iconsToPick, setIconsToPick] = useState<Array<IconPickerIcon>>(iconPickerIcons);
  const [searchValue, setSearchValue] = useState('');

  const onIconPick = useCallback((iconToPick: IconPickerIcons) => {
    setPickedIcon(iconToPick);
  }, []);

  const onApplyClick = useCallback(() => {
    onIconApply(pickedIcon);
    onClose();
  }, [onClose, onIconApply, pickedIcon]);

  const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let filteredIcons = iconPickerIcons;

    setSearchValue(value);

    if (value) {
      filteredIcons = iconPickerIcons.filter(({ iconName }) =>
        iconName.toLowerCase().includes(value.toLowerCase())
      );
    }

    setIconsToPick(filteredIcons);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <Container>
        <CloseButton onClick={onClose}>
          <Icon
            size={19}
            name="Close"
          />
        </CloseButton>
        <Heading type="h3">Change icon</Heading>
        <SearchContainer>
          <Icon
            name="Search"
            size={20}
            color={colors.secondaryText}
          />
          <SearchInput
            placeholder="Search in icons"
            value={searchValue}
            onChange={onInputChange}
          />
        </SearchContainer>
        <IconsList>
          {iconsToPick.map(iconToPick => (
            <ColorButton
              key={iconToPick.iconName}
              isActive={iconToPick.iconName === pickedIcon}
              onClick={() => onIconPick(iconToPick.iconName)}
            >
              <Icon
                size={25}
                name={iconToPick.iconName}
              />
            </ColorButton>
          ))}
        </IconsList>
        <ButtonsContainer>
          <Button onClick={onApplyClick}>Apply changes</Button>
          <Button
            variant={ButtonVariant.TERTIARY}
            onClick={onClose}
          >
            Cancel
          </Button>
        </ButtonsContainer>
      </Container>
    </Modal>
  );
};

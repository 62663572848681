import styled, { css } from 'styled-components';

import { ThemeVariant } from '@/core/interfaces/common';

import { getVariant } from '@/themes/typographyUtilis';

export const InputContainer = styled.div`
  position: relative;
`;

export const StyledInput = styled.input<{
  withPrefixIcon?: boolean;
  withSuffixIcon?: boolean;
  withError?: boolean;
  centered?: boolean;
}>`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none !important;
  }

  &[type='number'] {
    appearance: textfield !important;
  }
  width: 100%;
  border: 1px solid
    ${({ theme, withError }) =>
      withError
        ? theme.colors.warning
        : theme.theme === ThemeVariant.DARK
          ? theme.colors.background.light
          : theme.colors.border};
  border-radius: 4px;
  padding: 12px 19px;
  background: ${({ theme }) => theme.colors.background.light};
  color: ${({ theme }) => theme.colors.primaryText};
  ${getVariant({ variant: 'body1-normal' })};
  text-align: left;

  &:focus {
    outline: none;
    border-color: ${({ theme, withError }) =>
      withError
        ? theme.colors.warning
        : theme.theme === ThemeVariant.DARK
          ? theme.colors.background.light
          : theme.colors.active};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.secondaryText};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.border};
  }

  ${({ withPrefixIcon }) =>
    withPrefixIcon &&
    css`
      padding-left: 45px;
    `}
  ${({ withSuffixIcon }) =>
    withSuffixIcon &&
    css`
      padding-right: 45px;
    `}
    ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `}
`;

export const PrefixIconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
`;

export const SuffixIconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
`;

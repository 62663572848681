import { ChangeEvent, useCallback, useState } from 'react';

import { Input } from '@/core/components/Input';
import { TextBodyNormal } from '@/core/components/Typography/Typography.styles';
import { Icon } from '@/core/components/Icons';
import { SearchSuggestionsList } from '@/core/components/SearchSuggestionsList';

import {
  GeofenceItem,
  GeofencesListContainer,
  RemoveGeofenceButton,
  SearchContainer,
} from './GeofeaturesList.styles';
import { GeofeaturesListProps } from './GeofeaturesList.types';
import { LocationFilterItem } from '../../LocationFilter.types';

export const GeofeaturesList = <FilterItem extends LocationFilterItem>({
  geofeaturesList,
  searchPlaceholder,
  searchSuggestions,
  onSearch,
  onGeofeatureRemoveClick,
  onGeofeatureSearchSelect,
  onGeofenceItemClick,
}: GeofeaturesListProps<FilterItem>) => {
  const [searchValue, setSearchValue] = useState('');

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value;

    setSearchValue(searchText);
    onSearch(searchText);
  };

  const onSearchBlur = useCallback(() => {
    setTimeout(() => {
      setSearchValue('');
      onSearch('');
    }, 150);
  }, [onSearch]);

  return (
    <>
      <SearchContainer>
        <Input
          placeholder={searchPlaceholder}
          type="search"
          value={searchValue}
          onChange={onSearchChange}
          onBlur={onSearchBlur}
        />
        {searchSuggestions.length > 0 && (
          <SearchSuggestionsList
            offsetY={50}
            suggestionsList={searchSuggestions}
            onSelectSuggestion={onGeofeatureSearchSelect}
          />
        )}
      </SearchContainer>
      <GeofencesListContainer>
        {geofeaturesList.map(geofeature => (
          <GeofenceItem
            key={String(geofeature.value)}
            onClick={() => {
              onGeofenceItemClick?.(geofeature);
            }}
          >
            <TextBodyNormal>{geofeature.label}</TextBodyNormal>
            <RemoveGeofenceButton
              onClick={event => {
                event.stopPropagation();
                onGeofeatureRemoveClick(geofeature.transformedLocationValue);
              }}
            >
              <Icon name="Trash" />
            </RemoveGeofenceButton>
          </GeofenceItem>
        ))}
      </GeofencesListContainer>
    </>
  );
};

import styled from 'styled-components';

import { getVariant } from '@/themes/typographyUtilis';

export const Input = styled.input`
  width: 100%;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  padding: 10px 15px;
`;
export const FieldErrorMessage = styled.div`
  margin-top: 5px;
  ${getVariant({ variant: 'body2-normal' })}
  color: ${({ theme }) => theme.colors.warning};
`;

import {
  SelectTimeframeOptionObjectType,
  TimeframeDropdownOptions,
  TimeframeFilterItem,
} from '@/core/components/MapFilters/TimeframeFilter/TimeframeFilter.types';
import { AutoDateRange } from '@/core/interfaces/filters';

const secondsInMinute = 60;
const minutesInHour = 60;
const hoursInDay = 24;
const daysInMonth = 30;
const daysInQuoter = 90;
const daysInWeek = 7;

export const timeframeRadioButtonData: Array<TimeframeFilterItem> = [
  {
    name: 'Last 30 minutes',
    label: '30m',
    value: secondsInMinute * 30,
  },
  {
    name: 'Last 1 hour',
    label: '1h',
    value: secondsInMinute * minutesInHour,
  },
  {
    name: 'Last 3 hours',
    label: '3h',
    value: secondsInMinute * minutesInHour * 3,
  },
  {
    name: 'Last 24 hours',
    label: '24h',
    value: secondsInMinute * minutesInHour * hoursInDay,
  },
  {
    name: 'Last 7 days',
    label: '7d',
    value: secondsInMinute * minutesInHour * hoursInDay * daysInWeek,
  },
  {
    name: 'Last 30 days',
    label: '30d',
    value: secondsInMinute * minutesInHour * hoursInDay * daysInMonth,
  },
  {
    name: 'Last 90 days',
    label: '90d',
    value: secondsInMinute * minutesInHour * hoursInDay * daysInQuoter,
  },
  {
    name: 'Custom',
    label: 'Custom',
    value: 'custom',
  },
];

export const timeframeCustomTimeData = [
  {
    name: 'minutes',
    label: 'minutes',
    value: 'minutes',
    amountInSeconds: secondsInMinute,
  },
  {
    name: 'hours',
    label: 'hours',
    value: 'hours',
    amountInSeconds: secondsInMinute * minutesInHour,
  },
  {
    name: 'days',
    label: 'days',
    value: 'days',
    amountInSeconds: secondsInMinute * minutesInHour * hoursInDay,
  },
  {
    name: 'months',
    label: 'months',
    value: 'months',
    amountInSeconds: secondsInMinute * minutesInHour * hoursInDay * daysInMonth,
  },
];

export const dropdownTimeframeOptions: SelectTimeframeOptionObjectType = [
  {
    label: 'Today',
    value: TimeframeDropdownOptions.TODAY,
    // @ts-expect-error `apiValue` is not required in the select option, it's only used to get value for the API
    apiValue: AutoDateRange.TODAY,
  },
  {
    label: 'Past 7 days',
    value: TimeframeDropdownOptions.PAST_7_DAYS,
    // @ts-expect-error `apiValue` is not required in the select option, it's only used to get value for the API
    apiValue: AutoDateRange.PAST_7_DAYS,
  },
  {
    label: 'Past 30 days',
    value: TimeframeDropdownOptions.PAST_30_DAYS,
    // @ts-expect-error `apiValue` is not required in the select option, it's only used to get value for the API
    apiValue: AutoDateRange.PAST_30_DAYS,
  },
  {
    label: 'Next 7 days',
    value: TimeframeDropdownOptions.NEXT_7_DAYS,
    // @ts-expect-error `apiValue` is not required in the select option, it's only used to get value for the API
    apiValue: AutoDateRange.NEXT_7_DAYS,
  },
  {
    label: 'Next 30 days',
    value: TimeframeDropdownOptions.NEXT_30_DAYS,
    // @ts-expect-error `apiValue` is not required in the select option, it's only used to get value for the API
    apiValue: AutoDateRange.NEXT_30_DAYS,
  },
  {
    label: 'Custom timeframe',
    value: TimeframeDropdownOptions.CUSTOM,
  },
];

import styled from 'styled-components';

import { getVariant } from '@/themes/typographyUtilis';

export const Suggestion = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
  width: 100%;
  padding: 10px 30px;
`;

export const SuggestionContent = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

export const RoleBadge = styled.span`
  border-radius: 4px;
  padding: 4px 11px;
  background-color: ${({ theme }) => theme.colors.active};
  ${getVariant({ variant: 'body2-normal' })}
`;

export const AddButton = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 6px;
  padding: 5px 12px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.border};
  ${getVariant({ variant: 'body2-normal' })}
  cursor: pointer;
`;

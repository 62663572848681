export enum ReportScore {
  UNKNOWN = 'Unknown',
  LOW = 'Unreliable',
  MEDIUM = 'Partially reliable',
  HIGH = 'Reliable',
}

export type SourceProps = {
  publicationDate: string;
  link: string;
  score: ReportScore;
};

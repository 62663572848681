import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components';

import { ThemeVariant } from '@/core/interfaces/common';

import { isG4S } from '@/utils/helpers';

import { GlobalStyle } from '@/themes/globalStyles';
import { darkTheme as darkThemeMain, lightTheme as lightThemeMain } from '@/themes/main';
import { darkThemeG4S, lightThemeG4S } from '@/themes/g4s';

import { CustomThemeProviderProps } from './CustomThemeContext.types';

export type CustomThemeContextType = {
  themeVariant: ThemeVariant;
  setThemeVariant: (variant: ThemeVariant) => void;
  setReportListItemFontSizeFactor: (factor: number) => void;
  setUseAnimations: (useAnimations: boolean) => void;
};

export const CustomThemeContext = createContext<CustomThemeContextType>({
  themeVariant: ThemeVariant.AUTO,
  setThemeVariant: () => {},
  setReportListItemFontSizeFactor: () => {},
  setUseAnimations: () => {},
});

const defaultReportListItemFontSizeFactor = 1;
const defaultUseAnimations = true;

const getTheme = () => {
  let darkTheme = darkThemeMain;
  let lightTheme = lightThemeMain;

  if (isG4S) {
    darkTheme = darkThemeG4S;
    lightTheme = lightThemeG4S;
  }

  return {
    [ThemeVariant.DARK]: darkTheme,
    [ThemeVariant.LIGHT]: lightTheme,
  };
};

const darkTheme = getTheme()[ThemeVariant.DARK];
const lightTheme = getTheme()[ThemeVariant.LIGHT];

export const CustomThemeProvider = ({ children }: CustomThemeProviderProps) => {
  const [themeVariant, setThemeVariant] = useState<ThemeVariant>(ThemeVariant.AUTO);
  const [reportListItemFontSizeFactor, setReportListItemFontSizeFactor] = useState(
    defaultReportListItemFontSizeFactor
  );
  const [useAnimations, setUseAnimations] = useState(defaultUseAnimations);

  const themeObject = useMemo<DefaultTheme>(() => {
    const userHasDarkTheme =
      typeof window !== 'undefined' && window.matchMedia('(prefers-color-scheme: dark)');

    let userTheme = userHasDarkTheme ? darkTheme : lightTheme;

    if (themeVariant === ThemeVariant.DARK) {
      userTheme = darkTheme;
    } else if (themeVariant === ThemeVariant.LIGHT) {
      userTheme = lightTheme;
    }

    return {
      ...userTheme,
      reportListItemFontSizeFactor,
      useAnimations,
    };
  }, [reportListItemFontSizeFactor, themeVariant, useAnimations]);

  const handleThemeVariantChange = useCallback((newThemeVariant: ThemeVariant) => {
    setThemeVariant(newThemeVariant);
    localStorage.setItem('theme', newThemeVariant);
  }, []);

  const handleReportListItemFontSizeFactorChange = useCallback((newFactor: number) => {
    setReportListItemFontSizeFactor(newFactor);
    localStorage.setItem('reportListItemFontSizeFactor', String(newFactor));
  }, []);

  const handleUseAnimationsChange = useCallback((newUseAnimations: boolean) => {
    setUseAnimations(newUseAnimations);
    localStorage.setItem('useAnimations', String(newUseAnimations));
  }, []);

  useEffect(() => {
    // Theme variant
    const userSelectedTheme = localStorage.getItem('theme') as ThemeVariant | null;

    if (!userSelectedTheme || userSelectedTheme === ThemeVariant.AUTO) {
      handleThemeVariantChange(ThemeVariant.AUTO);
    } else if (userSelectedTheme === ThemeVariant.DARK) {
      handleThemeVariantChange(ThemeVariant.DARK);
    } else {
      handleThemeVariantChange(ThemeVariant.LIGHT);
    }

    // Report list item font size factor
    const userSelectedReportListItemFontSizeFactor = localStorage.getItem(
      'reportListItemFontSizeFactor'
    );

    setReportListItemFontSizeFactor(
      Number(userSelectedReportListItemFontSizeFactor) || defaultReportListItemFontSizeFactor
    );

    if (!userSelectedReportListItemFontSizeFactor) {
      localStorage.setItem(
        'reportListItemFontSizeFactor',
        userSelectedReportListItemFontSizeFactor || String(defaultReportListItemFontSizeFactor)
      );
    }

    // Animations
    let userSelectedUseAnimations = localStorage.getItem('useAnimations');

    if (userSelectedUseAnimations === null) {
      userSelectedUseAnimations = String(defaultUseAnimations);
    }

    setUseAnimations(userSelectedUseAnimations === String(defaultUseAnimations));
    localStorage.setItem(
      'useAnimations',
      String(userSelectedUseAnimations === String(defaultUseAnimations))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomThemeContext.Provider
      value={{
        themeVariant,
        setThemeVariant: handleThemeVariantChange,
        setReportListItemFontSizeFactor: handleReportListItemFontSizeFactorChange,
        setUseAnimations: handleUseAnimationsChange,
      }}
    >
      <ThemeProvider theme={themeObject}>
        <GlobalStyle theme={themeObject} />
        {children}
      </ThemeProvider>
    </CustomThemeContext.Provider>
  );
};

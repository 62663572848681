import styled from 'styled-components';

import { TextSidebarNormalBold } from '@/core/components/Typography/Typography.styles';

export const Container = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

export const NameButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  padding: 16px 20px;
  background: none;
  cursor: pointer;
`;

export const GroupNameContainer = styled(TextSidebarNormalBold)`
  border: none;
  background: none;
  color: ${({ theme }) => theme.colors.secondaryText};
  text-transform: uppercase;
`;

export const FiltersWrapper = styled.div<{ isExpanded: boolean }>`
  display: ${({ isExpanded }) => (isExpanded ? 'block' : 'none')};
`;

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/core/interfaces/store';

const focusesSelector = (state: RootState) => state.focuses;

export const getMyFocusesSelector = createSelector(
  [focusesSelector],
  focusesState => focusesState.focuses
);
export const getSharedFocusesSelector = createSelector(
  [focusesSelector],
  focusesState => focusesState.sharedFocuses
);
export const getAllFocusesSelector = createSelector(focusesSelector, state => [...state.focuses]);
export const getSelectedFocusSelector = createSelector(
  focusesSelector,
  state => state.selectedFocus
);

export const focusModalSelector = createSelector(focusesSelector, state => state.focusModal);

export const iFocusModalOpenSelector = createSelector(
  focusModalSelector,
  state => state === 'edit' || state === 'create'
);

export const getDraftFocusSelector = createSelector(focusesSelector, state => state.draftFocus);

export const isOwnFocusSelector = createSelector(
  [focusesSelector, (_state, focusId: number | undefined) => focusId],
  (state, focusId) => !!focusId && !!state.focuses.find(focus => focus.id === focusId)
);

import NextImage from 'next/image';
import { useTheme } from 'styled-components';
import { useContext, useEffect, useMemo } from 'react';

import { Button, ButtonVariant } from '@/core/components/Button';
import { Icon } from '@/core/components/Icons';
import { MapContext } from '@/core/context/MapContext';
import { useMapStyleName } from '@/core/hooks/useMapStyleName';

import {
  drawRadialGeofeature,
  getCircleDataName,
  getCircleFillLayerName,
  getCircleStrokeLayerName,
  removeRadialGeofeature,
} from '@/utils/map/drawRadial';
import {
  drawPolygon,
  getPolygonDataName,
  getPolygonFillLayerName,
  getPolygonStrokeLayerName,
  removePolygonGeofeature,
} from '@/utils/map/drawPolygon';
import {
  drawLine,
  getLineBufferDataName,
  getLineBufferFillLayerName,
  getLineBufferStrokeLayerName,
  getLineDataName,
  removeLineGeofeature,
} from '@/utils/map/drawLine';

import { CreateGeofenceViewProps, GeofenceType } from './CreateGeofenceView.types';
import {
  ButtonsContainer,
  GeofenceItem,
  GeofencesContainer,
  ItemName,
  ItemText,
  SaveContainer,
  SaveInfoContainer,
  ShapeName,
  ShapeNameContainer,
  ShapeNameIconContainer,
} from './CreateGeofenceView.styles';

export const CreateGeofenceView = ({
  createdGeofences,
  isEditMode,
  onGeofenceClick,
  onGeofencesSave,
  onGeofenceCancel,
}: CreateGeofenceViewProps) => {
  const { mapRef } = useContext(MapContext);
  const { colors } = useTheme();

  const mapStyleName = useMapStyleName();

  const geofeatureAdded = useMemo(
    () => !!Object.keys(createdGeofences || {}).length,
    [createdGeofences]
  );

  useEffect(() => {
    if (!createdGeofences) {
      return;
    }

    const radialData = createdGeofences[GeofenceType.RADIAL];
    const polygonData = createdGeofences[GeofenceType.POLYGON];
    const lineData = createdGeofences[GeofenceType.LINE];

    if (radialData) {
      const radialId = radialData.properties.id;

      drawRadialGeofeature(mapRef, {
        lineType: 'solid',
        center: [radialData.properties.center.lng, radialData.properties.center.lat],
        radius: radialData.properties.radius,
        units: radialData.properties.radiusUnit,
        color: radialData.properties.color,
        circleDataName: getCircleDataName(radialId),
        circleFillLayerName: getCircleFillLayerName(radialId),
        circleStrokeLayerName: getCircleStrokeLayerName(radialId),
      });
    }

    if (polygonData) {
      const polygonId = String(polygonData.properties.id);

      drawPolygon(mapRef, {
        color: polygonData.properties.color,
        polygonData,
        polygonDataName: getPolygonDataName(polygonId),
        polygonFillLayerName: getPolygonFillLayerName(polygonId),
        polygonStrokeLayerName: getPolygonStrokeLayerName(polygonId),
      });
    }

    if (lineData) {
      const lineId = String(lineData.properties.id);

      drawLine(mapRef, {
        color: lineData.properties.color,
        lineData,
        lineDataName: getLineDataName(lineId),
        lineLayerName: getLineDataName(lineId),
        lineBufferDataName: getLineBufferDataName(lineId),
        lineBufferFillLayerName: getLineBufferFillLayerName(lineId),
        lineBufferStrokeLayerName: getLineBufferStrokeLayerName(lineId),
        radius: lineData.properties.radius,
        radiusUnit: lineData.properties.radiusUnit,
      });
    }
  }, [mapStyleName, createdGeofences, mapRef]);

  const isRadialActive = useMemo(
    () => !!createdGeofences?.[GeofenceType.RADIAL],
    [createdGeofences]
  );
  const isPolygonActive = useMemo(
    () => !!createdGeofences?.[GeofenceType.POLYGON],
    [createdGeofences]
  );
  const isLineActive = useMemo(() => !!createdGeofences?.[GeofenceType.LINE], [createdGeofences]);

  useEffect(() => {
    return () => {
      if (!createdGeofences) {
        return;
      }

      const radialData = createdGeofences[GeofenceType.RADIAL];
      const polygonData = createdGeofences[GeofenceType.POLYGON];
      const lineData = createdGeofences[GeofenceType.LINE];

      if (radialData) {
        removeRadialGeofeature(mapRef, {
          circleDataName: getCircleDataName(radialData.properties.id),
          circleFillLayerName: getCircleFillLayerName(radialData.properties.id),
          circleStrokeLayerName: getCircleStrokeLayerName(radialData.properties.id),
        });
      }

      if (polygonData) {
        const polygonId = String(polygonData.properties.id);

        removePolygonGeofeature(mapRef, {
          polygonDataName: getPolygonDataName(polygonId),
          polygonFillLayerName: getPolygonFillLayerName(polygonId),
          polygonStrokeLayerName: getPolygonStrokeLayerName(polygonId),
        });
      }

      if (lineData) {
        const lineId = String(lineData.properties.id);

        removeLineGeofeature(mapRef, {
          lineDataName: getLineDataName(lineId),
          lineLayerName: getLineDataName(lineId),
          lineBufferDataName: getLineBufferDataName(lineId),
          lineBufferFillLayerName: getLineBufferFillLayerName(lineId),
          lineBufferStrokeLayerName: getLineBufferStrokeLayerName(lineId),
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GeofencesContainer>
        <GeofenceItem
          type="button"
          backgroundColor={colors.geofenceCreateItem.radialBackground}
          onClick={() => onGeofenceClick(GeofenceType.RADIAL)}
          isActive={isRadialActive}
        >
          <NextImage
            src="assets/radial.svg"
            alt="Radial Geofence"
            width={54}
            height={54}
          />
          <div>
            <ItemName isActive={isRadialActive}>Radial Geofence</ItemName>
            <ItemText isActive={isRadialActive}>Select a circular area to be monitored.</ItemText>
            {createdGeofences?.[GeofenceType.RADIAL] && (
              <ShapeNameContainer>
                <ShapeNameIconContainer>
                  <Icon
                    name="Check"
                    color={colors.white}
                    size={12}
                  />
                </ShapeNameIconContainer>
                <ShapeName>{createdGeofences[GeofenceType.RADIAL].properties.name}</ShapeName>
              </ShapeNameContainer>
            )}
          </div>
        </GeofenceItem>
        <GeofenceItem
          type="button"
          backgroundColor={colors.geofenceCreateItem.polygonBackground}
          onClick={() => onGeofenceClick(GeofenceType.POLYGON)}
          isActive={isPolygonActive}
        >
          <NextImage
            src="assets/polygon.svg"
            alt="Polygon Geofence"
            width={56}
            height={59}
          />
          <div>
            <ItemName isActive={isPolygonActive}>Polygon Geofence</ItemName>
            <ItemText isActive={isPolygonActive}>Select a polygon area to be monitored.</ItemText>
            {createdGeofences?.[GeofenceType.POLYGON] && (
              <ShapeNameContainer>
                <ShapeNameIconContainer>
                  <Icon
                    name="Check"
                    color={colors.white}
                    size={12}
                  />
                </ShapeNameIconContainer>
                <ShapeName>{createdGeofences[GeofenceType.POLYGON].properties.name}</ShapeName>
              </ShapeNameContainer>
            )}
          </div>
        </GeofenceItem>
        <GeofenceItem
          type="button"
          backgroundColor={colors.geofenceCreateItem.lineBackground}
          onClick={() => onGeofenceClick(GeofenceType.LINE)}
          isActive={!!createdGeofences?.[GeofenceType.LINE]}
        >
          <NextImage
            src="assets/line.svg"
            alt="Line Geofence"
            width={53}
            height={59}
          />
          <div>
            <ItemName isActive={isLineActive}>Line Geofence</ItemName>
            <ItemText isActive={isLineActive}>Select points to create path in beteween</ItemText>
            {createdGeofences?.[GeofenceType.LINE] && (
              <ShapeNameContainer>
                <ShapeNameIconContainer>
                  <Icon
                    name="Check"
                    color={colors.white}
                    size={12}
                  />
                </ShapeNameIconContainer>
                <ShapeName>{createdGeofences[GeofenceType.LINE].properties.name}</ShapeName>
              </ShapeNameContainer>
            )}
          </div>
        </GeofenceItem>
      </GeofencesContainer>
      <SaveContainer>
        {!geofeatureAdded && (
          <SaveInfoContainer>
            <Icon name="InfoCircle" />
            You need to draw your geofeature first.
          </SaveInfoContainer>
        )}
        <ButtonsContainer>
          <Button onClick={onGeofencesSave}>Save</Button>
          {isEditMode && (
            <Button
              onClick={onGeofenceCancel || (() => {})}
              variant={ButtonVariant.TERTIARY}
            >
              Cancel
            </Button>
          )}
        </ButtonsContainer>
      </SaveContainer>
    </>
  );
};

import { SelectOptionType } from '@/core/components/Select/SelectComponent.types';
import { MultiButtonItem } from '@/core/components/MultiButton';

export type TimeframeFilterItem = MultiButtonItem<number | string | 'custom' | null>;

export type TimeframeDateTabsProps = {
  name: string;
  label: string;
  value: 'publicationDate' | 'eventDate' | null;
};

export type TimeframeFilterProps = {
  timeframeFiltersData: Array<TimeframeFilterItem> | Array<TimeframeDateTabsProps>;
};

export enum TimeframeTabViewType {
  PUBLICATION_DATE = 'publication_date',
  EVENT_DATE = 'event_date',
}

export enum TimeframeDropdownOptions {
  TODAY,
  PAST_7_DAYS,
  PAST_30_DAYS,
  NEXT_7_DAYS,
  NEXT_30_DAYS,
  CUSTOM,
}

export type SelectTimeframeOptionType = SelectOptionType<TimeframeDropdownOptions>;

export type SelectTimeframeOptionObjectType = Array<SelectTimeframeOptionType>;

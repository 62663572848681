import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { AddButton } from '@/core/components/AddButton';

import { getFoldersListSelector, getGeofeaturesListSelector } from '@/features/Geofeatures/store';

import { NewGeofenceButtonContainer } from './GeofencesList.styles';
import { GeofencesListProps } from './GeofencesList.types';
import {
  GeofeatureListSuggestionItem,
  GeofeatureListSuggestionItemType,
  GeofeaturesList,
} from '../GeofeaturesList';

export const GeofencesList = ({
  geofencesList,
  onGeofenceAddClick,
  onGeofenceRemoveClick,
  onGeofenceSearchSelect,
  onGeofenceItemClick,
}: GeofencesListProps) => {
  const allGeofencesList = useSelector(getGeofeaturesListSelector);
  const allFoldersList = useSelector(getFoldersListSelector);

  const [searchValue, setSearchValue] = useState('');

  const filteredGeofencesList = useMemo<Array<GeofeatureListSuggestionItem>>(() => {
    if (!searchValue) {
      return [];
    }

    const searchPhrase = searchValue.toLowerCase();

    const folders = allFoldersList
      .filter(folder => {
        return folder.name.toLowerCase().includes(searchPhrase);
      })
      .map<GeofeatureListSuggestionItem>(folder => ({
        type: GeofeatureListSuggestionItemType.FOLDER,
        item: folder,
        name: folder.name,
      }));

    const geofences = allGeofencesList
      .filter(geofence => {
        return (
          (geofence.name.toLowerCase().includes(searchPhrase) ||
            geofence.description?.toLowerCase().includes(searchPhrase) ||
            geofence.tags.some(tag => tag.toLowerCase().includes(searchPhrase))) &&
          !geofencesList.some(
            ({ transformedLocationValue }) =>
              transformedLocationValue.value.properties.id === geofence.id
          )
        );
      })
      .map<GeofeatureListSuggestionItem>((geofence, index) => ({
        type: GeofeatureListSuggestionItemType.GEOFEATURE,
        item: geofence,
        name: geofence.name,
        withSeparator: index === 0 && folders.length > 0,
      }));

    return [...folders, ...geofences];
  }, [allFoldersList, allGeofencesList, geofencesList, searchValue]);

  return (
    <>
      <GeofeaturesList
        geofeaturesList={geofencesList}
        onGeofeatureRemoveClick={onGeofenceRemoveClick}
        searchPlaceholder="Search in geofences"
        searchSuggestions={filteredGeofencesList}
        onSearch={setSearchValue}
        onGeofeatureSearchSelect={onGeofenceSearchSelect}
        onGeofenceItemClick={onGeofenceItemClick}
      />
      <NewGeofenceButtonContainer>
        <AddButton
          onClick={onGeofenceAddClick}
          text="Add geofence"
        />
      </NewGeofenceButtonContainer>
    </>
  );
};

import styled from 'styled-components';

import { TypographyProps } from '@/core/components/Typography/Typography.types';

import { getVariant } from '@/themes/typographyUtilis';

export const TextH1 = styled.p<TypographyProps>`
  color: ${({ color, theme }) => color || theme.colors.primaryText};
  ${getVariant({ variant: 'h1' })}
`;

export const TextH2 = styled.p<TypographyProps>`
  color: ${({ color, theme }) => color || theme.colors.primaryText};
  ${getVariant({ variant: 'h2' })}
`;

export const TextH3 = styled.p<TypographyProps>`
  color: ${({ color, theme }) => color || theme.colors.primaryText};
  ${getVariant({ variant: 'h3' })}
`;

export const TextSubheadline = styled.p<TypographyProps>`
  color: ${({ color, theme }) => color || theme.colors.primaryText};
  ${getVariant({ variant: 'subheadline1-normal' })}
`;

export const TextSubheadlineSemibold = styled.p<TypographyProps>`
  color: ${({ color, theme }) => color || theme.colors.primaryText};
  ${getVariant({ variant: 'subheadline1-medium' })}
`;

export const TextSubheadlineSmall = styled.p<TypographyProps>`
  color: ${({ color, theme }) => color || theme.colors.primaryText};
  ${getVariant({ variant: 'subheadline2-medium' })}
`;

export const TextBodyNormal = styled.p<TypographyProps>`
  color: ${({ color, theme }) => color || theme.colors.primaryText};
  ${getVariant({ variant: 'body1-normal' })}
`;

export const TextBodyBold = styled.p<TypographyProps>`
  color: ${({ color, theme }) => color || theme.colors.primaryText};
  ${getVariant({ variant: 'body1-bold' })}
`;

export const TextBodySmallNormal = styled.p<TypographyProps>`
  color: ${({ color, theme }) => color || theme.colors.primaryText};
  ${getVariant({ variant: 'body2-normal' })}
`;

export const TextBodySmallBold = styled.p<TypographyProps>`
  color: ${({ color, theme }) => color || theme.colors.primaryText};
  ${getVariant({ variant: 'body2-bold' })}
`;

export const TextBodyTiny = styled.p<TypographyProps>`
  color: ${({ color, theme }) => color || theme.colors.primaryText};
  ${getVariant({ variant: 'body3-normal' })}
`;

export const TextButton = styled.p<TypographyProps>`
  color: ${({ color, theme }) => color || theme.colors.primaryText};
  ${getVariant({ variant: 'button1' })}
`;

export const TextButtonSmall = styled.p<TypographyProps>`
  color: ${({ color, theme }) => color || theme.colors.primaryText};
  ${getVariant({ variant: 'button2' })}
`;

export const TextSidebarNormalBold = styled.p<TypographyProps>`
  color: ${({ color, theme }) => color || theme.colors.primaryText};
  ${getVariant({ variant: 'sidebarOne' })}
`;

export const TextSidebarSmallBold = styled.p<TypographyProps>`
  color: ${({ color, theme }) => color || theme.colors.primaryText};
  ${getVariant({ variant: 'sidebarTwo' })}
`;

export const TextCaptionBold = styled.p<TypographyProps>`
  color: ${({ color, theme }) => color || theme.colors.primaryText};
  ${getVariant({ variant: 'caption-bold' })}
`;

export const TextCaptionNormal = styled.p<TypographyProps>`
  color: ${({ color, theme }) => color || theme.colors.primaryText};
  ${getVariant({ variant: 'caption-normal' })}
`;

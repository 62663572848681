import styled from 'styled-components';

export const MenuNav = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const MenuList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const MenuLink = styled.a<{ isActive?: boolean }>`
  position: relative;
  display: block;
  padding: 25px 20px 20px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryText};
  }

  &::after {
    content: ${({ isActive }) => (isActive ? "' '" : 'none')};
    position: absolute;
    bottom: 0;
    left: 10px;
    display: inline-block;
    width: calc(100% - 20px);
    height: 4px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.active};
  }
`;

export const RightMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 33px;
  padding: 0 14px;
`;

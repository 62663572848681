import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { CategoriesResponse } from '@/core/interfaces/categories';
import { CountriesResponse } from '@/core/interfaces/countries';

import {
  FocusAlertItem,
  FocusesState,
  FocusNotificationDayOfWeek,
  FocusResponseItem,
  FocusType,
} from '@/features/Focus/interfaces';
import {
  createFocus,
  createFocusAlert,
  deleteFocus,
  deleteFocusAlert,
  getFocusesList,
  getSharedFocusesList,
  updateFocus,
  updateFocusAlert,
} from '@/features/Focus/store';
import { daysForWeekdays, transformFocusDataToAppliedFilters } from '@/features/Focus/utils';

const initialState: FocusesState = {
  selectedFocus: null,
  focuses: [],
  sharedFocuses: [],
  focusModal: null,
  draftFocus: null,
};

const mapFocusDataToFocus = (
  focusData: FocusResponseItem,
  {
    alerts = [],
    categories,
    countries,
  }: {
    alerts?: Array<FocusAlertItem>;
    categories: CategoriesResponse;
    countries: CountriesResponse;
  }
): FocusType => {
  const focusAlert = alerts.find(alert => alert.focus === focusData.id);
  let alertTime = dayjs().utc();
  let alertTimeInLocal: dayjs.Dayjs | null = null;

  if (focusAlert?.timeOfNotification) {
    const emailFrequencyDay = focusAlert.dayOfTheWeek
      ? daysForWeekdays.indexOf(focusAlert.dayOfTheWeek)
      : undefined;
    const [emailFrequencyHour, emailFrequencyMinutes] = focusAlert?.timeOfNotification.split(':');

    if (emailFrequencyDay !== undefined) {
      alertTime = alertTime.day(emailFrequencyDay);
    }

    if (emailFrequencyHour && emailFrequencyMinutes) {
      alertTime = alertTime.hour(Number(emailFrequencyHour)).minute(Number(emailFrequencyMinutes));
    }

    alertTimeInLocal = alertTime.local();
  }

  return {
    id: focusData.id,
    name: focusData.name,
    description: focusData.description,
    filters: transformFocusDataToAppliedFilters({
      focusData,
      categories,
      countries,
    }),
    emailNotifications: !!focusAlert?.receiveEmail,
    emailFrequency: focusAlert?.receiveEmail
      ? {
          day: alertTimeInLocal
            ? (alertTimeInLocal.format('dddd') as FocusNotificationDayOfWeek)
            : null,
          frequency: focusAlert.notificationPreference,
          hour: alertTimeInLocal ? alertTimeInLocal.format('HH:mm') : null,
        }
      : undefined,
    users: focusData.users,
    organizations: focusData.organizations,
  };
};

export const focusesSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    openFocusModal: (state, { payload }: PayloadAction<'edit' | 'create' | null>) => {
      state.focusModal = payload;
    },
    selectFocus: (state, { payload }: PayloadAction<number | null>) => {
      const focusId = payload;

      const foundFocus =
        state.focuses.find(focus => focus.id === focusId) ||
        state.sharedFocuses.find(focus => focus.id === focusId);

      state.selectedFocus = foundFocus || null;
    },
    saveDraftFocus: (state, { payload }: PayloadAction<FocusesState['draftFocus']>) => {
      state.draftFocus = payload;
    },
    clearDraftFocus: state => {
      state.draftFocus = null;
    },
  },
  extraReducers: builder =>
    builder
      .addCase(
        createFocus.fulfilled,
        (state, { payload: { focusData, alerts, categories, countries } }) => {
          state.focuses = [
            ...state.focuses,
            mapFocusDataToFocus(focusData, {
              alerts,
              categories,
              countries,
            }),
          ];
        }
      )
      .addCase(
        updateFocus.fulfilled,
        (state, { payload: { focusData, alerts, categories, countries } }) => {
          const isSharedFocus = state.sharedFocuses.some(focus => focus.id === focusData.id);
          const isMyFocus = state.focuses.some(focus => focus.id === focusData.id);

          if (isSharedFocus) {
            state.sharedFocuses = state.sharedFocuses.map(focus => {
              if (focus.id === focusData.id) {
                return mapFocusDataToFocus(focusData, {
                  alerts,
                  categories,
                  countries,
                });
              }

              return focus;
            });
          }

          if (isMyFocus) {
            state.focuses = state.focuses.map(focus => {
              if (focus.id === focusData.id) {
                return mapFocusDataToFocus(focusData, {
                  alerts,
                  categories,
                  countries,
                });
              }

              return focus;
            });
          }
        }
      )
      .addCase(deleteFocus.fulfilled, (state, { payload }) => {
        state.focuses = state.focuses.filter(focus => focus.id !== payload);
        state.sharedFocuses = state.sharedFocuses.filter(focus => focus.id !== payload);
      })
      .addCase(
        getFocusesList.fulfilled,
        (state, { payload: { alerts, categories, countries, focuses } }) => {
          state.focuses = focuses.map(focus =>
            mapFocusDataToFocus(focus, {
              alerts,
              categories,
              countries,
            })
          );
        }
      )
      .addCase(
        getSharedFocusesList.fulfilled,
        (state, { payload: { alerts, categories, countries, focuses } }) => {
          state.sharedFocuses = focuses.map(focus =>
            mapFocusDataToFocus(focus, {
              alerts,
              categories,
              countries,
            })
          );
        }
      )
      .addCase(deleteFocusAlert.fulfilled, (state, { payload }) => {
        const focusItem =
          state.focuses.find(focus => focus.id === payload) ||
          state.sharedFocuses.find(focus => focus.id === payload);

        if (focusItem) {
          focusItem.emailNotifications = false;
          focusItem.emailFrequency = undefined;
        }
      })
      .addMatcher(
        isAnyOf(createFocusAlert.fulfilled, updateFocusAlert.fulfilled),
        (state, { payload }) => {
          const focusItem =
            state.focuses.find(focus => focus.id === payload.focus) ||
            state.sharedFocuses.find(focus => focus.id === payload.focus);

          let localTimeOfNotification: string | null = null;

          if (focusItem) {
            if (payload.timeOfNotification) {
              const [hour, minutes] = payload.timeOfNotification.split(':');

              localTimeOfNotification = dayjs()
                .utc()
                .hour(Number(hour))
                .minute(Number(minutes))
                .local()
                .format('HH:mm');
            }

            focusItem.emailNotifications = true;
            focusItem.emailFrequency = {
              day: payload.dayOfTheWeek,
              frequency: payload.notificationPreference,
              hour: localTimeOfNotification,
            };
          }
        }
      ),
});

export const { selectFocus, openFocusModal, saveDraftFocus, clearDraftFocus } =
  focusesSlice.actions;

export default focusesSlice.reducer;

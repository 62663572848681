import React from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useTheme } from 'styled-components';

import { routePaths } from '@/core/constants/routes';
import { Paragraph } from '@/core/components/Typography';

import { AccountMenu } from '@/features/Auth/components/AccountMenu';

import { MenuLink, MenuList, MenuNav, RightMenu } from './MainMenu.styles';

export const MainMenu = () => {
  const pathname = usePathname();
  const { colors } = useTheme();

  return (
    <MenuNav>
      <MenuList>
        <li>
          <Link
            href={routePaths.home}
            passHref
            legacyBehavior
          >
            <MenuLink isActive={pathname === routePaths.home}>
              <Paragraph
                type={pathname === routePaths.home ? 'bold' : 'normal'}
                color={colors.secondaryText}
              >
                Map
              </Paragraph>
            </MenuLink>
          </Link>
        </li>
        <li>
          <Link
            href={routePaths.multilist}
            passHref
            legacyBehavior
          >
            <MenuLink isActive={pathname === routePaths.multilist}>
              <Paragraph
                type={pathname === routePaths.multilist ? 'bold' : 'normal'}
                color={colors.secondaryText}
              >
                Multi List
              </Paragraph>
            </MenuLink>
          </Link>
        </li>
        <li>
          <Link
            href={routePaths.riskAssessment}
            passHref
            legacyBehavior
          >
            <MenuLink isActive={pathname === routePaths.riskAssessment}>
              <Paragraph
                type={pathname === routePaths.riskAssessment ? 'bold' : 'normal'}
                color={colors.secondaryText}
              >
                Risk Assessment
              </Paragraph>
            </MenuLink>
          </Link>
        </li>
      </MenuList>
      <RightMenu>
        <AccountMenu />
      </RightMenu>
    </MenuNav>
  );
};

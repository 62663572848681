import {
  TextBodyBold,
  TextBodyNormal,
  TextBodySmallBold,
  TextBodySmallNormal,
  TextBodyTiny,
  TextButton,
  TextButtonSmall,
  TextCaptionBold,
  TextCaptionNormal,
  TextH1,
  TextH2,
  TextH3,
  TextSidebarNormalBold,
  TextSidebarSmallBold,
  TextSubheadline,
  TextSubheadlineSemibold,
  TextSubheadlineSmall,
} from './Typography.styles';
import {
  HeadingProps,
  SubheadlineProps,
  ParagraphProps,
  ButtonTextProps,
  SidebarTextProps,
  CaptionProps,
} from './Typography.types';

export const Heading = ({ type, children, color }: HeadingProps) => {
  switch (type) {
    case 'h1':
      return <TextH1 color={color}>{children}</TextH1>;
    case 'h2':
      return <TextH2 color={color}>{children}</TextH2>;
    case 'h3':
      return <TextH3 color={color}>{children}</TextH3>;
  }
};

export const Subheadline = ({ type, children, color }: SubheadlineProps) => {
  switch (type) {
    case 'semi-bold':
      return <TextSubheadlineSemibold color={color}>{children}</TextSubheadlineSemibold>;
    case 'small':
      return <TextSubheadlineSmall color={color}>{children}</TextSubheadlineSmall>;
    case 'normal':
      return <TextSubheadline color={color}>{children}</TextSubheadline>;
  }
};

export const Paragraph = ({ type, children, color }: ParagraphProps) => {
  switch (type) {
    case 'normal':
      return <TextBodyNormal color={color}>{children}</TextBodyNormal>;
    case 'bold':
      return <TextBodyBold color={color}>{children}</TextBodyBold>;
    case 'small-normal':
      return <TextBodySmallNormal color={color}>{children}</TextBodySmallNormal>;
    case 'small-bold':
      return <TextBodySmallBold color={color}>{children}</TextBodySmallBold>;
    case 'tiny':
      return <TextBodyTiny color={color}>{children}</TextBodyTiny>;
  }
};

export const ButtonText = ({ isSmall, children, color }: ButtonTextProps) => {
  if (isSmall) return <TextButtonSmall color={color}>{children}</TextButtonSmall>;

  return <TextButton color={color}>{children}</TextButton>;
};

export const SidebarText = ({ isSmall, children, color }: SidebarTextProps) => {
  if (isSmall) return <TextSidebarSmallBold color={color}>{children}</TextSidebarSmallBold>;

  return <TextSidebarNormalBold color={color}>{children}</TextSidebarNormalBold>;
};

export const Caption = ({ isBold, children, color }: CaptionProps) => {
  if (isBold) return <TextCaptionBold color={color}>{children}</TextCaptionBold>;

  return <TextCaptionNormal color={color}>{children}</TextCaptionNormal>;
};

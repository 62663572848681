import styled from 'styled-components';

import { ThemeVariant } from '@/core/interfaces/common';

export const RowContainer = styled.div`
  display: grid;
  grid-template-columns: 238px 90px auto;
  gap: 11px;
`;

export const DropdownControl = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90px;
  border: 1px solid
    ${({ theme }) =>
      theme.theme === ThemeVariant.DARK ? theme.colors.background.light : theme.colors.border};
  border-radius: 4px;
  padding: 12px 19px;
  background-color: ${({ theme }) => theme.colors.background.light};
  cursor: pointer;
`;

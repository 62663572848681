import {
  CreateFocusAlertPayload,
  CreateFocusPayload,
  CreateLineGeofenceData,
  CreateLineGeofenceResponse,
  CreatePointOfInterestGeofenceData,
  CreatePointOfInterestGeofenceResponse,
  CreatePolygonGeofenceData,
  CreatePolygonGeofenceResponse,
  CreateRadialGeofenceData,
  CreateRadialGeofenceResponse,
  FocusAlertItem,
  FocusAlertResponse,
  FocusResponseItem,
} from '@/features/Focus/interfaces';

import { request } from '@/utils/request';
import { makeServerSideRequest } from '@/utils/serverSideRequest';

export const makeApi = (instance = request) => ({
  createFocus: (focusData: CreateFocusPayload) =>
    instance.post<FocusResponseItem>('focus/create/', focusData),
  updateFocus: (focusId: number, focusData: CreateFocusPayload) =>
    instance.patch<FocusResponseItem>(`focus/${focusId}/edit/`, focusData),
  deleteFocus: (focusId: number) => instance.delete(`focus/${focusId}/delete/`),
  getFocusAlert: (focusId: number) => instance.get<FocusAlertResponse>(`focus/${focusId}/alert/`),
  getFocusesList: () => instance.get<Array<FocusResponseItem>>('focuses/'),
  getSharedFocusesList: () => instance.get<Array<FocusResponseItem>>('shared/focuses/'),
  createFocusAlert: (focusId: number, alertData: CreateFocusAlertPayload) =>
    instance.post<FocusAlertItem>(`focus/${focusId}/alert/create/`, alertData),
  updateFocusAlert: (focusId: number, alertData: CreateFocusAlertPayload) =>
    instance.put<FocusAlertResponse>(`focus/${focusId}/alert/edit/`, alertData),
  deleteFocusAlert: (focusId: number) => instance.delete(`focus/${focusId}/alert/delete/`),
  createRadialGeofence: (geofenceData: CreateRadialGeofenceData) =>
    instance.post<CreateRadialGeofenceResponse>('geofence/create/radial/', geofenceData),
  createPolygonGeofence: (geofenceData: CreatePolygonGeofenceData) =>
    instance.post<CreatePolygonGeofenceResponse>('geofence/create/polygon/', geofenceData),
  createLineGeofence: (geofenceData: CreateLineGeofenceData) =>
    instance.post<CreateLineGeofenceResponse>('geofence/create/line/', geofenceData),
  createPointOfInterestGeofence: (geofenceData: CreatePointOfInterestGeofenceData) =>
    instance.post<CreatePointOfInterestGeofenceResponse>('geofence/create/point/', geofenceData),
  updateRadialGeofence: (geofenceId: string, geofenceData: CreateRadialGeofenceData) =>
    instance.patch<CreateRadialGeofenceResponse>(
      `geofence/${geofenceId}/edit/radial/`,
      geofenceData
    ),
  updatePolygonGeofence: (geofenceId: string, geofenceData: CreatePolygonGeofenceData) =>
    instance.patch<CreatePolygonGeofenceResponse>(
      `geofence/${geofenceId}/edit/polygon/`,
      geofenceData
    ),
  updateLineGeofence: (geofenceId: string, geofenceData: CreateLineGeofenceData) =>
    instance.patch<CreateLineGeofenceResponse>(`geofence/${geofenceId}/edit/line/`, geofenceData),
  updatePointOfInterestGeofence: (
    geofenceId: string,
    geofenceData: CreatePointOfInterestGeofenceData
  ) =>
    instance.patch<CreatePointOfInterestGeofenceResponse>(
      `geofence/${geofenceId}/edit/point/`,
      geofenceData
    ),
  deleteFocusGeofence: (geofenceId: string) => instance.delete(`geofence/${geofenceId}/delete/`),
});

export const api = makeApi();
export const makeServerSideApi = (token?: string) => makeApi(makeServerSideRequest(token));

import { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { SelectComponent } from '@/core/components/Select';
import { TextBodyNormal } from '@/core/components/Typography/Typography.styles';

import { CustomTimeframeView } from '../CustomTimeframeView/CustomTimeframeView';
import { EventDateTimeframeViewProps } from './EventDateTimeframeView.types';
import { Container, CustomTimeframeViewWrapper } from './EventDateTimeframeView.styles';

export const EventDateTimeframeView = ({
  isInlineDatepickerVisible,
  datepickerVisibility,
  dropdownOption,
  startDateProp,
  endDateProp,
  setStartDate,
  setEndDate,
  dropdownTimeframeOptions,
  handleDropdownChange,
  setIsInlineDatepickerVisible,
}: EventDateTimeframeViewProps) => {
  const { colors } = useTheme();

  const selectValue = useMemo(
    () => dropdownTimeframeOptions.find(option => option.value === dropdownOption),
    [dropdownOption, dropdownTimeframeOptions]
  );

  return (
    <Container withMargin={!isInlineDatepickerVisible}>
      {!isInlineDatepickerVisible && (
        <>
          <TextBodyNormal color={colors.secondaryText}>Event date</TextBodyNormal>
          <SelectComponent
            options={dropdownTimeframeOptions}
            instanceId="timeframe-event-date-select"
            placeholder="Custom timeframe"
            value={selectValue}
            onChange={selectedOption => {
              if (!selectedOption) return;

              handleDropdownChange(selectedOption);
            }}
          />
        </>
      )}
      {datepickerVisibility && (
        <CustomTimeframeViewWrapper>
          <CustomTimeframeView
            isInlineDatepickerVisible={isInlineDatepickerVisible}
            startDateProp={startDateProp}
            endDateProp={endDateProp}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setIsInlineDatepickerVisible={setIsInlineDatepickerVisible}
          />
        </CustomTimeframeViewWrapper>
      )}
    </Container>
  );
};

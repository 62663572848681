import { AvatarContainer, AvatarImage } from './Avatar.styles';
import { AvatarProps } from './Avatar.types';

export const Avatar = ({ src, isActive = false, size, onClick }: AvatarProps) => {
  return (
    <AvatarContainer
      onClick={onClick}
      isActive={isActive}
      size={size}
    >
      <AvatarImage
        src={src || '/assets/avatar-placeholder.png'}
        alt="avatar"
      />
    </AvatarContainer>
  );
};

import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import MapboxDraw, { DrawCreateEvent } from '@mapbox/mapbox-gl-draw';
// @ts-expect-error - MapboxDraw static mode types are not available
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode';

import { MapContext } from '@/core/context/MapContext';
import { colorPickerColors } from '@/core/constants/colors';
import { SelectOptionType } from '@/core/components/Select/SelectComponent.types';
import { GEOJSONShapeType } from '@/core/interfaces/geojsons';
import { useAppDispatch } from '@/core/store/store';
import { disableDrawingOnMap, enableDrawingOnMap } from '@/core/store/reducers/config';

import { getFoldersListSelector } from '@/features/Geofeatures/store';

import {
  getPolygonDataName,
  getPolygonFillLayerName,
  getPolygonStrokeLayerName,
  removePolygonGeofeature,
} from '@/utils/map/drawPolygon';

import { PolygonGeofenceForm } from './components/PolygonGeofenceForm';
import { PolygonGeofenceFormData } from './components/PolygonGeofenceForm/PolygonGeofenceForm.types';
import {
  polygonFillPropertyName,
  polygonStrokePropertyName,
  usePolygonDefaultStyles,
} from './CreatePolygonGeofence.utils';
import { CreatePolygonGeofenceProps } from './CreatePolygonGeofence.types';

// @ts-expect-error - MapboxDraw types are not available
MapboxDraw.constants.classes.CONTROL_BASE = 'maplibregl-ctrl';
// @ts-expect-error - MapboxDraw types are not available
MapboxDraw.constants.classes.CONTROL_PREFIX = 'maplibregl-ctrl-';
// @ts-expect-error - MapboxDraw types are not available
MapboxDraw.constants.classes.CONTROL_GROUP = 'maplibregl-ctrl-group';

const modes = MapboxDraw.modes;

// @ts-expect-error - MapboxDraw types are not available
modes.static = StaticMode;

export const CreatePolygonGeofence = ({
  initialData,
  defaultFolder,
  withoutFolder,
  onFormSubmit,
  onFormCancel,
}: CreatePolygonGeofenceProps) => {
  const { mapRef } = useContext(MapContext);
  const polygonColor = useRef(initialData?.properties.color || colorPickerColors.color1);
  const polygonId = useRef<string | undefined>(initialData?.properties.id);
  const geofeaturesFoldersList = useSelector(getFoldersListSelector);
  const dispatch = useAppDispatch();

  const polygonDefaultStyles = usePolygonDefaultStyles();

  const defaultFormFolder = useMemo<SelectOptionType<number> | undefined>(() => {
    let folderOption = defaultFolder;

    if (initialData?.properties.folder) {
      const folder = geofeaturesFoldersList.find(f => f.id === initialData.properties.folder);

      if (folder) {
        folderOption = {
          label: folder.name,
          value: folder.id,
        };
      }
    } else if (!defaultFolder && geofeaturesFoldersList.length) {
      const firstFolder = geofeaturesFoldersList[0];

      folderOption = {
        label: firstFolder.name,
        value: firstFolder.id,
      };
    }

    return folderOption;
  }, [defaultFolder, geofeaturesFoldersList, initialData]);

  const draw = useMemo(
    () =>
      new MapboxDraw({
        displayControlsDefault: false,
        defaultMode: 'draw_polygon',
        boxSelect: false,
        // @ts-expect-error - added custom mode
        modes,
        styles: polygonDefaultStyles,
        userProperties: true,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const changePolygonColor = (propertyId: string, color = polygonColor.current) => {
    draw.setFeatureProperty(propertyId, polygonFillPropertyName, color);
    draw.setFeatureProperty(propertyId, polygonStrokePropertyName, color);
  };

  useEffect(() => {
    const map = mapRef?.getMap();

    if (!map) {
      return;
    }

    dispatch(enableDrawingOnMap('polygon'));

    // @ts-expect-error - MapboxDraw types are not available
    if (!map.hasControl(draw)) {
      // @ts-expect-error - MapboxDraw types are not available
      map.addControl(draw);
    }

    if (!!initialData) {
      draw.add({
        ...initialData,
        id: initialData.properties.id,
      });
      draw.changeMode('direct_select', { featureId: initialData.properties.id });

      changePolygonColor(polygonId.current!, initialData.properties.color);

      removePolygonGeofeature(mapRef, {
        polygonDataName: getPolygonDataName(initialData.properties.id),
        polygonFillLayerName: getPolygonFillLayerName(initialData.properties.id),
        polygonStrokeLayerName: getPolygonStrokeLayerName(initialData.properties.id),
      });
    }

    const onDrawCreate = (event: DrawCreateEvent) => {
      // We have to use `setTimeout` because draw event is fired over and over
      setTimeout(() => {
        if (event?.features && event.features.length === 1) {
          const featureId = event.features[0].id;

          if (!featureId) {
            return;
          }

          polygonId.current = String(featureId);

          changePolygonColor(polygonId.current);
        }

        draw.changeMode('static');
      });
    };

    const onDrawModeChange = () => {
      if (draw.getAll().features.length === 0) {
        draw.changeMode('draw_polygon');
      }
    };

    map.on('draw.create', onDrawCreate);
    map.on('draw.modechange', onDrawModeChange);

    return () => {
      map.off('draw.create', onDrawCreate);
      map.off('draw.modechange', onDrawModeChange);
      // @ts-expect-error - MapboxDraw types are not available
      map.removeControl(draw);

      dispatch(disableDrawingOnMap());

      if (polygonId.current) {
        removePolygonGeofeature(mapRef, {
          polygonDataName: getPolygonDataName(polygonId.current),
          polygonFillLayerName: getPolygonFillLayerName(polygonId.current),
          polygonStrokeLayerName: getPolygonStrokeLayerName(polygonId.current),
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onColorChange = (color: string) => {
    polygonColor.current = color;

    if (!polygonId.current || !draw) {
      return;
    }

    // We have to use `setTimeout` because draw event is fired immediately after color change
    setTimeout(() => {
      changePolygonColor(polygonId.current!, color);

      draw.changeMode('static');

      if (!!initialData) {
        draw.changeMode('direct_select', { featureId: initialData.properties.id });
      }
    }, 1);
  };

  const handleFormSubmit = useCallback(
    async (formData: PolygonGeofenceFormData) => {
      if (!polygonId.current) {
        return;
      }

      const polygonData = draw.get(polygonId.current);

      if (!polygonData) {
        return;
      }

      await onFormSubmit({
        ...polygonData,
        // @ts-expect-error - different types for coordinates but in reality they are the same
        geometry: {
          ...polygonData.geometry,
          // @ts-expect-error different types for coordinates but in reality they are the same
          coordinates: polygonData.geometry.coordinates,
        },
        properties: {
          shape: GEOJSONShapeType.POLYGON,
          id: polygonId.current,
          color: formData.color,
          icon: formData.icon,
          name: formData.name,
          description: formData.description,
          folder: withoutFolder ? undefined : formData.folder.value,
          tags: formData.tags?.map(tag => tag.value) || [],
        },
      });
    },
    [draw, onFormSubmit, withoutFolder]
  );

  return (
    <PolygonGeofenceForm
      withoutFolder={withoutFolder}
      defaultValues={{
        color: polygonColor.current,
        icon: initialData?.properties.icon || 'Flag',
        name: initialData?.properties.name || '',
        description: initialData?.properties.description || '',
        folder: defaultFormFolder,
        tags:
          initialData?.properties.tags.map(tag => ({
            label: tag,
            value: tag,
          })) || [],
      }}
      onColorChange={onColorChange}
      onSubmit={handleFormSubmit}
      onCancel={onFormCancel}
    />
  );
};

import ReactDatePicker from 'react-datepicker';
import { useEffect, useState } from 'react';

import { TextBodyNormal } from '@/core/components/Typography/Typography.styles';
import { DEFAULT_DATEPICKER_DATE_FORMAT } from '@/core/constants/time';
import { CustomTimeframeViewProps } from '@/core/components/MapFilters/TimeframeFilter/components/CustomTimeframeView/CustomTimeframeView.types';
import { DatepickerCustomInput } from '@/core/components/DatePicker/components/DatepickerCustomInput';
import { DatepickerCustomHeader } from '@/core/components/DatePicker/components/DatepickerCustomHeader';
import { Button, ButtonVariant } from '@/core/components/Button';
import { DatePickerContainer } from '@/core/components/DatePicker/DatePicker.styles';

import { formatToDefaultDate } from '@/utils/helpers';

import { ButtonContainer, DatepickerWrapper } from './CustomTimeframeView.styles';

export const CustomTimeframeView = ({
  isInlineDatepickerVisible,
  startDateProp,
  endDateProp,
  setStartDate,
  setEndDate,
  setIsInlineDatepickerVisible,
}: CustomTimeframeViewProps) => {
  const [localStartDate, setLocalStartDate] = useState<Date | null>(startDateProp);
  const [localEndDate, setLocalEndDate] = useState<Date | null>(endDateProp);
  const [isMonthPickerOpen, setIsMonthPickerOpen] = useState(false);
  const [isYearPickerOpen, setIsYearPickerOpen] = useState(false);

  const onDatesSelect = (dates: Date | [Date, Date]) => {
    if (Array.isArray(dates)) {
      const [startDate, endDate] = dates;

      setLocalStartDate(startDate);
      setLocalEndDate(endDate);
    } else if (isMonthPickerOpen) {
      setIsMonthPickerOpen(false);
    } else if (isYearPickerOpen) {
      setIsYearPickerOpen(false);
      setIsMonthPickerOpen(true);
    }
  };

  const onDatesConfirm = () => {
    setStartDate(localStartDate);
    setEndDate(localEndDate);
    setIsInlineDatepickerVisible(false);
  };

  const showInlineDatePickers = () => {
    setIsInlineDatepickerVisible(true);
  };

  useEffect(() => {
    if (!isInlineDatepickerVisible) {
      setIsMonthPickerOpen(false);
      setIsYearPickerOpen(false);
    }
  }, [isInlineDatepickerVisible]);

  return (
    <>
      {isInlineDatepickerVisible ? (
        <DatePickerContainer>
          <ReactDatePicker
            inline
            selectsRange={!isMonthPickerOpen && !isYearPickerOpen}
            calendarStartDay={1}
            showMonthYearPicker={isMonthPickerOpen}
            showYearPicker={isYearPickerOpen}
            dateFormat={DEFAULT_DATEPICKER_DATE_FORMAT}
            selected={localStartDate}
            onChange={onDatesSelect}
            startDate={localStartDate}
            endDate={localEndDate}
            disabledKeyboardNavigation
            renderCustomHeader={headerProps => (
              <DatepickerCustomHeader
                {...headerProps}
                isMonthPickerOpen={isMonthPickerOpen}
                isYearPickerOpen={isYearPickerOpen}
                setIsMonthPickerOpen={setIsMonthPickerOpen}
                setIsYearPickerOpen={setIsYearPickerOpen}
              />
            )}
          >
            <ButtonContainer>
              <Button
                variant={ButtonVariant.PRIMARY}
                onClick={onDatesConfirm}
              >
                APPLY
              </Button>
            </ButtonContainer>
          </ReactDatePicker>
        </DatePickerContainer>
      ) : (
        <DatepickerWrapper>
          <DatepickerCustomInput
            onClick={showInlineDatePickers}
            dateValue={formatToDefaultDate(startDateProp)}
          />
          <TextBodyNormal>―</TextBodyNormal>
          <DatepickerCustomInput
            onClick={showInlineDatePickers}
            dateValue={formatToDefaultDate(endDateProp)}
          />
        </DatepickerWrapper>
      )}
    </>
  );
};

import { useCallback, useState } from 'react';

import { GeoJSONImportArgument, GeoJSONImportForm } from '@/core/components/GeoJSONImportForm';
import { GEOJSONCollectionData, GEOJSONData } from '@/core/interfaces/geojsons';

import { prepareGeoJSONForImport } from '@/utils/helpers';
import {
  isGeoJSONPointsOfInterestValid,
  transformPointsOfInterestFromTextToGeoJSON,
} from '@/utils/geoJSONImport';

import { PointOfInterestImportProps } from './PointOfInterestImport.types';

export const PointOfInterestImport = ({ onSubmit }: PointOfInterestImportProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const additionalValidator = useCallback(({ importValue, isJson }: GeoJSONImportArgument) => {
    if (!isJson) {
      return true;
    }

    try {
      const importData = JSON.parse(importValue);

      return isGeoJSONPointsOfInterestValid(importData);
    } catch {
      return false;
    }
  }, []);

  const handleSubmit = useCallback(
    async ({ importValue, isJson }: GeoJSONImportArgument) => {
      setIsLoading(true);

      let importDataValue: GEOJSONData | GEOJSONCollectionData | string = importValue;

      if (!isJson) {
        importDataValue = await transformPointsOfInterestFromTextToGeoJSON(importValue);
      }

      const importData = prepareGeoJSONForImport(importDataValue);

      onSubmit(importData);

      setIsLoading(false);
    },
    [onSubmit]
  );

  return (
    <GeoJSONImportForm
      placeholder="Place your data here ..."
      isLoading={isLoading}
      additionalValidatorFn={additionalValidator}
      onSubmit={handleSubmit}
    />
  );
};

import { DefaultTheme } from 'styled-components';

import { ButtonVariant } from '@/core/components/Button';

import { darkTheme, lightTheme } from './main';

export const lightThemeG4S: DefaultTheme = {
  ...lightTheme,
  colors: {
    ...lightTheme.colors,
    active: '#ef373e',
    focuses: {
      ...lightTheme.colors.focuses,
      active: '#ef373e',
    },
    button: {
      ...lightTheme.colors.button,
      [ButtonVariant.PRIMARY]: {
        ...lightTheme.colors.button[ButtonVariant.PRIMARY],
        backgroundDefault: '#cdbbac',
        backgroundHover: '#ef373e',
        backgroundActive: '#ef373e',
      },
    },
    multiButton: {
      ...lightTheme.colors.multiButton,
      activeButtonBackground: '#cdbbac',
    },
  },
};

export const darkThemeG4S: DefaultTheme = {
  ...darkTheme,
  colors: {
    ...darkTheme.colors,
    active: '#ef373e',
    focuses: {
      ...darkTheme.colors.focuses,
      active: '#ef373e',
    },
    button: {
      ...darkTheme.colors.button,
      [ButtonVariant.PRIMARY]: {
        ...darkTheme.colors.button[ButtonVariant.PRIMARY],
        backgroundDefault: '#cdbbac',
        backgroundHover: '#ef373e',
        backgroundActive: '#ef373e',
      },
    },
    multiButton: {
      ...darkTheme.colors.multiButton,
      activeButtonBackground: '#cdbbac',
    },
  },
};

import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { useCallback, useContext, useMemo, useState } from 'react';

import { Icon } from '@/core/components/Icons';
import { MapContext } from '@/core/context/MapContext';
import { POINT_OF_INTEREST_ZOOM } from '@/core/constants/map';

import {
  getFoldersListSelector,
  getPointsOfInterestListSelector,
} from '@/features/Geofeatures/store';

import { PointOfInterestsListProps } from './PointOfInterestsList.types';
import { ImportButton, NewGeofenceButtonContainer } from './PointOfInterestsList.styles';
import {
  GeofeatureListSuggestionItem,
  GeofeatureListSuggestionItemType,
  GeofeaturesList,
} from '../GeofeaturesList';
import { LocationPoiFilterItem } from '../../LocationFilter.types';

export const PointOfInterestsList = ({
  pointOfInterestsList,
  onPointOfInterestImportClick,
  onPointOfInterestRemoveClick,
  onPointOfInterestSearchSelect,
}: PointOfInterestsListProps) => {
  const { mapRef } = useContext(MapContext);
  const { colors } = useTheme();

  const allPointsOfInterestsGeofencesList = useSelector(getPointsOfInterestListSelector);
  const allFoldersList = useSelector(getFoldersListSelector);

  const [searchValue, setSearchValue] = useState('');

  const filteredPointOfInteresList = useMemo(() => {
    if (!searchValue) {
      return [];
    }

    const searchPhrase = searchValue.toLowerCase();

    const folders = allFoldersList
      .filter(folder => {
        return folder.name.toLowerCase().includes(searchPhrase);
      })
      .map<GeofeatureListSuggestionItem>(folder => ({
        type: GeofeatureListSuggestionItemType.FOLDER,
        item: folder,
        name: folder.name,
      }));

    const pointsOfInterest = allPointsOfInterestsGeofencesList
      .filter(geofence => {
        return (
          (geofence.name.toLowerCase().includes(searchPhrase) ||
            geofence.description?.toLowerCase().includes(searchPhrase) ||
            geofence.tags.some(tag => tag.toLowerCase().includes(searchPhrase))) &&
          !pointOfInterestsList.some(
            ({ transformedLocationValue }) =>
              transformedLocationValue.value.properties.id === geofence.id
          )
        );
      })
      .map<GeofeatureListSuggestionItem>((geofence, index) => ({
        type: GeofeatureListSuggestionItemType.GEOFEATURE,
        item: geofence,
        name: geofence.name,
        withSeparator: index === 0 && folders.length > 0,
      }));

    return [...folders, ...pointsOfInterest];
  }, [allFoldersList, allPointsOfInterestsGeofencesList, pointOfInterestsList, searchValue]);

  const onPointOfInterestItemClick = useCallback(
    (geofence: LocationPoiFilterItem) => {
      const map = mapRef?.getMap();

      if (!map) {
        return;
      }

      map.flyTo({
        center: geofence.transformedLocationValue.value.geometry.coordinates as [number, number],
        zoom: POINT_OF_INTEREST_ZOOM,
      });
    },
    [mapRef]
  );

  return (
    <>
      <GeofeaturesList
        geofeaturesList={pointOfInterestsList}
        onGeofeatureRemoveClick={onPointOfInterestRemoveClick}
        searchPlaceholder="Search in POIs"
        searchSuggestions={filteredPointOfInteresList}
        onSearch={setSearchValue}
        onGeofeatureSearchSelect={onPointOfInterestSearchSelect}
        onGeofenceItemClick={onPointOfInterestItemClick}
      />
      <NewGeofenceButtonContainer>
        <ImportButton
          type="button"
          onClick={onPointOfInterestImportClick}
        >
          <Icon
            name="UpToLine"
            color={colors.secondaryText}
          />
          IMPORT POI
        </ImportButton>
      </NewGeofenceButtonContainer>
    </>
  );
};

import { MouseEvent, useCallback } from 'react';

import { Icon } from '@/core/components/Icons';

import {
  CheckboxWrapper,
  CheckboxInput,
  CheckboxLabel,
  CheckboxFakeInput,
} from './Checkbox.styles';
import { CheckboxProps } from './Checkbox.types';

export const Checkbox = ({
  value,
  isChecked,
  label,
  onChange,
  isReducedWidth = false,
}: CheckboxProps) => {
  const handleLabelClick = useCallback((event: MouseEvent<HTMLLabelElement>) => {
    event.stopPropagation();
  }, []);

  return (
    <CheckboxWrapper
      onClick={handleLabelClick}
      isReducedWidth={isReducedWidth}
      htmlFor={String(value)}
    >
      <CheckboxInput
        type="checkbox"
        id={String(value)}
        checked={isChecked}
        onChange={onChange}
      />
      <CheckboxFakeInput>
        <Icon
          size={12}
          name="Check"
        />
      </CheckboxFakeInput>
      {label && <CheckboxLabel htmlFor={String(value)}>{label}</CheckboxLabel>}
    </CheckboxWrapper>
  );
};

import { useTheme } from 'styled-components';

import { Icon } from '@/core/components/Icons/Icon';

import { AddButtonProps } from './AddButton.types';
import { StyledButton } from './AddButton.styles';

export const AddButton = ({ onClick, text }: AddButtonProps) => {
  const { colors } = useTheme();

  return (
    <StyledButton
      onClick={onClick}
      type="button"
    >
      <Icon
        name="Plus"
        color={colors.secondaryText}
      />
      {text}
    </StyledButton>
  );
};

import NextLink from 'next/link';
import styled from 'styled-components';

import { getVariant } from '@/themes/typographyUtilis';

export const AccountMenuContainer = styled.div`
  position: relative;
`;

export const MenuContainer = styled.div`
  position: absolute;
  top: 78px;
  right: 22px;
  z-index: 4;
  width: 340px;
  box-shadow: 0 4px 41px 0 #00000040;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.modalBackground};
`;

export const AccountMenuHeader = styled.header`
  display: flex;
  align-items: flex-start;
  gap: 18px;
  padding: 20px;
`;

export const UserName = styled.p`
  color: ${({ theme }) => theme.colors.primaryText};
  ${getVariant({ variant: 'body1-bold' })}
`;

export const UserEmail = styled.p`
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.secondaryText};
  ${getVariant({ variant: 'body1-normal' })}
`;

export const AccountMenuList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const AccountMenuItem = styled.li`
  padding: 20px;
`;

export const AccountMenuLink = styled(NextLink)`
  color: ${({ theme }) => theme.colors.primaryText};
  ${getVariant({ variant: 'body1-normal' })}
  text-decoration: none;
`;

export const AccountLogoutContainer = styled.footer`
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  padding: 20px;
`;

export const AccountLogoutButton = styled.button`
  display: inline-block;
  margin: 0;
  outline: none;
  border: none;
  padding: 0;
  background: none;
  color: ${({ theme }) => theme.colors.active};
  ${getVariant({ variant: 'body1-normal' })}
  cursor: pointer;
`;

import {
  RadioButtonInput,
  RadioButtonLabel,
  RadioButtonWrapper,
} from '@/core/components/RadioButton/RadioButton.styles';
import { MergeElementProps } from '@/core/interfaces/common';

type CustomRadioButtonProps = {
  label: string;
  value: string;
  name: string;
  onChange: () => void;
};

type RadioButtonProps = MergeElementProps<'input', CustomRadioButtonProps>;

export const RadioButton = ({ label, name, value, onChange, checked }: RadioButtonProps) => {
  return (
    <RadioButtonWrapper>
      <RadioButtonInput
        onChange={onChange}
        type="radio"
        id={name}
        name={name}
        value={value}
        checked={checked}
      />
      <RadioButtonLabel
        htmlFor={name}
        isChecked={checked}
      >
        {label}
      </RadioButtonLabel>
    </RadioButtonWrapper>
  );
};

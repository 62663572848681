import { SwitchLabel, SwitchInput, SwitchSlider } from './Switch.styles';
import { SwitchProps } from './Switch.types';

export const Switch = ({ isChecked, onChange }: SwitchProps) => {
  return (
    <SwitchLabel>
      <SwitchInput
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
      />
      <SwitchSlider />
    </SwitchLabel>
  );
};

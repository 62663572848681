import styled from 'styled-components';

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

export const PickersRow = styled.div`
  display: flex;
  gap: 12px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 70px;
`;

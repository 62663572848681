import { AppliedFilterDataItem } from '@/core/interfaces/filters';
import { CountryLocationData } from '@/core/interfaces/locations';
import {
  LineGEOJSONData,
  PointOfInterestGEOJSONData,
  PolygonGEOJSONData,
  RadialGEOJSONData,
} from '@/core/interfaces/geojsons';

export enum LocationFilterGeofenceSource {
  DIRECT_DRAW,
  GEOFENCES_LIST,
}

export enum LocationFilterViewType {
  COUNTRY,
  GEOFENCE,
  POI,
}

export enum LocationSubViewType {
  GEOFENCE_ADD,
  GEOFENCE_CREATE_RADIAL,
  GEOFENCE_CREATE_POLYGON,
  GEOFENCE_CREATE_LINE,
  POI_IMPORT,
  RADIUS_SELECT,
}

export enum LocationFilterItemType {
  COUNTRY,
  GEOFENCE_RADIAL,
  GEOFENCE_POLYGON,
  GEOFENCE_LINE,
  POI,
}

export type LocationCountryFilterItemValue = {
  locationItemType: LocationFilterItemType.COUNTRY;
  value: CountryLocationData;
};

type LocationGeofenceFilterBaseItemValue = {
  value: RadialGEOJSONData | PolygonGEOJSONData | LineGEOJSONData;
};

export type LocationGeofenceFilterRadialItemValue = LocationGeofenceFilterBaseItemValue & {
  locationItemType: LocationFilterItemType.GEOFENCE_RADIAL;
  value: RadialGEOJSONData;
};

export type LocationGeofenceFilterPolygonItemValue = LocationGeofenceFilterBaseItemValue & {
  locationItemType: LocationFilterItemType.GEOFENCE_POLYGON;
  value: PolygonGEOJSONData;
};

export type LocationGeofenceFilterLineItemValue = LocationGeofenceFilterBaseItemValue & {
  locationItemType: LocationFilterItemType.GEOFENCE_LINE;
  value: LineGEOJSONData;
};

export type LocationGeofenceFilterItemValue =
  | LocationGeofenceFilterRadialItemValue
  | LocationGeofenceFilterPolygonItemValue
  | LocationGeofenceFilterLineItemValue;

export type LocationPoiFilterItemValue = {
  locationItemType: LocationFilterItemType.POI;
  value: PointOfInterestGEOJSONData;
};

export type LocationFilterItemValue = {
  locationItemType: LocationFilterItemType;
} & (LocationCountryFilterItemValue | LocationGeofenceFilterItemValue | LocationPoiFilterItemValue);

export type LocationCountryFilterItem = AppliedFilterDataItem & {
  locationType: LocationFilterViewType.COUNTRY;
  transformedLocationValue: LocationCountryFilterItemValue;
};

export type LocationGeofenceFilterItem = AppliedFilterDataItem & {
  locationType: LocationFilterViewType.GEOFENCE;
  transformedLocationValue: LocationGeofenceFilterItemValue;
  source: LocationFilterGeofenceSource;
};

export type LocationPoiFilterItem = AppliedFilterDataItem & {
  locationType: LocationFilterViewType.POI;
  transformedLocationValue: LocationPoiFilterItemValue;
};

export type LocationFilterItem =
  | LocationCountryFilterItem
  | LocationGeofenceFilterItem
  | LocationPoiFilterItem;

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/core/interfaces/store';
import { ReportSourceType } from '@/core/interfaces/common';

import { ReportsState } from '@/features/Reports/interfaces';

const reportsSelector = (state: RootState): ReportsState => state.reports;

export const getActiveReportDataSelector = createSelector(
  [reportsSelector],
  state => state.activeReportData
);

export const isReportDetailsOpenSelector = createSelector(
  [reportsSelector],
  state => state.activeReportData !== null
);

export const getHorizonReportsListSelector = createSelector(
  [reportsSelector],
  state => state.horizonReportsList
);

export const getLookoutReportsListSelector = createSelector(
  [reportsSelector],
  state => state.lookoutReportsList
);

export const getReportsListSelector = createSelector([reportsSelector], state =>
  [...state.horizonReportsList, ...state.lookoutReportsList].sort((a, b) => {
    const dateAField =
      a.reportSourceType === ReportSourceType.LOOKOUT ? a.modifiedDatetime : a.publishedDatetime;
    const dateBField =
      b.reportSourceType === ReportSourceType.LOOKOUT ? b.modifiedDatetime : b.publishedDatetime;

    const dateA = new Date(dateAField).getTime();
    const dateB = new Date(dateBField).getTime();

    return dateB - dateA;
  })
);

export const getMulticolumnsListSelector = createSelector(
  reportsSelector,
  state => state.multicolumnsList
);

export const getHorizonReportsListForMulticolumnSelector = createSelector(
  [reportsSelector, (_state, focusId: number) => focusId],
  (state, focusId: number) =>
    state.multicolumnsList.find(column => column.id === focusId)?.horizonReports || []
);

export const getLookoutReportsListForMulticolumnSelector = createSelector(
  [reportsSelector, (_state, focusId: number) => focusId],
  (state, focusId: number) =>
    state.multicolumnsList.find(column => column.id === focusId)?.lookoutReports || []
);

export const getMulticolumnReportsSelector = createSelector(
  [reportsSelector, (_state, focusId: number) => focusId],
  (state, focusId: number) => {
    const horizonReports =
      state.multicolumnsList.find(column => column.id === focusId)?.horizonReports || [];
    const lookoutReports =
      state.multicolumnsList.find(column => column.id === focusId)?.lookoutReports || [];

    return [...horizonReports, ...lookoutReports].sort((a, b) => {
      const dateAField =
        a.reportSourceType === ReportSourceType.LOOKOUT ? a.modifiedDatetime : a.publishedDatetime;
      const dateBField =
        b.reportSourceType === ReportSourceType.LOOKOUT ? b.modifiedDatetime : b.publishedDatetime;

      const dateA = new Date(dateAField).getTime();
      const dateB = new Date(dateBField).getTime();

      return dateB - dateA;
    });
  }
);

import styled from 'styled-components';

import { getVariant } from '@/themes/typographyUtilis';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const BulkActionsButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 6px;
  padding: 10px 13px;
  background: none;
  color: ${({ theme }) => theme.colors.primaryText};
  ${getVariant({ variant: 'body1-normal' })};
  cursor: pointer;
`;

export const DeselectButton = styled.button`
  margin: 0;
  border: none;
  padding: 0;
  background: none;
  color: ${({ theme }) => theme.colors.primaryText};
  ${getVariant({ variant: 'body2-normal' })};
  cursor: pointer;
`;

export const DeleteModalContent = styled.p`
  ${getVariant({ variant: 'body2-normal' })}
  color: ${({ theme }) => theme.colors.primaryText};
`;

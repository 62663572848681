import { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

import { TextSidebarNormalBold } from '@/core/components/Typography/Typography.styles';

export const StyledFocusItem = styled(TextSidebarNormalBold).attrs({ as: 'button' })<{
  type: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  isSelected: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 38px;
  border: 2px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.colors.focuses.active : theme.colors.focuses.background};
  border-radius: 4px;
  padding: 9px 18px;
  background: ${({ theme }) => theme.colors.focuses.background};
  color: ${({ theme }) => theme.colors.secondaryText};
  text-align: left;
  text-transform: uppercase;
  transition: all 0.25s ease;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    outline: none;
    color: ${({ theme }) => theme.colors.primaryText};
  }

  &:hover > button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:focus-visible {
    border-color: ${({ theme }) => theme.colors.teal};
  }
`;

export const StyledIconButton = styled.button`
  display: none;
  margin: 0;
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
`;

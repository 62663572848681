import { useMemo } from 'react';
import NextImage from 'next/image';
import NextLink from 'next/link';
import { useTheme } from 'styled-components';

import { ThemeVariant } from '@/core/interfaces/common';

import { isG4S } from '@/utils/helpers';

import { LogoProps } from './Logo.types';

export const Logo = ({ width, height }: LogoProps) => {
  const { theme } = useTheme();

  const darkLogo = useMemo(() => {
    if (isG4S) {
      return {
        path: '/assets/g4s-logo-inverted.png',
        width: width || 58,
        height: height || 42,
      };
    }

    return {
      path: '/assets/logo-dark.svg',
      width: width || 140,
      height: height || 42,
    };
  }, [height, width]);

  const lightLogo = useMemo(() => {
    if (isG4S) {
      return {
        path: '/assets/g4s-logo.png',
        width: width || 55,
        height: height || 42,
      };
    }

    return {
      path: '/assets/logo-light.svg',
      width: width || 140,
      height: height || 42,
    };
  }, [height, width]);

  const logo = useMemo(() => {
    if (theme === ThemeVariant.DARK) {
      return darkLogo;
    }

    return lightLogo;
  }, [darkLogo, lightLogo, theme]);

  return (
    <NextLink href="/">
      <NextImage
        src={logo.path}
        alt="Logo"
        width={logo.width}
        height={logo.height}
      />
    </NextLink>
  );
};

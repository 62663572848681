import styled from 'styled-components';

export const TOOLBAR_HEIGHT = '58px';

export const TOOLBAR_ANIMATION_TIME = 0.3;

export const ToolbarWrapper = styled.div<{ widthToShrink: string }>`
  overflow-x: auto;
  display: flex;
  align-items: center;
  height: ${TOOLBAR_HEIGHT};
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.08);
  margin-left: ${({ widthToShrink }) => widthToShrink};
  padding: 6px;
  background-color: ${({ theme }) => theme.colors.background.medium};
  transition: margin ${({ theme }) => (theme.useAnimations ? TOOLBAR_ANIMATION_TIME : 0)}s
    ease-in-out;
`;

export const InputWrapper = styled.div`
  max-width: 402px;
`;

export const ToolbarMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 26px;
  padding: 0 26px;
`;

export const DropdownControl = styled.span`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;

export const MapButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

import styled from 'styled-components';

export const AvatarContainer = styled.div<{ isActive: boolean; size?: number }>`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => (size ? `${size + 4}px` : '37px')};
  height: ${({ size }) => (size ? `${size + 4}px` : '37px')};
  outline: ${({ isActive, theme }) =>
    isActive ? `2px solid ${theme.colors.active}` : '2px solid transparent'};
  border: 2px solid transparent;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
`;

export const AvatarImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

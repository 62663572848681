import { useTheme } from 'styled-components';

import { ButtonVariant, ButtonProps, ButtonSize } from '@/core/components/Button/Button.types';
import { Icon } from '@/core/components/Icons/Icon';
import { IconNames } from '@/core/components/Icons/Icon.types';

import { StyledIconButton } from './IconButton.styles';

export const IconButton = ({
  type = 'button',
  onClick,
  variant = ButtonVariant.PRIMARY,
  buttonSize = ButtonSize.MEDIUM,
  name,
  ...props
}: ButtonProps & { name: IconNames }) => {
  const { colors } = useTheme();

  return (
    <StyledIconButton
      type={type}
      onClick={onClick}
      variant={variant}
      buttonSize={buttonSize}
      {...props}
    >
      <Icon
        name={name}
        size={12}
        color={colors.white}
      />
    </StyledIconButton>
  );
};

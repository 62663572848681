import styled from 'styled-components';

import { StyledIcon } from '@/core/components/Icons/Icon.styles';

export const GeofeatureContainer = styled.div`
  overflow: auto;
  scrollbar-width: thin;
  width: 532px;
  max-width: 532px;
  height: 100%;
  padding: 20px 24px;
  background-color: ${({ theme }) => theme.colors.modalBackground};
`;

export const GeofeatureHeader = styled.header`
  padding-bottom: 27px;
`;

export const TabsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding-bottom: 10px;
`;

export const GeofeatureCloseIcon = styled(StyledIcon).attrs({ name: 'Close' })`
  width: 19px;
  height: 19px;
  cursor: pointer;
  fill: ${({ theme }) => theme.colors.primaryText};

  path {
    fill: ${({ theme }) => theme.colors.primaryText};
  }
`;

export const HeaderTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.primaryText};
`;

export const GeofeatureContent = styled.div`
  display: grid;
`;

export const InputWithActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 24px;
  vertical-align: middle;
`;

export const SearchClearButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
`;

export const SearchContainer = styled.div`
  flex-grow: 1;
`;

export const GeofeatureIconWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

export const GeofeatureGearIcon = styled(StyledIcon).attrs({
  name: 'Gear',
  size: 20,
})`
  margin-left: 10px;
`;

export const GeofeatureEyeIcon = styled(StyledIcon).attrs({
  size: 20,
})`
  margin-left: 10px;
`;

export const BulkActionsContainer = styled.div`
  margin-top: 15px;
`;

export const GeofeatureListWrapper = styled.div`
  margin-top: 12px;
`;

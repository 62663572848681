import styled, { css } from 'styled-components';

import { getVariant } from '@/themes/typographyUtilis';

export const MultiButtonGroup = styled.div<{ isSmall: boolean }>`
  display: flex;
  gap: ${({ isSmall }) => (isSmall ? '8px' : '10px')};
  width: max-content;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  padding: 3px;
`;

export const MultiButtonItem = styled.button<{ isActive: boolean; isSmall: boolean }>`
  display: flex;
  gap: 6px;
  outline: 0;
  border: none;
  border-radius: 4px;
  padding: ${({ isSmall }) => (isSmall ? '5px 12px' : '9px 20px')};
  background-color: transparent;
  color: ${({ theme }) => theme.colors.multiButton.buttonText};
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  ${({ isSmall }) =>
    isSmall ? getVariant({ variant: 'body2-normal' }) : getVariant({ variant: 'body1-normal' })};
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.multiButton.activeButtonBackground};
      color: ${({ theme }) => theme.colors.multiButton.activeButtonText};
    `}
`;

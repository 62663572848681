import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Multicolumn, ReportSourceType } from '@/core/interfaces/common';
import { applyAllFilters, applyFilter, resetFilters } from '@/core/store/reducers/filtersSlice';

import { Report, ReportResponseItem, ReportsState } from '@/features/Reports/interfaces';

const initialState: ReportsState = {
  horizonReportsList: [],
  lookoutReportsList: [],
  activeReportData: null,
  multicolumnsList: [],
};

export const reportDetailsSlice = createSlice({
  name: 'reportDetails',
  initialState,
  reducers: {
    openDetailsModal: (state, { payload }: PayloadAction<Report>) => {
      state.activeReportData = payload;
    },
    closeDetailsModal: state => {
      state.activeReportData = null;
    },
    appendHorizonReports: (state, { payload }: PayloadAction<Array<ReportResponseItem>>) => {
      const reportsList = payload.map(item => ({
        ...item,
        reportSourceType: ReportSourceType.HORIZON,
        wasOpened: false,
      }));

      state.horizonReportsList.push(...reportsList);
    },
    appendLookoutReports: (state, { payload }: PayloadAction<Array<ReportResponseItem>>) => {
      const reportsList = payload.map(item => ({
        ...item,
        reportSourceType: ReportSourceType.LOOKOUT,
        wasOpened: false,
      }));

      state.lookoutReportsList.push(...reportsList);
    },
    markReportAsOpened: (
      state,
      {
        payload: { reportId, reportSourceType, columnId },
      }: PayloadAction<{
        reportId: number;
        reportSourceType: ReportSourceType;
        columnId?: number;
      }>
    ) => {
      let reportsList: Array<Report> = [];

      if (columnId) {
        const column = state.multicolumnsList.find(item => item.id === columnId);

        if (column) {
          if (reportSourceType === ReportSourceType.HORIZON) {
            reportsList = column.horizonReports;
          }

          if (reportSourceType === ReportSourceType.LOOKOUT) {
            reportsList = column.lookoutReports;
          }
        }
      } else {
        if (reportSourceType === ReportSourceType.HORIZON) {
          reportsList = state.horizonReportsList;
        }

        if (reportSourceType === ReportSourceType.LOOKOUT) {
          reportsList = state.lookoutReportsList;
        }
      }

      const report = reportsList.find(item => item.reportId === reportId);

      if (report) {
        report.wasOpened = true;
      }
    },
    addMulticolumn: (state, { payload }: PayloadAction<Multicolumn>) => {
      if (state.multicolumnsList.some(column => column.id === payload.id)) {
        return;
      }

      state.multicolumnsList.push(payload);
    },
    removeMulticolumn: (state, { payload }: PayloadAction<Multicolumn['id']>) => {
      state.multicolumnsList = state.multicolumnsList.filter(column => column.id !== payload);
    },
    setMulticolumnsOrder: (state, { payload }: PayloadAction<Array<Multicolumn>>) => {
      state.multicolumnsList = payload;
    },
    appendMulticolumnHorizonReport: (
      state,
      {
        payload,
      }: PayloadAction<{
        focusId: number;
        reports: Array<Report>;
      }>
    ) => {
      state.multicolumnsList = state.multicolumnsList.map(column => {
        if (column.id === payload.focusId) {
          column.horizonReports.push(...payload.reports);
        }

        return column;
      });
    },
    appendMulticolumnLookoutReport: (
      state,
      {
        payload,
      }: PayloadAction<{
        focusId: number;
        reports: Array<Report>;
      }>
    ) => {
      state.multicolumnsList = state.multicolumnsList.map(column => {
        if (column.id === payload.focusId) {
          column.lookoutReports.push(...payload.reports);
        }

        return column;
      });
    },
  },
  extraReducers: builder =>
    builder
      .addCase(applyFilter, state => {
        state.horizonReportsList = [];
        state.lookoutReportsList = [];
      })
      .addCase(applyAllFilters, state => {
        state.horizonReportsList = [];
        state.lookoutReportsList = [];
      })
      .addCase(resetFilters, state => {
        state.horizonReportsList = [];
        state.lookoutReportsList = [];
      }),
});

export const {
  appendHorizonReports,
  appendLookoutReports,
  openDetailsModal,
  closeDetailsModal,
  markReportAsOpened,
  addMulticolumn,
  removeMulticolumn,
  setMulticolumnsOrder,
  appendMulticolumnHorizonReport,
  appendMulticolumnLookoutReport,
} = reportDetailsSlice.actions;

export default reportDetailsSlice.reducer;

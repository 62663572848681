import { createSlice } from '@reduxjs/toolkit';

import { RiskAssesmentsState } from '@/features/RiskAssessment/interfaces';
import {
  getRiskAssessmentDetails,
  getRiskAssessments,
} from '@/features/RiskAssessment/store/actions';

const initialState: RiskAssesmentsState = {
  riskAssessments: [],
  riskAssessmentsDetails: [],
};

export const riskAssessmentSlice = createSlice({
  name: 'riskAssessment',
  reducers: {},
  initialState,
  extraReducers: builder =>
    builder
      .addCase(getRiskAssessments.fulfilled, (state, { payload }) => {
        state.riskAssessments = payload;
      })
      .addCase(getRiskAssessmentDetails.fulfilled, (state, { payload }) => {
        const index = state.riskAssessmentsDetails.findIndex(item => item.iso2 === payload.iso2);

        if (index !== -1) {
          state.riskAssessmentsDetails[index] = payload;
        } else {
          state.riskAssessmentsDetails.push(payload);
        }
      }),
});

export default riskAssessmentSlice.reducer;

import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Units } from '@turf/turf';
import { MapEventType } from 'maplibre-gl';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { MapContext } from '@/core/context/MapContext';
import { RadialCenter } from '@/core/interfaces/common';
import { colorPickerColors } from '@/core/constants/colors';
import { useMapStyleName } from '@/core/hooks/useMapStyleName';
import { SelectOptionType } from '@/core/components/Select/SelectComponent.types';
import { GEOJSONShapeType } from '@/core/interfaces/geojsons';
import { useAppDispatch } from '@/core/store/store';
import { disableDrawingOnMap, enableDrawingOnMap } from '@/core/store/reducers/config';

import { getFoldersListSelector } from '@/features/Geofeatures/store';

import {
  drawRadialGeofeature,
  getCircleDataName,
  getCircleFillLayerName,
  getCircleStrokeLayerName,
  removeRadialGeofeature,
} from '@/utils/map/drawRadial';

import { CreateRadialGeofenceProps, RadialGeofenceFormData } from './CreateRadialGeofence.types';
import { RadialGeofenceForm } from './components/RadialGeofenceForm';

export const CreateRadialGeofence = ({
  initialData,
  defaultFolder,
  withoutFolder,
  onFormSubmit,
  onFormCancel,
}: CreateRadialGeofenceProps) => {
  const { mapRef } = useContext(MapContext);
  const [dataId] = useState(initialData?.properties.id || uuidv4());
  const circleRadius = useRef(initialData?.properties.radius || 5);
  const circleUnits = useRef<Units>(initialData?.properties.radiusUnit || 'kilometers');
  const circleCenter = useRef<RadialCenter | null>(
    initialData?.properties.center
      ? [initialData.properties.center.lng, initialData.properties.center.lat]
      : null
  );
  const circleColor = useRef(initialData?.properties.color || colorPickerColors.color1);
  const shouldDraw = useRef(true);
  const geofeaturesFoldersList = useSelector(getFoldersListSelector);
  const dispatch = useAppDispatch();

  const defaultFormFolder = useMemo<SelectOptionType<string | number> | undefined>(() => {
    let folderOption = defaultFolder;

    if (initialData?.properties.folder) {
      const folder = geofeaturesFoldersList.find(f => f.id === initialData.properties.folder);

      if (folder) {
        folderOption = {
          label: folder.name,
          value: folder.id,
        };
      }
    } else if (!defaultFolder && geofeaturesFoldersList.length) {
      const firstFolder = geofeaturesFoldersList[0];

      folderOption = {
        label: firstFolder.name,
        value: firstFolder.id,
      };
    }

    return folderOption;
  }, [defaultFolder, geofeaturesFoldersList, initialData]);

  const schema = useMemo(() => {
    const baseSchema = {
      center: yup.object().shape({
        lat: yup.number(),
        lng: yup.number(),
      }),
      radiusValue: yup
        .number()
        .transform((value, originalVal) => (originalVal === '' ? 0 : value))
        .required(),
      radiusUnit: yup.string().required(),
      name: yup.string().required(),
      folder: yup.object().required(),
      description: yup.string(),
      tags: yup.array().of(
        yup.object().shape({
          label: yup.string().required(),
          value: yup.string().required(),
        })
      ),
      color: yup.string().required(),
      icon: yup.string().required(),
    };

    if (withoutFolder) {
      // @ts-expect-error folder can be removed
      delete baseSchema.folder;
    }

    return yup.object().shape(baseSchema);
  }, [withoutFolder]);

  const formMethods = useForm<RadialGeofenceFormData>({
    defaultValues: {
      radiusValue: circleRadius.current,
      radiusUnit: circleUnits.current,
      color: circleColor.current,
      icon: initialData?.properties.icon || 'Flag',
      name: initialData?.properties.name || '',
      description: initialData?.properties.description || '',
      center: circleCenter.current
        ? {
            name:
              initialData?.properties.address ||
              `${circleCenter.current[1]}, ${circleCenter.current[0]}`,
            coordinates: {
              lat: circleCenter.current?.[1] ?? 0,
              lng: circleCenter.current?.[0] ?? 0,
            },
          }
        : undefined,
      folder: defaultFormFolder,
      tags:
        initialData?.properties.tags.map(tag => ({
          label: tag,
          value: tag,
        })) || [],
      dataId,
    },
    resolver: yupResolver(schema),
  });

  const { setValue, watch, formState } = formMethods;

  const radiusValueWatch = watch('radiusValue');
  const radiusUnitWatch = watch('radiusUnit');
  const radiusCenterWatch = watch('center');
  const circleColorWatch = watch('color');

  const isFormSubmitting = formState.isSubmitting;

  const circleDataName = useMemo(() => getCircleDataName(dataId), [dataId]);
  const circleFillLayerName = useMemo(() => getCircleFillLayerName(dataId), [dataId]);
  const circleStrokeLayerName = useMemo(() => getCircleStrokeLayerName(dataId), [dataId]);

  const mapStyleName = useMapStyleName();

  const drawCircle = ({
    center = circleCenter.current,
    radius = circleRadius.current,
    units = circleUnits.current,
    color = circleColor.current,
  }: {
    center?: RadialCenter | null;
    radius?: number;
    units?: Units;
    color?: string;
  }) => {
    if (shouldDraw.current) {
      drawRadialGeofeature(mapRef, {
        center,
        radius,
        units,
        color,
        circleDataName,
        circleFillLayerName,
        circleStrokeLayerName,
        lineType: initialData ? 'solid' : 'dash',
        shouldZoom: !initialData,
      });
    }
  };

  const onCenterChange = (coordinates: RadialCenter) => {
    circleCenter.current = coordinates;

    if (!circleCenter.current || !mapRef) {
      return;
    }

    drawCircle({ center: circleCenter.current });
  };

  const onRadiusChange = (radius: { value: number; unit: Units }) => {
    circleRadius.current = radius.value;
    circleUnits.current = radius.unit;

    if (!circleCenter.current || !mapRef) {
      return;
    }

    drawCircle({
      radius: radius.value,
      units: radius.unit,
    });
  };

  const onColorChange = (color: string) => {
    circleColor.current = color;

    if (!circleCenter.current || !mapRef) {
      return;
    }

    drawCircle({ color: circleColor.current });
  };

  const drawCircleOnMouseUp = (e: MapEventType['mouseup']) => {
    if (!!initialData) {
      return;
    }

    const center = [e.lngLat.lng, e.lngLat.lat] as [number, number];

    circleCenter.current = center;

    setValue('center', {
      name: `${center[1]}, ${center[0]}`,
      coordinates: {
        lat: circleCenter.current[1],
        lng: circleCenter.current[0],
      },
    });

    drawCircle({ center });
  };

  useEffect(() => {
    drawCircle({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapStyleName]);

  const onMapMoveStart = () => {
    shouldDraw.current = false;
  };
  const onMapMoveEnd = () => {
    shouldDraw.current = true;
  };

  useEffect(() => {
    onRadiusChange({
      value: radiusValueWatch,
      unit: radiusUnitWatch,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radiusUnitWatch, radiusValueWatch]);

  useEffect(() => {
    if (radiusCenterWatch?.coordinates) {
      onCenterChange([radiusCenterWatch.coordinates.lng, radiusCenterWatch.coordinates.lat]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radiusCenterWatch]);

  useEffect(() => {
    onColorChange(circleColorWatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circleColorWatch]);

  useEffect(() => {
    const map = mapRef?.getMap();

    dispatch(enableDrawingOnMap('radial'));

    map?.on('movestart', onMapMoveStart);
    map?.on('mouseup', drawCircleOnMouseUp);
    map?.on('moveend', onMapMoveEnd);

    return () => {
      dispatch(disableDrawingOnMap());

      map?.off('movestart', onMapMoveStart);
      map?.off('mouseup', drawCircleOnMouseUp);
      map?.off('moveend', onMapMoveEnd);

      removeRadialGeofeature(mapRef, {
        circleDataName,
        circleFillLayerName,
        circleStrokeLayerName,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = useCallback(
    async (formData: RadialGeofenceFormData) => {
      const map = mapRef?.getMap();

      const circleSource = map?.getSource(circleDataName)?.serialize();

      if (!circleSource) {
        return;
      }

      await onFormSubmit({
        ...circleSource.data,
        properties: {
          ...circleSource.data.properties,
          id: dataId,
          shape: GEOJSONShapeType.CIRCLE,
          center: {
            lat: circleCenter.current?.[1] ?? 0,
            lng: circleCenter.current?.[0] ?? 0,
          },
          radius: formData.radiusValue,
          radiusUnit: formData.radiusUnit,
          color: formData.color,
          icon: formData.icon,
          name: formData.name,
          description: formData.description,
          folder: withoutFolder ? undefined : formData.folder?.value,
          tags: formData.tags?.map(tag => tag.value) || [],
          address: formData.center.name,
        },
      });
    },
    [circleDataName, dataId, mapRef, onFormSubmit, withoutFolder]
  );

  return (
    <FormProvider {...formMethods}>
      <RadialGeofenceForm
        isSubmitting={isFormSubmitting}
        withoutFolder={withoutFolder}
        onSubmit={handleFormSubmit}
        onCancel={onFormCancel}
      />
    </FormProvider>
  );
};

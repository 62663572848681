import { Icon } from '@/core/components/Icons';
import { useAppDispatch } from '@/core/store/store';
import { openFilter } from '@/core/store/reducers/filtersSlice';

import { openFocusModal } from '@/features/Focus/store';

import { StyledFocusItem, StyledIconButton } from './FocusItem.styles';
import { FocusItemProps } from './FocusItem.types';

export const FocusItem = ({
  name,
  isSelected,
  shouldHideEditButton = false,
  onClick,
}: FocusItemProps) => {
  const dispatch = useAppDispatch();

  const handleEditFocusClick = () => {
    dispatch(openFilter(null));
    dispatch(openFocusModal('edit'));
  };

  return (
    <StyledFocusItem
      as="button"
      type="button"
      isSelected={isSelected}
      onClick={onClick}
    >
      {name}
      {!shouldHideEditButton && (
        <StyledIconButton onClick={handleEditFocusClick}>
          <Icon name="Gear" />
        </StyledIconButton>
      )}
    </StyledFocusItem>
  );
};

import styled from 'styled-components';

export const DetailsContainer = styled.div`
  overflow: auto;
  scrollbar-width: thin;
  width: 100%;
  max-width: 888px;
  height: 100%;
  border-left: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.background.light};
`;

export const DetailsHeader = styled.header`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding: 24px 48px 48px;
`;

export const HeaderTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  color: ${({ theme }) => theme.colors.primaryText};
`;

export const CloseIcon = styled.span`
  cursor: pointer;
`;

export const ReportTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 48px;
`;

export const Country = styled.span`
  color: ${({ theme }) => theme.colors.active};
`;

export const ReportContent = styled.div`
  display: grid;
  grid-template-columns: 0.7fr 0.3fr;
  color: ${({ theme }) => theme.colors.secondaryText};
`;

export const ReportContentColumn = styled.div`
  border-right: 1px solid ${({ theme }) => theme.colors.border};
  padding: 48px;
`;

export const ReportMap = styled.div`
  width: 100%;
  height: 236px;
  margin-top: 24px;
  background-color: ${({ theme }) => theme.colors.primaryText};
`;

export const ReportSourceTitle = styled.p`
  margin: 0 0 20px;
`;

export const TitleSource = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

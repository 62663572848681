import { StyledIcon } from './Icon.styles';
import { IconProps } from './Icon.types';

/*continue working on icons (paused on plus/minus icons and default size value for icon*/
export const Icon = ({ name, ...props }: IconProps) => {
  return (
    <StyledIcon
      name={name}
      {...props}
    />
  );
};

import styled from 'styled-components';

import { TextBodyNormal, TextCaptionNormal } from '@/core/components/Typography/Typography.styles';

export const GeofeatureItem = styled.div<{ isVisibleOnMap: boolean }>`
  opacity: ${({ isVisibleOnMap }) => (isVisibleOnMap ? 1 : 0.5)};
  box-shadow: inset 5px 0 0 ${({ theme }) => theme.colors.active};
  outline: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 3px;
  padding: 7px 18px 8px 15px;
  cursor: pointer;

  &:hover {
    outline: 2px solid ${({ theme }) => theme.colors.active};
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;

export const GeofeatureNameWithTagsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const GeofeatureItemName = styled(TextBodyNormal)`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const GeofeatureItemIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
  margin-left: auto;
`;

export const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border: none;
  padding: 0;
  background: none;
  cursor: pointer;
`;

export const GeofeatureOptionalDescription = styled(TextCaptionNormal)`
  margin-top: 6px;
`;

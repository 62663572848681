import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import isEqual from 'lodash/isEqual';

import { useAppDispatch } from '@/core/store/store';
import {
  Main,
  PageAsideMenu,
  PageHeader,
  PageHeaderLogo,
  PageContent,
  ButtonsContainer,
  StyledButton,
} from '@/core/components/Layout/Layout.styles';
import { MainMenu } from '@/core/components/Layout/MainMenu';
import { UserRole } from '@/core/interfaces/common';
import { MenuSection } from '@/core/components/MenuSection';
import { FocusesList } from '@/core/components/FocusesList';
import { ButtonVariant } from '@/core/components/Button';
import { TimeframeFilter } from '@/core/components/MapFilters/TimeframeFilter';
import { timeframeRadioButtonData } from '@/core/components/MapFilters/TimeframeFilter/timeframeRadioButtonData';
import { CategoryFilter } from '@/core/components/MapFilters/CategoryFilter';
import { LocationFilter } from '@/core/components/MapFilters/LocationFilter';
import { KeywordsFilter } from '@/core/components/MapFilters/KeywordFilter';
import { MoreFilters } from '@/core/components/MapFilters/MoreFilters';
import { getAllAppliedFiltersSelector } from '@/core/store/reducers/filtersSlice';
import { ResetFiltersButton } from '@/core/components/ResetFiltersButton';
import { DEFAULT_FILTERS_DATA } from '@/core/constants/filters';
import { Logo } from '@/core/components/Logo';

import {
  iFocusModalOpenSelector,
  getMyFocusesSelector,
  getSharedFocusesSelector,
  openFocusModal,
  getSelectedFocusSelector,
  isOwnFocusSelector,
} from '@/features/Focus/store';
import { ReportDetails } from '@/features/Reports/ReportDetails';
import { FocusModal } from '@/features/Focus/FocusModal';
import { GeofeatureModal } from '@/features/Geofeatures/components/GeofeatureModal/GeofeatureModal';
import { closeGeofeatureModal, isGeofeatureModalOpenSelector } from '@/features/Geofeatures/store';
import { closeDetailsModal, isReportDetailsOpenSelector } from '@/features/Reports/store';
import { getUserSelector } from '@/features/Auth/store';

import { hasPermission } from '@/utils/helpers';

interface LayoutProps {
  children: ReactNode;
  shouldHideMainSidebar?: boolean;
}

export const Layout = ({ children, shouldHideMainSidebar = false }: LayoutProps) => {
  const userData = useSelector(getUserSelector);
  const isDetailsOpen = useSelector(isReportDetailsOpenSelector);
  const focuses = useSelector(getMyFocusesSelector);
  const sharedFocuses = useSelector(getSharedFocusesSelector);
  const appliedFilters = useSelector(getAllAppliedFiltersSelector);
  const isGeofeatureOpen = useSelector(isGeofeatureModalOpenSelector);
  const isFocusModalOpen = useSelector(iFocusModalOpenSelector);
  const currentFocus = useSelector(getSelectedFocusSelector);
  const isOwnFocus = useSelector(state => isOwnFocusSelector(state, currentFocus?.id));
  const selectedFocusId = currentFocus ? currentFocus.id : null;

  const { events } = useRouter();

  const dispatch = useAppDispatch();

  const areFiltersApplied = Object.values(appliedFilters).some(array => array.length > 0);

  const canCreateFocus = useMemo(
    () =>
      hasPermission(userData?.groups || [], [
        UserRole.MANAGEMENT_ADMIN,
        UserRole.TEAM_LEADER,
        UserRole.ANALYST,
      ]),
    [userData?.groups]
  );
  const canEditFocus = useMemo(
    () => hasPermission(userData?.groups || [], [UserRole.MANAGEMENT_ADMIN, UserRole.TEAM_LEADER]),
    [userData?.groups]
  );

  const handleCreateFocus = useCallback(() => {
    dispatch(openFocusModal('create'));
  }, [dispatch]);

  const handleUpdateFocus = useCallback(() => {
    if (!currentFocus) return;

    dispatch(openFocusModal('edit'));
  }, [dispatch, currentFocus]);

  const isFocusDifferent = useMemo(
    () => !isEqual(appliedFilters, currentFocus?.filters || DEFAULT_FILTERS_DATA),
    [appliedFilters, currentFocus?.filters]
  );

  useEffect(() => {
    const onRouteChange = () => {
      dispatch(closeDetailsModal());
      dispatch(openFocusModal(null));
      dispatch(closeGeofeatureModal());
    };

    events.on('routeChangeStart', onRouteChange);

    return () => {
      events.off('routeChangeStart', onRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onRouteChange = () => {
      dispatch(closeDetailsModal());
      dispatch(openFocusModal(null));
      dispatch(closeGeofeatureModal());
    };

    events.on('routeChangeStart', onRouteChange);

    return () => {
      events.off('routeChangeStart', onRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageHeader>
        <PageHeaderLogo>
          <Logo />
        </PageHeaderLogo>
        <MainMenu />
      </PageHeader>
      <Main isFullWidth={shouldHideMainSidebar}>
        {!shouldHideMainSidebar && (
          <PageAsideMenu>
            <ResetFiltersButton />
            <MenuSection
              isInitialyExpanded
              sectionName="My focuses"
            >
              <FocusesList
                focuses={focuses}
                selectedFocusId={selectedFocusId}
              />
            </MenuSection>
            <MenuSection sectionName="Shared focuses">
              <FocusesList
                focuses={sharedFocuses}
                selectedFocusId={selectedFocusId}
              />
            </MenuSection>
            <MenuSection sectionName="Filters">
              <LocationFilter />
              <CategoryFilter />
              <TimeframeFilter timeframeFiltersData={timeframeRadioButtonData} />
              <KeywordsFilter />
              <MoreFilters />
            </MenuSection>
            {areFiltersApplied && (
              <ButtonsContainer>
                {isFocusDifferent && (
                  <>
                    {currentFocus && (canEditFocus || isOwnFocus) && (
                      <StyledButton onClick={handleUpdateFocus}>UPDATE FOCUS</StyledButton>
                    )}

                    {canCreateFocus && (
                      <StyledButton
                        variant={currentFocus ? ButtonVariant.SECONDARY : ButtonVariant.PRIMARY}
                        onClick={handleCreateFocus}
                      >
                        SAVE FOCUS
                      </StyledButton>
                    )}
                  </>
                )}
              </ButtonsContainer>
            )}
          </PageAsideMenu>
        )}
        <PageContent>
          {children}
          <ReportDetails isOpen={isDetailsOpen} />
          {isFocusModalOpen && (
            <FocusModal
              key={selectedFocusId}
              isOpen={isFocusModalOpen}
            />
          )}
          <GeofeatureModal isOpen={isGeofeatureOpen} />
        </PageContent>
      </Main>
    </>
  );
};

import styled from 'styled-components';

import { HEADER_HEIGHT, ASIDE_WIDTH } from '@/core/components/Layout/Layout.styles';

export const EditFocusContainer = styled.div`
  overflow-y: auto;
  width: calc(100vw - ${ASIDE_WIDTH});
  height: 100%;
  max-height: calc(100vh - ${HEADER_HEIGHT});
  scrollbar-width: thin;
  padding: 52px 76px;
  background-color: ${({ theme }) => theme.colors.background.dark};
`;

export const EditFocusHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
`;

export const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 600px;
  margin-bottom: 40px;
`;

export const FilterSection = styled(StyledSection)`
  max-width: 100%;
`;

export const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 12px;
  width: fit-content;
  cursor: pointer;
`;

export const StyledInfo = styled.div`
  display: flex;
  gap: 12px;
`;

export const SuggestionContent = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

export const DailyContainer = styled.div`
  width: 283px;
`;

export const WeeklyContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`;

export const FrequencyField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const StyledDeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  outline: none;
  border: none;
  border-radius: 6px;
  padding: 7px 13px;
  background-color: transparent;
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => `${theme.colors.warning}40`};
  }
`;

import { InputHintText, InputLabel } from '@/core/components/FormElements.styles';

import {
  InputContainer,
  PrefixIconContainer,
  StyledInput,
  SuffixIconContainer,
} from './Input.styles';
import { InputProps } from './Input.types';
import { FieldErrorMessage } from '../TextField/TextField.styles';

export const Input = ({
  prefixIcon,
  suffixIcon,
  label,
  centered = false,
  withError = false,
  hintText,
  errorMessage,
  ...props
}: InputProps) => {
  return (
    <div>
      {label && <InputLabel>{label}</InputLabel>}
      <InputContainer>
        {prefixIcon && <PrefixIconContainer>{prefixIcon}</PrefixIconContainer>}
        <StyledInput
          {...props}
          withPrefixIcon={!!prefixIcon}
          withSuffixIcon={!!suffixIcon}
          withError={withError}
          centered={centered}
        />
        {suffixIcon && <SuffixIconContainer>{suffixIcon}</SuffixIconContainer>}
      </InputContainer>
      {hintText && !errorMessage && <InputHintText>{hintText}</InputHintText>}
      {errorMessage && <FieldErrorMessage>{errorMessage}</FieldErrorMessage>}
    </div>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  GEOJSONCollectionData,
  GEOJSONData,
  GEOJSONShapeType,
  LineGEOJSONData,
  PointOfInterestGEOJSONData,
  PolygonGEOJSONData,
  RadialGEOJSONData,
} from '@/core/interfaces/geojsons';

import { api } from '@/features/Geofeatures/api';
import {
  CreateLineGeofeatureData,
  CreatePointOfInterestGeofeatureData,
  CreatePolygonGeofeatureData,
  CreateRadialGeofeatureData,
  GeofeatureCreateFolderData,
  GeofeatureCreateFolderErrorReponse,
  GeofeatureCreateFolderResponse,
  GeofeatureEditFolderData,
  UpdateRadialGeofeatureData,
  UpdateGeofeaturesData,
  UpdatePolygonGeofeatureData,
  UpdateLineGeofeatureData,
  UpdatePointOfInterestGeofeatureData,
} from '@/features/Geofeatures/interfaces';

import { getActionPrefix } from '@/utils/helpers';

const actionPrefix = getActionPrefix('geofeatures');

export const getGeofeaturesFolders = createAsyncThunk(
  `${actionPrefix}/getGeofeaturesFolders`,
  async () => {
    const { data } = await api.getFolders();

    return data;
  }
);

export const getSharedGeofeaturesFolders = createAsyncThunk(
  `${actionPrefix}/getSharedGeofeaturesFolders`,
  async () => {
    const { data } = await api.getSharedFolders();

    return data;
  }
);

export const createGeofeaturesFolder = createAsyncThunk<
  GeofeatureCreateFolderResponse,
  GeofeatureCreateFolderData,
  { rejectValue: GeofeatureCreateFolderErrorReponse }
>(`${actionPrefix}/createGeofeaturesFolder`, async (folderData, { rejectWithValue }) => {
  try {
    const { data } = await api.createFolder(folderData);

    return data;
  } catch (error) {
    // @ts-expect-error error is not defined
    if (error.response.data) {
      // @ts-expect-error error is not defined
      return rejectWithValue({ formErrors: error.response.data });
    }

    throw error;
  }
});

export const editGeofeaturesFolder = createAsyncThunk<
  GeofeatureCreateFolderResponse,
  {
    folderId: number;
    folderData: GeofeatureEditFolderData;
  },
  { rejectValue: GeofeatureCreateFolderErrorReponse }
>(
  `${actionPrefix}/editGeofeaturesFolder`,
  async ({ folderId, folderData }, { rejectWithValue }) => {
    try {
      const { data } = await api.editFolder(folderId, folderData);

      return data;
    } catch (error) {
      // @ts-expect-error error is not defined
      if (error.response.data) {
        // @ts-expect-error error is not defined
        return rejectWithValue({ formErrors: error.response.data });
      }

      throw error;
    }
  }
);

export const deleteGeofeaturesFolder = createAsyncThunk(
  `${actionPrefix}/deleteGeofeaturesFolder`,
  async (folderId: number) => {
    await api.deleteFolder(folderId);

    return folderId;
  }
);

export const createRadialGeofeature = createAsyncThunk(
  `${actionPrefix}/createRadialGeofeature`,
  async (geofeatureData: CreateRadialGeofeatureData) => {
    const { data } = await api.createRadialGeofeature(geofeatureData);

    return data;
  }
);

export const createPolygonGeofeature = createAsyncThunk(
  `${actionPrefix}/createPolygonGeofeature`,
  async (geofeatureData: CreatePolygonGeofeatureData) => {
    const { data } = await api.createPolygonGeofeature(geofeatureData);

    return data;
  }
);

export const createLineGeofeature = createAsyncThunk(
  `${actionPrefix}/createLineGeofeature`,
  async (geofeatureData: CreateLineGeofeatureData) => {
    const { data } = await api.createLineGeofeature(geofeatureData);

    return data;
  }
);

export const createPointOfInterestGeofeature = createAsyncThunk(
  `${actionPrefix}/createPointOfInterestGeofeature`,
  async (geofeatureData: CreatePointOfInterestGeofeatureData) => {
    const { data } = await api.createPointOfInterestGeofeature(geofeatureData);

    return data;
  }
);

export const importGeofeatures = createAsyncThunk(
  `${actionPrefix}/importGeofeatures`,
  async (geofeatures: GEOJSONData | GEOJSONCollectionData, { dispatch }) => {
    let geofeaturesData: Array<GEOJSONData> = [];

    if (geofeatures.type === 'FeatureCollection') {
      geofeaturesData = geofeatures.features;
    } else if (geofeatures.type === 'Feature') {
      geofeaturesData = [geofeatures];
    }

    const geofeaturesRequests = geofeaturesData.map(geofeature => {
      if (geofeature.properties.shape === GEOJSONShapeType.POI) {
        return dispatch(
          createPointOfInterestGeofeature({
            ...(geofeature as PointOfInterestGEOJSONData),
            properties: {
              ...geofeature.properties,
              folder: geofeature.properties.folder!,
            },
          })
        );
      }

      if (geofeature.properties.shape === GEOJSONShapeType.LINE) {
        return dispatch(
          createLineGeofeature({
            ...(geofeature as LineGEOJSONData),
            properties: {
              ...geofeature.properties,
              folder: geofeature.properties.folder!,
            },
          })
        );
      }

      if (geofeature.properties.shape === GEOJSONShapeType.POLYGON) {
        return dispatch(
          createPolygonGeofeature({
            ...(geofeature as PolygonGEOJSONData),
            properties: {
              ...geofeature.properties,
              folder: geofeature.properties.folder!,
            },
          })
        );
      }

      if (geofeature.properties.shape === GEOJSONShapeType.CIRCLE) {
        return dispatch(
          createRadialGeofeature({
            ...(geofeature as RadialGEOJSONData),
            properties: {
              ...geofeature.properties,
              folder: geofeature.properties.folder!,
              radiusConverted: 0,
              center: {
                lng: geofeature.properties.center.lng,
                lat: geofeature.properties.center.lat,
              },
            },
          })
        );
      }

      return null;
    });

    const createdGeofeatures = await Promise.all(geofeaturesRequests);

    return createdGeofeatures;
  }
);

export const deleteGeofeature = createAsyncThunk(
  `${actionPrefix}/deleteGeofeature`,
  async (geofeatureId: string) => {
    await api.deleteGeofeature(geofeatureId);

    return geofeatureId;
  }
);

export const deleteGeofeatures = createAsyncThunk(
  `${actionPrefix}/deleteGeofeatures`,
  async (geofeatureIds: Array<string>) => {
    await Promise.all(geofeatureIds.map(id => api.deleteGeofeature(id)));

    return geofeatureIds;
  }
);

export const updateRadialGeofeatureData = createAsyncThunk<
  {
    geofeatureId: string;
    data: UpdateRadialGeofeatureData;
  },
  {
    geofeatureId: string;
    data: UpdateRadialGeofeatureData;
  }
>(`${actionPrefix}/updateRadialGeofeatureData`, async ({ geofeatureId, data }) => {
  await api.updateRadialGeofeatureData(geofeatureId, data);

  return {
    geofeatureId,
    data,
  };
});

export const updatePolygonGeofeatureData = createAsyncThunk<
  {
    geofeatureId: string;
    data: UpdatePolygonGeofeatureData;
  },
  {
    geofeatureId: string;
    data: UpdatePolygonGeofeatureData;
  }
>(`${actionPrefix}/updatePolygonGeofeatureData`, async ({ geofeatureId, data }) => {
  await api.updatePolygonGeofeatureData(geofeatureId, data);

  return {
    geofeatureId,
    data,
  };
});

export const updateLineGeofeatureData = createAsyncThunk<
  {
    geofeatureId: string;
    data: UpdateLineGeofeatureData;
  },
  {
    geofeatureId: string;
    data: UpdateLineGeofeatureData;
  }
>(`${actionPrefix}/updateLineGeofeatureData`, async ({ geofeatureId, data }) => {
  await api.updateLineGeofeatureData(geofeatureId, data);

  return {
    geofeatureId,
    data,
  };
});

export const updatePointOfInterestGeofeatureData = createAsyncThunk<
  {
    geofeatureId: string;
    data: UpdatePointOfInterestGeofeatureData;
  },
  {
    geofeatureId: string;
    data: UpdatePointOfInterestGeofeatureData;
  }
>(`${actionPrefix}/updatePointOfInterestGeofeatureData`, async ({ geofeatureId, data }) => {
  await api.updatePointOfInterestGeofeatureData(geofeatureId, data);

  return {
    geofeatureId,
    data,
  };
});

export const updateGeofeaturesData = createAsyncThunk(
  `${actionPrefix}/updateGeofeaturesData`,
  async (geofeatures: UpdateGeofeaturesData) => {
    await Promise.all(
      geofeatures
        .map(({ id, ...geofeature }) => {
          switch (geofeature.properties.shape) {
            case GEOJSONShapeType.POI:
              return api.updatePointOfInterestGeofeatureData(
                id,
                geofeature as UpdatePointOfInterestGeofeatureData
              );
            case GEOJSONShapeType.LINE:
              return api.updateLineGeofeatureData(id, geofeature as UpdateLineGeofeatureData);
            case GEOJSONShapeType.POLYGON:
              return api.updatePolygonGeofeatureData(id, geofeature as UpdatePolygonGeofeatureData);
            case GEOJSONShapeType.CIRCLE:
              return api.updateRadialGeofeatureData(id, geofeature as UpdateRadialGeofeatureData);
          }
        })
        .filter(Boolean)
    );

    return geofeatures;
  }
);

import { SuggestionItem } from '@/core/components/SearchSuggestionsList/SearchSuggestionsList.types';

import { GeofeatureFolderItem, GeofeatureItem } from '@/features/Geofeatures/interfaces';

import { LocationFilterItem } from '../../LocationFilter.types';

export enum GeofeatureListSuggestionItemType {
  GEOFEATURE,
  FOLDER,
}

export type GeofeatureListSuggestionItem = SuggestionItem & {
  type: GeofeatureListSuggestionItemType;
  item: GeofeatureItem | GeofeatureFolderItem;
} & (
    | {
        type: GeofeatureListSuggestionItemType.GEOFEATURE;
        item: GeofeatureItem;
      }
    | {
        type: GeofeatureListSuggestionItemType.FOLDER;
        item: GeofeatureFolderItem;
      }
  );

export type GeofeaturesListProps<FilterItem extends LocationFilterItem> = {
  geofeaturesList: Array<FilterItem>;
  searchPlaceholder: string;
  searchSuggestions: Array<GeofeatureListSuggestionItem>;
  onSearch: (searchText: string) => void;
  onGeofeatureRemoveClick: (geofeature: FilterItem['transformedLocationValue']) => void;
  onGeofeatureSearchSelect: (geofeature: GeofeatureListSuggestionItem) => void;
  onGeofenceItemClick?: (geofence: FilterItem) => void;
};

import { ReactElement } from 'react';

export enum BadgeSize {
  SMALL,
  MEDIUM,
}

export type BadgeProps = {
  text: string;
  backgroundColor: string;
  color?: string;
  leadingIcon?: ReactElement;
  size?: BadgeSize;
  isNormalcase?: boolean;
  useFontSizeFactor?: boolean;
};

import { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ReportTypeCategory, ReportSourceType, ApiAccess } from '@/core/interfaces/common';
import { FilterContainer } from '@/core/components/FilterContainer';
import { Paragraph } from '@/core/components/Typography';
import {
  applyFilter,
  getAppliedFilterSelector,
  isFilterOpenedSelector,
} from '@/core/store/reducers/filtersSlice';
import { AppliedFilterData, AppliedFilterDataItem, FilterType } from '@/core/interfaces/filters';
import { useAppDispatch } from '@/core/store/store';
import { MultiButton, MultiButtonItem } from '@/core/components/MultiButton';
import { impactFilterOptions, impactFilterOptionsG4S } from '@/core/constants/filters';

import { getUserSelector } from '@/features/Auth/store';

import { isG4S } from '@/utils/helpers';

import { FilterGroup } from './MoreFilters.styles';

export const MoreFilters = () => {
  const user = useSelector(getUserSelector);
  const appliedFilters = useSelector(state =>
    getAppliedFilterSelector(state, FilterType.MORE_FILTERS)
  );
  const dispatch = useAppDispatch();

  const [selectedFilters, setSelectedFilters] = useState<AppliedFilterData>(appliedFilters);
  const isFilterVisible = useSelector(state =>
    isFilterOpenedSelector(state, FilterType.MORE_FILTERS)
  );

  const reportSourceOptions = useMemo(() => {
    const sourceOptions: Array<MultiButtonItem<string>> = [];

    if (user?.organization.currentSubscription.apiAccess.includes(ApiAccess.HORIZON)) {
      sourceOptions.push({
        label: 'Verified',
        value: ReportSourceType.HORIZON,
        iconName: 'Person' as const,
      });
    }

    if (user?.organization.currentSubscription.apiAccess.includes(ApiAccess.LOOKOUT)) {
      sourceOptions.push({
        label: 'Hozint AI',
        value: ReportSourceType.LOOKOUT,
        iconName: 'MicrochipAi' as const,
      });
    }

    return sourceOptions;
  }, [user?.organization.currentSubscription.apiAccess]);

  const setAppliedFilters = useCallback(
    (filtersToApply: AppliedFilterData) => {
      dispatch(
        applyFilter({
          filterType: FilterType.MORE_FILTERS,
          filterData: filtersToApply,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isFilterVisible) {
      if (!appliedFilters.length) {
        setSelectedFilters([]);
      } else {
        setSelectedFilters(appliedFilters);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterVisible]);

  const handleApplyFilters = useCallback(() => {
    setAppliedFilters(selectedFilters);
  }, [selectedFilters, setAppliedFilters]);

  useEffect(() => {
    setSelectedFilters(appliedFilters);
  }, [appliedFilters]);

  const reportImpactOptions = useMemo(() => {
    let impactOptions = impactFilterOptions;

    if (isG4S) {
      impactOptions = impactFilterOptionsG4S;
    }

    return impactOptions;
  }, []);

  const handleSelectFilter = useCallback(
    (filters: Array<string>) => {
      setSelectedFilters(prevFilters => {
        const filtersToRemove = prevFilters.filter(
          filter => !filters.includes(filter.value as string)
        );

        const filtersToAdd = filters
          .filter(filter => !prevFilters.some(prevFilter => prevFilter.value === filter))
          .map(filter => {
            if (filter === ReportSourceType.HORIZON || filter === ReportSourceType.LOOKOUT) {
              return {
                value: filter,
                label:
                  (reportSourceOptions.find(option => option.value === filter)?.label as string) ||
                  '',
              };
            }

            if (reportImpactOptions.some(option => option.value === filter)) {
              return reportImpactOptions.find(
                option => option.value === filter
              ) as AppliedFilterDataItem;
            }

            return {
              value: filter,
              label: filter,
            };
          });

        return [
          ...prevFilters.filter(filter => !filtersToRemove.includes(filter)),
          ...filtersToAdd,
        ];
      });
    },
    [reportImpactOptions, reportSourceOptions]
  );

  const handleRemoveFilterFromList = useCallback(
    (filter: AppliedFilterDataItem) => {
      const filterdSelectedFiltersList = selectedFilters.filter(
        selectedFilter => selectedFilter.value !== filter.value
      );
      const filterdAppliedFiltersList = appliedFilters.filter(
        appliedFilter => appliedFilter.value !== filter.value
      );

      setSelectedFilters(filterdSelectedFiltersList);
      setAppliedFilters(filterdAppliedFiltersList);
    },
    [appliedFilters, selectedFilters, setAppliedFilters]
  );

  const reportTypeCategoryOptions = useMemo(() => {
    return Object.values(ReportTypeCategory).map(value => ({
      label: value,
      value,
    }));
  }, []);

  return (
    <FilterContainer
      filterType={FilterType.MORE_FILTERS}
      onFiltersApply={handleApplyFilters}
      filterName="More Filters"
      onAppliedFilterRemove={handleRemoveFilterFromList}
    >
      <FilterGroup>
        <Paragraph type="normal">Impact</Paragraph>
        <MultiButton
          isMulti
          isSmall
          active={selectedFilters.map(filter => filter.value as string)}
          tabsData={reportImpactOptions}
          onChange={handleSelectFilter}
        />
      </FilterGroup>
      <FilterGroup>
        <Paragraph type="normal">Data feed</Paragraph>
        <MultiButton
          isMulti
          isSmall
          active={selectedFilters.map(filter => filter.value as string)}
          tabsData={reportSourceOptions}
          onChange={handleSelectFilter}
        />
      </FilterGroup>
      <FilterGroup>
        <Paragraph type="normal">Report type</Paragraph>
        <MultiButton
          isMulti
          isSmall
          active={selectedFilters.map(filter => filter.value as string)}
          tabsData={reportTypeCategoryOptions}
          onChange={handleSelectFilter}
        />
      </FilterGroup>
    </FilterContainer>
  );
};

import { Action, combineReducers, Reducer } from 'redux';
import reduceReducers from 'reduce-reducers';

import { RootState } from '@/core/interfaces/store';
import config from '@/core/store/reducers/config';
import hydrateSlice from '@/core/store/reducers/hydrate';
import filters from '@/core/store/reducers/filtersSlice';

import auth from '@/features/Auth/store/slices';
import geofeatures from '@/features/Geofeatures/store/slices';
import reports from '@/features/Reports/store/slices';
import riskAssessment from '@/features/RiskAssessment/store/slices';
import focuses from '@/features/Focus/store/slices';

const featuresReducer = combineReducers({
  auth,
  config,
  reports,
  geofeatures,
  filters,
  riskAssessment,
  focuses,
});

export const rootReducer = reduceReducers<RootState>(hydrateSlice, featuresReducer) as Reducer<
  RootState,
  Action
>;

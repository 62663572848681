export enum OverlayStyle {
  BLUR,
  NONE,
}

export type ModalStyleProps = {
  fullWidth?: boolean;
  hasHeader?: boolean;
  positionY?: 'center' | 'flex-start' | 'flex-end';
  positionX?: 'center' | 'flex-start' | 'flex-end';
  height?: string;
};

export type OverlayPosition = {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
};

export type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  overlayStyle?: OverlayStyle;
  overlayPosition?: OverlayPosition;
  matchContentSize?: boolean;
  zIndex?: number;
} & ModalStyleProps &
  ReactModal.Props;

export type ModalHeaderProps = {
  center?: boolean;
  hasCloseButton?: boolean;
};

import { getMonth } from 'date-fns';
import { ReactNode, useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';

import {
  DatePickerMonthChangeButton,
  DatePickerMonthLabel,
  DatepickerMonthSelectionWrapper,
} from '@/core/components/DatePicker/DatePicker.styles';
import { Icon } from '@/core/components/Icons';
import { datepickerMonthsArray } from '@/core/constants/constants';

import { DatepickerCustomHeaderTypes } from './DatepickerCustomHeader.types';

export const DatepickerCustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  increaseYear,
  decreaseYear,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  isMonthPickerOpen,
  isYearPickerOpen,
  setIsMonthPickerOpen,
  setIsYearPickerOpen,
}: DatepickerCustomHeaderTypes): ReactNode => {
  const { colors } = useTheme();

  const openMonthPicker = useCallback(() => {
    if (isMonthPickerOpen) {
      setIsYearPickerOpen(true);
      setIsMonthPickerOpen(false);
    } else if (!isMonthPickerOpen && !isYearPickerOpen) {
      setIsMonthPickerOpen(true);
    }
  }, [isMonthPickerOpen, isYearPickerOpen, setIsMonthPickerOpen, setIsYearPickerOpen]);

  const isDayPickerOpen = useMemo(
    () => !isMonthPickerOpen && !isYearPickerOpen,
    [isMonthPickerOpen, isYearPickerOpen]
  );

  return (
    <DatepickerMonthSelectionWrapper>
      <DatePickerMonthChangeButton
        onClick={isDayPickerOpen ? decreaseMonth : decreaseYear}
        disabled={prevMonthButtonDisabled}
        isDisabled={prevMonthButtonDisabled}
      >
        <Icon
          color={colors.secondaryText}
          name="ChevronLeft"
        />
      </DatePickerMonthChangeButton>
      {date && (
        <DatePickerMonthLabel onClick={openMonthPicker}>
          {isDayPickerOpen ? datepickerMonthsArray[getMonth(date)] : date.getFullYear()}
        </DatePickerMonthLabel>
      )}
      <DatePickerMonthChangeButton
        onClick={isDayPickerOpen ? increaseMonth : increaseYear}
        disabled={nextMonthButtonDisabled}
        isDisabled={nextMonthButtonDisabled}
      >
        <Icon
          color={colors.secondaryText}
          name="ChevronRight"
        />
      </DatePickerMonthChangeButton>
    </DatepickerMonthSelectionWrapper>
  );
};

import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import MapboxDraw, { DrawCreateEvent } from '@mapbox/mapbox-gl-draw';
// @ts-expect-error - MapboxDraw static mode types are not available
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode';
import { Units } from '@turf/turf';
import { useSelector } from 'react-redux';

import { MapContext } from '@/core/context/MapContext';
import { colorPickerColors } from '@/core/constants/colors';
import { SelectOptionType } from '@/core/components/Select/SelectComponent.types';
import { GEOJSONShapeType } from '@/core/interfaces/geojsons';
import { disableDrawingOnMap, enableDrawingOnMap } from '@/core/store/reducers/config';
import { useAppDispatch } from '@/core/store/store';

import { getFoldersListSelector } from '@/features/Geofeatures/store';

import {
  drawLineBuffer,
  getLineBufferDataName,
  getLineBufferFillLayerName,
  getLineBufferStrokeLayerName,
  getLineDataName,
  removeLineGeofeature,
} from '@/utils/map/drawLine';

import { linePropertyName, useLineDefaultStyles } from './CreateLineGeofence.utils';
import { CreateLineGeofenceProps } from './CreateLineGeofence.types';
import { LineGeofenceForm } from './components/LineGeofenceForm';
import { LineGeofenceFormData } from './components/LineGeofenceForm/LineGeofenceForm.types';

// @ts-expect-error - MapboxDraw types are not available
MapboxDraw.constants.classes.CONTROL_BASE = 'maplibregl-ctrl';
// @ts-expect-error - MapboxDraw types are not available
MapboxDraw.constants.classes.CONTROL_PREFIX = 'maplibregl-ctrl-';
// @ts-expect-error - MapboxDraw types are not available
MapboxDraw.constants.classes.CONTROL_GROUP = 'maplibregl-ctrl-group';

const modes = MapboxDraw.modes;

// @ts-expect-error - MapboxDraw types are not available
modes.static = StaticMode;

export const CreateLineGeofence = ({
  initialData,
  defaultFolder,
  withoutFolder,
  onFormSubmit,
  onFormCancel,
}: CreateLineGeofenceProps) => {
  const { mapRef } = useContext(MapContext);
  const lineColor = useRef(initialData?.properties.color || colorPickerColors.color1);
  const lineBufferRadius = useRef(initialData?.properties.radius || 0);
  const lineBufferRadiusUnit = useRef<Units>(initialData?.properties.radiusUnit || 'kilometers');
  const lineId = useRef<string | undefined>(initialData?.properties.id);
  const geofeaturesFoldersList = useSelector(getFoldersListSelector);
  const dispatch = useAppDispatch();

  const lineDefaultStyles = useLineDefaultStyles();

  const defaultFormFolder = useMemo<SelectOptionType<number> | undefined>(() => {
    let folderOption = defaultFolder;

    if (initialData?.properties.folder) {
      const folder = geofeaturesFoldersList.find(f => f.id === initialData.properties.folder);

      if (folder) {
        folderOption = {
          label: folder.name,
          value: folder.id,
        };
      }
    } else if (!defaultFolder && geofeaturesFoldersList.length) {
      const firstFolder = geofeaturesFoldersList[0];

      folderOption = {
        label: firstFolder.name,
        value: firstFolder.id,
      };
    }

    return folderOption;
  }, [defaultFolder, geofeaturesFoldersList, initialData]);

  const draw = useMemo(
    () =>
      new MapboxDraw({
        displayControlsDefault: false,
        defaultMode: 'draw_line_string',
        boxSelect: false,
        // @ts-expect-error - added custom mode
        modes,
        styles: lineDefaultStyles,
        userProperties: true,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const changeLineColor = (propertyId: string, color = lineColor.current) => {
    draw.setFeatureProperty(propertyId, linePropertyName, color);

    if (!lineId.current) {
      return;
    }

    const lineDataGeoJSON = draw.get(lineId.current);

    if (!lineDataGeoJSON || !lineBufferRadius.current) {
      return;
    }

    drawLineBuffer(mapRef, {
      color: color,
      lineDataGeoJSON: lineDataGeoJSON,
      radius: lineBufferRadius.current,
      radiusUnit: lineBufferRadiusUnit.current,
      lineBufferDataName: getLineBufferDataName(lineId.current),
      lineBufferFillLayerName: getLineBufferFillLayerName(lineId.current),
      lineBufferStrokeLayerName: getLineBufferStrokeLayerName(lineId.current),
    });
  };

  useEffect(() => {
    const map = mapRef?.getMap();

    if (!map) {
      return;
    }

    dispatch(enableDrawingOnMap('line'));

    // @ts-expect-error - MapboxDraw types are not available
    if (!map.hasControl(draw)) {
      // @ts-expect-error - MapboxDraw types are not available
      map.addControl(draw);
    }

    if (!!initialData) {
      const initialLineId = initialData.properties.id;

      draw.add({
        ...initialData,
        id: initialLineId,
      });
      draw.changeMode('direct_select', { featureId: initialLineId });

      changeLineColor(initialLineId);

      removeLineGeofeature(mapRef, {
        lineDataName: getLineDataName(initialLineId),
        lineLayerName: getLineDataName(initialLineId),
        lineBufferDataName: getLineBufferDataName(initialLineId),
        lineBufferFillLayerName: getLineBufferFillLayerName(initialLineId),
        lineBufferStrokeLayerName: getLineBufferStrokeLayerName(initialLineId),
      });

      const lineDataGeoJSON = draw.get(initialLineId);

      if (lineDataGeoJSON && lineBufferRadius.current) {
        drawLineBuffer(mapRef, {
          color: lineColor.current,
          lineDataGeoJSON: lineDataGeoJSON,
          radius: lineBufferRadius.current,
          radiusUnit: lineBufferRadiusUnit.current,
          lineBufferDataName: getLineBufferDataName(initialLineId),
          lineBufferFillLayerName: getLineBufferFillLayerName(initialLineId),
          lineBufferStrokeLayerName: getLineBufferStrokeLayerName(initialLineId),
        });
      }
    }

    const onDrawCreate = (event: DrawCreateEvent) => {
      // We have to use `setTimeout` because draw event is fired over and over
      setTimeout(() => {
        if (event?.features && event.features.length === 1) {
          const featureId = event.features[0].id;

          if (!featureId) {
            return;
          }

          lineId.current = String(featureId);

          changeLineColor(lineId.current);

          draw.changeMode('static');

          const lineDataGeoJSON = draw.get(lineId.current);

          if (!lineDataGeoJSON || !lineBufferRadius.current) {
            return;
          }

          drawLineBuffer(mapRef, {
            color: lineColor.current,
            lineDataGeoJSON: lineDataGeoJSON,
            radius: lineBufferRadius.current,
            radiusUnit: lineBufferRadiusUnit.current,
            lineBufferDataName: getLineBufferDataName(lineId.current),
            lineBufferFillLayerName: getLineBufferFillLayerName(lineId.current),
            lineBufferStrokeLayerName: getLineBufferStrokeLayerName(lineId.current),
          });
        }
      });
    };

    const onDrawModeChange = () => {
      if (draw.getAll().features.length === 0) {
        draw.changeMode('draw_line_string');
      }
    };

    map.on('draw.create', onDrawCreate);
    map.on('draw.modechange', onDrawModeChange);

    return () => {
      map.off('draw.create', onDrawCreate);
      map.off('draw.modechange', onDrawModeChange);
      // @ts-expect-error - MapboxDraw types are not available
      map.removeControl(draw);

      dispatch(disableDrawingOnMap());

      if (lineId.current) {
        removeLineGeofeature(mapRef, {
          lineDataName: getLineDataName(lineId.current),
          lineLayerName: getLineDataName(lineId.current),
          lineBufferDataName: getLineBufferDataName(lineId.current),
          lineBufferFillLayerName: getLineBufferFillLayerName(lineId.current),
          lineBufferStrokeLayerName: getLineBufferStrokeLayerName(lineId.current),
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onColorChange = (color: string) => {
    lineColor.current = color;

    if (!lineId.current || !draw) {
      return;
    }

    // We have to use `setTimeout` because draw event is fired immediately after color change
    setTimeout(() => {
      changeLineColor(lineId.current!, color);

      draw.changeMode('static');

      if (!!initialData) {
        draw.changeMode('direct_select', { featureId: initialData.properties.id });
      }

      const lineDataGeoJSON = draw.get(lineId.current!);

      if (!lineDataGeoJSON || !lineBufferRadius.current) {
        return;
      }

      drawLineBuffer(mapRef, {
        color: color,
        lineDataGeoJSON: lineDataGeoJSON,
        radius: lineBufferRadius.current,
        radiusUnit: lineBufferRadiusUnit.current,
        lineBufferDataName: getLineBufferDataName(lineId.current!),
        lineBufferFillLayerName: getLineBufferFillLayerName(lineId.current!),
        lineBufferStrokeLayerName: getLineBufferStrokeLayerName(lineId.current!),
      });
    }, 1);
  };

  const onBufferRadiusChange = (radius: { value: number; unit: Units }) => {
    lineBufferRadius.current = radius.value;
    lineBufferRadiusUnit.current = radius.unit;

    if (!mapRef || !lineId.current) {
      return;
    }

    setTimeout(() => {
      const lineDataGeoJSON = draw.get(lineId.current!);

      if (!lineDataGeoJSON) {
        return;
      }

      drawLineBuffer(mapRef, {
        radius: radius.value,
        radiusUnit: radius.unit,
        color: lineColor.current,
        lineDataGeoJSON,
        lineBufferDataName: getLineBufferDataName(lineId.current!),
        lineBufferFillLayerName: getLineBufferFillLayerName(lineId.current!),
        lineBufferStrokeLayerName: getLineBufferStrokeLayerName(lineId.current!),
      });
    }, 1);
  };

  const handleFormSubmit = useCallback(
    async (formData: LineGeofenceFormData) => {
      if (!lineId.current) {
        return;
      }

      const lineData = draw.get(lineId.current);

      if (!lineData) {
        return;
      }

      await onFormSubmit({
        ...lineData,
        // @ts-expect-error - different types for coordinates but in reality they are the same
        geometry: {
          ...lineData.geometry,
          // @ts-expect-error different types for coordinates but in reality they are the same
          coordinates: lineData.geometry.coordinates,
        },
        properties: {
          shape: GEOJSONShapeType.LINE,
          id: lineId.current,
          color: formData.color,
          icon: formData.icon,
          name: formData.name,
          description: formData.description,
          folder: withoutFolder ? undefined : formData.folder.value,
          tags: formData.tags?.map(tag => tag.value) || [],
          radius: lineBufferRadius.current,
          radiusUnit: lineBufferRadiusUnit.current,
        },
      });
    },
    [draw, onFormSubmit, withoutFolder]
  );

  return (
    <LineGeofenceForm
      withoutFolder={withoutFolder}
      defaultValues={{
        color: lineColor.current,
        icon: initialData?.properties.icon || 'Flag',
        name: initialData?.properties.name || '',
        description: initialData?.properties.description || '',
        folder: defaultFormFolder,
        radiusUnit: lineBufferRadiusUnit.current,
        radiusValue: lineBufferRadius.current,
        tags:
          initialData?.properties.tags.map(tag => ({
            label: tag,
            value: tag,
          })) || [],
      }}
      onColorChange={onColorChange}
      onRadiusChange={onBufferRadiusChange}
      onSubmit={handleFormSubmit}
      onCancel={onFormCancel}
    />
  );
};

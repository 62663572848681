import styled from 'styled-components';

import { TextBodyNormal } from '../Typography/Typography.styles';

export const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const RadioButtonInput = styled.input.attrs({ type: 'radio' })`
  width: 18px;
  height: 18px;
  appearance: none;
  margin: 0 5px 0 0;
  outline: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 50%;
  cursor: pointer;

  &:checked {
    outline: 1px solid ${({ theme }) => theme.colors.active};
    border: 5px solid ${({ theme }) => theme.colors.background.medium};
    background-color: ${({ theme }) => theme.colors.active};
  }

  &:active {
    outline: 1px solid ${({ theme }) => theme.colors.active};
  }
`;

export const RadioButtonLabel = styled(TextBodyNormal).attrs({ as: 'label' })<{
  htmlFor: string;
  isChecked?: boolean;
}>`
  margin-left: 8px;
  color: ${({ theme, isChecked }) =>
    isChecked ? theme.colors.primaryText : theme.colors.secondaryText};
  cursor: pointer;
`;

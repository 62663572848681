import { MouseEvent, ReactElement, ReactNode } from 'react';

export enum DropdownPosition {
  LEFT,
  RIGHT,
}

type BaseDropdownItem = {
  id?: string | number;
  label: ReactNode;
  withSeparator?: boolean;
};

export type DropdownItem = BaseDropdownItem &
  (
    | {
        onClick: (event: MouseEvent<HTMLLIElement>) => void;
        nestedItems?: never;
        maxWidth?: never;
      }
    | {
        onClick?: never;
        nestedItems: Array<DropdownItem>;
        maxWidth?: string;
      }
  );

export type DropdownProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  control: ReactElement;
  menuItems: Array<DropdownItem>;
  offsetY?: number | string;
  offsetX?: number | string;
  usePortal?: boolean;
  dropdownZIndex?: number;
  position?: DropdownPosition;
  maxWidth?: string;
};

import Select, { components, GroupBase, StylesConfig, MultiValueRemoveProps } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useTheme } from 'styled-components';
import { useMemo } from 'react';

import { ThemeVariant } from '@/core/interfaces/common';
import { InputLabel } from '@/core/components/FormElements.styles';

import { SelectOptionType, SelectProps } from './SelectComponent.types';
import { Icon } from '../Icons';

const CustomMultiValueRemove = (props: MultiValueRemoveProps) => {
  const { colors } = useTheme();

  return (
    <components.MultiValueRemove {...props}>
      <Icon
        size={14}
        name="Close"
        color={colors.white}
      />
    </components.MultiValueRemove>
  );
};

export const SelectComponent = <
  SelectOption extends SelectOptionType,
  IsMulti extends boolean = false,
>({
  isSearchable = false,
  isClearable = false,
  creatable = false,
  label,
  ...props
}: SelectProps<SelectOption, IsMulti>) => {
  const { theme, colors, typography } = useTheme();

  const overwrittenSelectStyles = useMemo<
    StylesConfig<SelectOption | GroupBase<SelectOption>, IsMulti, GroupBase<SelectOption>>
  >(
    () => ({
      container: base => ({
        ...base,
        fontFamily: typography.fontFamily.inter,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
      }),
      control: () => ({
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: colors.background.light,
        borderRadius: '4px',
        border: `1px solid ${theme === ThemeVariant.LIGHT ? colors.border : 'transparent'}`,
        boxShadow: 'none',
        '&:focus, &:hover': {},
        '&:hover': {
          borderColor: theme === ThemeVariant.LIGHT ? colors.border : 'transparent',
        },
        cursor: 'pointer',
      }),
      input: base => ({
        ...base,
        margin: 0,
        padding: 0,
        color: colors.primaryText,
      }),
      valueContainer: base => ({
        ...base,
        gap: '10px',
        padding: '13px 20px',
      }),
      singleValue: base => ({
        ...base,
        margin: 0,
        color: colors.primaryText,
      }),
      multiValue: () => ({
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        margin: 0,
        borderRadius: '4px',
        padding: '5px 10px',
        backgroundColor: colors.tags.tagColor,
        cursor: 'default',
      }),
      multiValueLabel: () => ({
        color: colors.primaryText,
        fontSize: '14px',
        lineHeight: '17px',
      }),
      multiValueRemove: () => ({
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      placeholder: base => ({
        ...base,
        color: colors.primaryText,
      }),
      menu: base => ({
        ...base,
        marginTop: '4px',
        borderRadius: '6px',
        backgroundColor: colors.background.light,
        boxShadow: '0px 10px 35px 0px #00000036',
      }),
      option: () => ({
        color: colors.secondaryText,
        padding: '13px 20px',
        cursor: 'pointer',
        '&:hover': {
          color: colors.primaryText,
          backgroundColor: colors.background.medium,
          '&:last-of-first': {
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
          },
          '&:last-of-type': {
            borderBottomLeftRadius: '6px',
            borderBottomRightRadius: '6px',
          },
        },
      }),
      indicatorsContainer: () => ({
        padding: '13px 20px',
        '& svg': {
          fill: colors.primaryText,
        },
      }),
      dropdownIndicator: () => ({
        display: 'flex',
      }),
    }),
    [theme, colors, typography]
  );

  const Component = creatable ? CreatableSelect : Select;

  return (
    <div>
      {label && <InputLabel>{label}</InputLabel>}
      <Component
        // @ts-expect-error select props are not compatible with react-select props
        styles={overwrittenSelectStyles}
        components={{
          MultiValueRemove: CustomMultiValueRemove,
        }}
        {...props}
        isSearchable={isSearchable || creatable}
        isClearable={isClearable}
      />
    </div>
  );
};

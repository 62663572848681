export const routePaths = {
  home: '/',
  multilist: '/multilist',
  riskAssessment: '/risk-assessment',
  error: '/404',
  login: '/login',
  protected: '/protected',
  server: '/server',
  static: '/static',
  subpage: '/subpage',
  forgotPassword: '/forgot-password',
  myAccount: '/my-account',
  myAccountSettings: '/my-account/settings',
  myOrganization: '/my-organization',
};

import styled from 'styled-components';

export const PickedColorContainer = styled.div`
  display: inline-block;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 6px;
  padding: 4px;
`;

export const PickedColor = styled.button<{ color: string }>`
  display: block;
  width: 38px;
  height: 38px;
  border: none;
  border-radius: 4px;
  background-color: ${({ color }) => color};
  cursor: pointer;
`;

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_TIME_FORMAT = 'HH:mm:ssZ';
export const DEFAULT_DATEPICKER_DATE_FORMAT = 'yyyy-MM-dd';

export const DEFAULT_DATE_AND_TIME_FORMAT = `${DEFAULT_DATE_FORMAT}${DEFAULT_TIME_FORMAT}`;

export const CARD_DATE_FORMAT = 'dddd, MMMM D, YYYY';
export const TIME_PUBLISHED_FORMAT = 'dddd DD MMMM YYYY HH:mm';
export const EVENT_DATE_FORMAT = 'dddd DD, MMMM YYYY';
export const SOURCE_DATE_FORMAT = 'MMMM D, YYYY - HH:mm [CET]';
export const RISK_ASSESSMENT_UPDATE_DATE_FORMAT = 'D MMMM YYYY';

import { LngLatLike, MapRef } from 'react-map-gl/maplibre';
import { Units, bbox, circle } from '@turf/turf';

import { RadialCenter } from '@/core/interfaces/common';

const transformBboxToLatLng = (polygonBbox: Array<number>): [LngLatLike, LngLatLike] => {
  const [minX, minY, maxX, maxY] = polygonBbox;

  return [
    [minX, minY],
    [maxX, maxY],
  ];
};

export const getCircleDataName = (dataId: string) => `circleData-${dataId}`;
export const getCircleFillLayerName = (dataId: string) => `circle-fill-${dataId}`;
export const getCircleStrokeLayerName = (dataId: string) => `circle-stroke-${dataId}`;

export const drawRadialGeofeature = (
  mapRef: MapRef | null,
  {
    center,
    radius,
    units,
    color,
    circleDataName,
    circleFillLayerName,
    circleStrokeLayerName,
    lineType = 'solid',
    shouldZoom = false,
  }: {
    center: RadialCenter | null;
    radius: number;
    units: Units;
    color: string;
    circleDataName: string;
    circleFillLayerName: string;
    circleStrokeLayerName: string;
    lineType?: 'dash' | 'solid';
    shouldZoom?: boolean;
  }
) => {
  if (!center || !mapRef) {
    return;
  }

  const map = mapRef.getMap();
  const options: {
    steps?: number;
    units?: Units;
  } = {
    steps: 80,
    units,
  };

  const circlePolygon = circle(center, radius, options);
  const circleSource = map.getSource(circleDataName);
  const circleFillLayer = map.getLayer(circleFillLayerName);
  const circleStrokeLayer = map.getLayer(circleStrokeLayerName);

  if (circleSource) {
    // @ts-expect-error - setData is not available in the types
    circleSource.setData(circle(center, radius, options));
  } else {
    map.addSource(circleDataName, {
      type: 'geojson',
      data: circlePolygon,
    });
  }

  if (circleFillLayer) {
    map.setPaintProperty(circleFillLayerName, 'fill-color', color);
  } else {
    map.addLayer({
      id: circleFillLayerName,
      type: 'fill',
      source: circleDataName,
      paint: {
        'fill-color': color,
        'fill-opacity': 0.2,
      },
    });
  }

  if (circleStrokeLayer) {
    map.setPaintProperty(circleStrokeLayerName, 'line-color', color);
  } else {
    if (lineType === 'dash' || lineType === 'solid') {
      map.addLayer({
        id: circleStrokeLayerName,
        type: 'line',
        source: circleDataName,
        paint: {
          'line-color': color,
          'line-width': 2,
          ...(lineType === 'dash' ? { 'line-dasharray': [2, 2] } : {}),
        },
      });
    }
  }

  if (shouldZoom) {
    mapRef.fitBounds(transformBboxToLatLng(bbox(circlePolygon)), {
      padding: 200,
    });
  }
};

export const removeRadialGeofeature = (
  mapRef: MapRef | null,
  {
    circleDataName,
    circleFillLayerName,
    circleStrokeLayerName,
  }: {
    circleDataName: string;
    circleFillLayerName: string;
    circleStrokeLayerName: string;
  }
) => {
  if (!mapRef) {
    return;
  }

  const map = mapRef.getMap();

  const dataNamesToRemove = [circleDataName, circleFillLayerName, circleStrokeLayerName];

  dataNamesToRemove.forEach(dataName => {
    // We have to check style because `getLayer` will throw an error on page change because `style` is undefined
    if (dataName && map.style && map.getLayer(dataName)) {
      map.removeLayer(dataName);
    }
  });

  dataNamesToRemove.forEach(dataName => {
    // We have to check style because `getSource` will throw an error on page change because `style` is undefined
    if (dataName && map.style && map.getSource(dataName)) {
      map.removeSource(dataName);
    }
  });
};

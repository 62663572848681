import styled from 'styled-components';

export const CategoryFilterContentContainer = styled.div`
  width: 100%;
  max-width: 794px;
`;

export const CategoryFilterSubCategoryList = styled.div`
  display: grid;
  grid-template-columns: 220px auto;
  gap: 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.border};

  &:first-of-type {
    border-top: none;
  }
  padding: 24px 0;
`;

export const CategoryFilterCheckboxesWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
`;

export const CategoryFilterIconCircle = styled.div<{ backgroundColor?: string }>`
  width: 38px;
  height: 38px;
  border-radius: 32px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const CategoryFilterSectionWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 14px;
`;

export const CategoryFilterIconWrapper = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
`;

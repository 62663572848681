import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import {
  MoreTabItemlabel,
  MoreTabsContainer,
  StyledTab,
  StyledTabBoldLabel,
  StyledTabLabel,
  TabsContainer,
  TabsListContainer,
} from '@/core/components/Tabs/Tabs.styles';
import { TabsDataType, TabsTypeProps, TabTypeProps } from '@/core/components/Tabs/Tabs.types';
import { Dropdown } from '@/core/components/Dropdown';
import { Paragraph } from '@/core/components/Typography';
import { Icon } from '@/core/components/Icons';
import { DropdownItem, DropdownPosition } from '@/core/components/Dropdown/Dropdown.types';

import { DropdownControl } from '@/features/Map/components/Toolbar/Toolbar.styles';

const Tab = ({ id, label, isActive, onTabChange }: TabTypeProps) => {
  return (
    <StyledTab
      isActive={isActive}
      onClick={() => onTabChange(id)}
    >
      {isActive ? (
        <StyledTabBoldLabel key={id}>{label}</StyledTabBoldLabel>
      ) : (
        <StyledTabLabel key={id}>{label}</StyledTabLabel>
      )}
    </StyledTab>
  );
};

export const Tabs = ({
  tabs,
  activeTab,
  onTabChange,
  isDropdownOpen,
  setIsDropdownOpen,
}: TabsTypeProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const tabsListContainerRef = useRef<HTMLUListElement>(null);
  const moreTabsContainerRef = useRef<HTMLDivElement>(null);
  const { colors } = useTheme();

  const [tabsList, setTabsList] = useState<Array<TabsDataType>>(tabs);
  const [moreTabsList, setMoreTabsList] = useState<Array<TabsDataType>>([]);
  const [tabsContainerWidth, setTabsContainerWidth] = useState<number>(0);

  const handleTabChange = useCallback(
    (tabId: number) => {
      onTabChange(tabId);
      setIsDropdownOpen(false);
    },
    [onTabChange, setIsDropdownOpen]
  );

  const mappedMoreTabs = useMemo<Array<DropdownItem>>(
    () =>
      moreTabsList.map(tab => ({
        id: tab.id,
        label: <MoreTabItemlabel isActive={tab.id === activeTab}>{tab.name}</MoreTabItemlabel>,
        onClick: () => handleTabChange(tab.id),
      })),
    [activeTab, handleTabChange, moreTabsList]
  );

  const isDropdownTabSelected = useMemo<boolean>(
    () => !!moreTabsList.find(tab => tab.id === activeTab),
    [activeTab, moreTabsList]
  );

  useEffect(() => {
    setTabsList(tabs);
    setMoreTabsList([]);
  }, [tabs]);

  useEffect(() => {
    if (!containerRef.current || !tabsListContainerRef.current) {
      return;
    }

    const calculateTabsWidth = () => {
      const containerWidth = containerRef.current?.getBoundingClientRect().width || 0;
      const tabsListWidth = Array.from(tabsListContainerRef.current?.children || []).reduce(
        (acc, childNode) => {
          const childWidth = childNode.getBoundingClientRect().width || 0;

          return acc + childWidth;
        },
        0
      );
      const moreTabsWidth = moreTabsContainerRef.current?.getBoundingClientRect().width || 0;

      if (tabsListWidth + moreTabsWidth > containerWidth) {
        const tabsListCopy = [...tabsList];
        const lastTab = tabsListCopy.pop();

        if (lastTab) {
          const moreTabs = [lastTab, ...moreTabsList];

          setTabsList(tabsListCopy);
          setMoreTabsList(moreTabs);
        }
      }

      setTabsContainerWidth(containerWidth);
    };

    calculateTabsWidth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsList]);

  return (
    <TabsContainer ref={containerRef}>
      <TabsListContainer
        ref={tabsListContainerRef}
        withMore={!!mappedMoreTabs.length}
      >
        {tabsList.map(tab => {
          const isTabActive = tab.id === activeTab;

          return (
            <Tab
              key={tab.id}
              id={tab.id}
              label={tab.name}
              isActive={isTabActive}
              onTabChange={handleTabChange}
            />
          );
        })}
      </TabsListContainer>
      {mappedMoreTabs.length > 0 && (
        <MoreTabsContainer
          isActive={isDropdownTabSelected}
          ref={moreTabsContainerRef}
        >
          <Dropdown
            isOpen={isDropdownOpen}
            setIsOpen={setIsDropdownOpen}
            position={DropdownPosition.LEFT}
            control={
              <DropdownControl>
                <Paragraph
                  type="normal"
                  color={colors.secondaryText}
                >
                  More
                </Paragraph>
                <Icon
                  name="ChevronDown"
                  color={colors.secondaryText}
                  size={16}
                />
              </DropdownControl>
            }
            menuItems={mappedMoreTabs}
            offsetY={34}
            maxWidth={`${tabsContainerWidth}px`}
          />
        </MoreTabsContainer>
      )}
    </TabsContainer>
  );
};

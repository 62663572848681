import { useContext, useEffect, useRef } from 'react';
import { useSession } from 'next-auth/react';

import { RefreshContext } from '@/core/components/Session';
import { LoginStatesValues } from '@/core/constants/constants';

import { request } from '@/utils/request';

export const useAuthenticatedSession = async (
  fn: () => void | (() => void),
  deps: Array<unknown>
) => {
  const isFetchedSession = useRef(false);
  const { status, data: session } = useSession();
  const isRefreshing = useContext(RefreshContext);

  useEffect(() => {
    if (!isFetchedSession.current && status === LoginStatesValues.AUTHENTICATED && !isRefreshing) {
      isFetchedSession.current = true;

      if (session?.accessToken) {
        request.setAuthorizationToken(session?.accessToken);
      }

      return fn();
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, isRefreshing, ...deps]);
};

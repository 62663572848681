import { StyledBadge } from './Badge.styles';
import { BadgeProps, BadgeSize } from './Badge.types';

export const Badge = ({
  text,
  backgroundColor,
  color = '#fff',
  leadingIcon,
  size = BadgeSize.SMALL,
  isNormalcase = false,
  useFontSizeFactor = false,
}: BadgeProps) => {
  return (
    <StyledBadge
      color={color}
      backgroundColor={backgroundColor}
      size={size}
      isNormalcase={isNormalcase}
      useFontSizeFactor={useFontSizeFactor}
    >
      {leadingIcon}
      {text}
    </StyledBadge>
  );
};

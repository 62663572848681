import styled, { keyframes } from 'styled-components';

import { LoaderProps } from './Loader.types';

const loaderAnimation = keyframes`
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
`;

export const Loader = styled.div<LoaderProps>`
  display: inline-block;
  width: ${({ size }) => size || '32px'};
  height: ${({ size }) => size || '32px'};
  border: 5px solid #fff;
  border-radius: 50%;
  border-bottom-color: transparent;
  animation: ${loaderAnimation} 1s linear infinite;
`;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { UserRole } from '@/core/interfaces/common';
import { webAppRoles } from '@/core/constants/constants';

import { api } from '@/features/Auth/api';
import {
  ForgotPasswordRequestPayload,
  ForgotResetResponseError,
  ResetPasswordRequestPayload,
  ResetPasswordRequestQueryParams,
  ResetPasswordResponseError,
  ChangePasswordRequestData,
  UserUpdateRequestData,
  UserUpdateResponse,
  GetUsersParams,
  GetOrganizationsParams,
} from '@/features/Auth/interfaces';

import { getActionPrefix } from '@/utils/helpers';

const actionPrefix = getActionPrefix('auth');

export const getUser = createAsyncThunk(`${actionPrefix}/getUser`, async () => {
  const { data: userData } = await api.getUser();
  const { data: organizationSubscriptionData } = await api.getUserOrganizationSubscription(
    userData.organization.id
  );

  return {
    ...userData,
    organization: {
      ...userData.organization,
      currentSubscription: organizationSubscriptionData.currentSubscription,
    },
  };
});

export const updateUser = createAsyncThunk<
  UserUpdateResponse,
  {
    userId: string;
    userData: UserUpdateRequestData;
  },
  {
    rejectValue: {
      formErrors: {
        [key: string]: Array<string>;
      };
    };
  }
>(`${actionPrefix}/updateUser`, async ({ userId, userData }, { rejectWithValue }) => {
  const formData = new FormData();

  if (userData.profilePicture) {
    formData.append('profilePicture', userData.profilePicture);
  }

  try {
    const { data } = await api.updateUser(userId, formData);

    return data;
  } catch (error) {
    // @ts-expect-error err is unknown
    if (error?.response?.data) {
      return rejectWithValue({
        // @ts-expect-error response is unknown
        formErrors: error?.response?.data,
      });
    }

    throw error;
  }
});

export const changePassword = createAsyncThunk<
  void,
  { userId: string; passwordData: ChangePasswordRequestData },
  {
    rejectValue: {
      formErrors: {
        [key: string]: Array<string>;
      };
    };
  }
>(`${actionPrefix}/changePassword`, async ({ userId, passwordData }, { rejectWithValue }) => {
  try {
    const { data } = await api.changePassword(userId, passwordData);

    return data;
  } catch (error) {
    // @ts-expect-error err is unknown
    if (error?.response?.data) {
      return rejectWithValue({
        // @ts-expect-error response is unknown
        formErrors: error?.response?.data,
      });
    }

    throw error;
  }
});

export const getOrganizationUsers = createAsyncThunk(
  `${actionPrefix}/getOrganizationUsers`,
  async (organizationId: string) => {
    const { data } = await api.getOrganizationUsers(organizationId);

    // As we don't need group except the one that are for the web app
    // we filter them out as user can have only one group from the web app roles
    return data.map(user => ({
      ...user,
      groups: user.groups.filter(group => webAppRoles.includes(group)),
    }));
  }
);

export const updateOrganizationUserRole = createAsyncThunk<
  void,
  { userId: string; role: UserRole }
>(`${actionPrefix}/updateOrganizationUserRole`, async ({ userId, role }) => {
  const { data } = await api.updateOrganizationUserRole(userId, { role });

  return data;
});

export const sendForgotPassword = createAsyncThunk<
  void,
  ForgotPasswordRequestPayload,
  {
    rejectValue: ForgotResetResponseError;
  }
>(`${actionPrefix}/sendResetPassword`, async (payload, { rejectWithValue }) => {
  try {
    const { data } = await api.sendForgotPassword(payload);

    return data;
  } catch (error) {
    // @ts-expect-error error is not defined
    if (error.response.data) {
      // @ts-expect-error error is not defined
      return rejectWithValue(error.response.data);
    }

    throw error;
  }
});

export const sendResetPassword = createAsyncThunk<
  void,
  {
    queryParams: ResetPasswordRequestQueryParams;
    payload: ResetPasswordRequestPayload;
  },
  { rejectValue: ResetPasswordResponseError }
>(`${actionPrefix}/sendResetPassword`, async ({ payload, queryParams }, { rejectWithValue }) => {
  try {
    const { data } = await api.sendResetPassword(queryParams, payload);

    return data;
  } catch (error) {
    // @ts-expect-error error is not defined
    if (error.response.data) {
      // @ts-expect-error error is not defined
      return rejectWithValue({ formErrors: error.response.data });
    }

    throw error;
  }
});

export const getUsers = createAsyncThunk(
  `${actionPrefix}/getUsers`,
  async (params: GetUsersParams) => {
    const { data } = await api.getUsers(params);

    return data;
  }
);

export const getOrganizations = createAsyncThunk(
  `${actionPrefix}/getOrganizations`,
  async (params: GetOrganizationsParams) => {
    const { data } = await api.getOrganizations(params);

    return data;
  }
);

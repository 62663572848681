import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/core/interfaces/store';
import { GEOJSONShapeType } from '@/core/interfaces/geojsons';

import { GeofeaturesState } from '@/features/Geofeatures/interfaces';

const featureStateSelector = (state: RootState): GeofeaturesState => state.geofeatures;

export const isGeofeatureModalOpenSelector = createSelector(
  featureStateSelector,
  state => state.isGeofeaturesModalOpen
);

export const getFoldersListSelector = createSelector(
  featureStateSelector,
  state => state.foldersList
);

export const getAllGeofeaturesListSelector = createSelector(
  featureStateSelector,
  state => state.geofeaturesList
);

export const getGeofeaturesListSelector = createSelector(featureStateSelector, state =>
  state.geofeaturesList.filter(
    geofeature => geofeature.geoJSONData.properties.shape !== GEOJSONShapeType.POI
  )
);

export const getPointsOfInterestListSelector = createSelector(featureStateSelector, state =>
  state.geofeaturesList.filter(
    geofeature => geofeature.geoJSONData.properties.shape === GEOJSONShapeType.POI
  )
);

import styled from 'styled-components';

export const SearchContainer = styled.div`
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding-bottom: 23px;
`;

export const GeofencesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 18px;
`;

export const GeofenceItem = styled.div`
  display: flex;
  justify-content: space-between;
  border: none;
  border-radius: 6px;
  padding: 13px 20px;
  background: ${({ theme }) => theme.colors.background.light};
`;

export const RemoveGeofenceButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: none;
  padding: 0;
  background: none;
  text-align: center;
  cursor: pointer;
`;

import styled, { css } from 'styled-components';

import { fontVariantMap, getVariant } from '@/themes/typographyUtilis';

import { BadgeSize } from './Badge.types';

export const StyledBadge = styled.div<{
  color?: string;
  backgroundColor: string;
  size?: BadgeSize;
  isNormalcase?: boolean;
  useFontSizeFactor?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  min-height: ${({ size }) => (size === BadgeSize.SMALL ? '19px' : '21px')};
  border-radius: 4px;
  padding: 0 6px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  font-weight: 700;
  ${({ isNormalcase }) => !isNormalcase && 'text-transform: uppercase;'}
  ${({ size, useFontSizeFactor }) =>
    size === BadgeSize.SMALL &&
    css`
      ${getVariant({
        variant: 'caption-bold',
      })}
      font-size: calc(${fontVariantMap['caption-bold'].fontSize} * ${({ theme }) =>
        useFontSizeFactor ? theme.reportListItemFontSizeFactor : '1'});
      line-height: calc(
        ${fontVariantMap['caption-bold'].lineHeight} *
          ${({ theme }) => (useFontSizeFactor ? theme.reportListItemFontSizeFactor : '1')}
      );
    `}
  ${({ size, useFontSizeFactor }) =>
    size !== BadgeSize.SMALL &&
    css`
      ${getVariant({
        variant: 'body2-bold',
      })}
      font-size: calc(${fontVariantMap['body2-bold'].fontSize} * ${({ theme }) =>
        useFontSizeFactor ? theme.reportListItemFontSizeFactor : '1'});
      line-height: calc(
        ${fontVariantMap['body2-bold'].lineHeight} *
          ${({ theme }) => (useFontSizeFactor ? theme.reportListItemFontSizeFactor : '1')}
      );
    `}
`;

import { useState, useEffect, useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

import { LocationSearch } from '@/core/components/LocationSearch';
import { SuggestionType } from '@/core/components/LocationSearch/LocationSearch.types';
import {
  FilterListItem,
  FilterListItemRemoveIcon,
} from '@/core/components/FilterContainer/FilterContainer.styles';
import { getCountriesSelector } from '@/core/store/reducers/config';
import { CountryLocationData } from '@/core/interfaces/locations';

import { CountryViewProps } from './CountryView.types';
import { FilterListItemWrapper } from './CountryView.styles';

export const CountryView = ({ onSelectCountries, appliedFilters }: CountryViewProps) => {
  const countriesList = useSelector(getCountriesSelector);

  const countrySuggestions = useMemo(() => {
    const regions = new Map<string, CountryLocationData>();
    const countries: Array<CountryLocationData> = [];

    countriesList.forEach(country => {
      regions.set(country.region, {
        name: country.region,
        id: country.regionId,
      });

      countries.push({
        name: country.name,
        id: country.id,
      });
    });

    return [...Array.from(regions.values()), ...countries].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }, [countriesList]);

  const [suggestions, setSuggestions] = useState<Array<SuggestionType>>(countrySuggestions);
  const [selectedCountries, setSelectedCountries] = useState<Array<CountryLocationData>>(
    appliedFilters.map(filter => ({
      name: filter.label,
      id: filter.value as number,
    }))
  );
  const { colors } = useTheme();

  const handleSelectCountry = useCallback((country: CountryLocationData) => {
    setSelectedCountries(prevCountries => [...prevCountries, country]);

    setSuggestions(prevSuggestions =>
      prevSuggestions.filter(suggestion => suggestion.name !== country.name)
    );
  }, []);

  const handleRemoveCountry = useCallback((country: CountryLocationData) => {
    setSelectedCountries(prevCountries =>
      prevCountries.filter(prevCountry => prevCountry !== country)
    );
    setSuggestions(prevSuggestions => [...prevSuggestions, country]);
  }, []);

  useEffect(() => {
    onSelectCountries(selectedCountries);
    setSuggestions(
      countrySuggestions.filter(
        suggestion => !selectedCountries.some(country => country.name === suggestion.name)
      )
    );
  }, [selectedCountries, onSelectCountries, countrySuggestions]);

  useEffect(() => {
    setSelectedCountries(
      appliedFilters.map(filter => ({
        name: filter.label,
        id: filter.value as number,
      }))
    );
  }, [appliedFilters]);

  return (
    <>
      <LocationSearch
        // @ts-expect-error suggestion has id from parent
        onSelectLocation={handleSelectCountry}
        suggestions={suggestions}
      />
      <FilterListItemWrapper>
        {selectedCountries.map(country => (
          <FilterListItem key={country.name}>
            {country.name}
            <FilterListItemRemoveIcon
              color={colors.white}
              size={12}
              onClick={() => handleRemoveCountry(country)}
            />
          </FilterListItem>
        ))}
      </FilterListItemWrapper>
    </>
  );
};

import styled from 'styled-components';

import { getVariant } from '@/themes/typographyUtilis';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 676px;
  max-height: 90vh;
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.21);
  border-radius: 12px;
  padding: 40px;
  background-color: ${({ theme }) => theme.colors.modalBackground};
`;

export const CloseModalButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border: none;
  padding: 0;
  background: none;
  cursor: pointer;
`;

export const CodeContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 6px;
  padding: 14px;
  background: ${({ theme }) => theme.colors.background.medium};
`;

export const CodeContent = styled.pre`
  overflow-y: auto;
  height: 100%;
  margin: 0;
  color: ${({ theme }) => theme.colors.primaryText};
`;

export const CopyButton = styled.button`
  position: absolute;
  top: 7px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin: 0;
  border: none;
  border-radius: 6px;
  padding: 6px 10px;
  background: ${({ theme }) => theme.colors.teal};
  color: ${({ theme }) => theme.colors.white};
  ${getVariant({ variant: 'body2-normal' })}
  cursor: pointer;
`;

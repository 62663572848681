import { createAsyncThunk } from '@reduxjs/toolkit';

import { ReportSourceType } from '@/core/interfaces/common';

import { api } from '@/features/Reports/api';
import { ReportResponseItem, ReportsListData } from '@/features/Reports/interfaces';

import { getActionPrefix } from '@/utils/helpers';

import {
  appendHorizonReports,
  appendLookoutReports,
  appendMulticolumnHorizonReport,
  appendMulticolumnLookoutReport,
} from './slices';

const actionPrefix = getActionPrefix('reports');

export const getHorizonReportsList = createAsyncThunk<Array<ReportResponseItem>, ReportsListData>(
  `${actionPrefix}/getHorizonReportsList`,
  async (filtersQueryParams, { signal, dispatch }) => {
    let pageNumber = 1;
    let shouldFetch = true;
    const reportsList: Array<ReportResponseItem> = [];
    const shouldFetchAllPages = !filtersQueryParams.page;

    // We have to fetch all pages in one go as we don't have pagination views on the map
    do {
      try {
        const { data } = await api.getHorizonReportsList(
          {
            page: pageNumber,
            ...filtersQueryParams,
          },
          signal
        );

        if (shouldFetchAllPages) {
          dispatch(appendHorizonReports(data.results));
        }

        reportsList.push(...data.results);

        pageNumber += 1;
        shouldFetch = !!data.next;
      } catch {
        shouldFetch = false;
      }
    } while (shouldFetch && shouldFetchAllPages);

    return reportsList;
  }
);

export const getLookoutReportsList = createAsyncThunk<Array<ReportResponseItem>, ReportsListData>(
  `${actionPrefix}/getLookoutReportsList`,
  async (filtersQueryParams, { signal, dispatch }) => {
    let pageNumber = 1;
    let shouldFetch = true;
    const reportsList: Array<ReportResponseItem> = [];
    const shouldFetchAllPages = !filtersQueryParams.page;

    // We have to fetch all pages in one go as we don't have pagination views on the map
    do {
      try {
        const { data } = await api.getLookoutReportsList(
          {
            page: pageNumber,
            ...filtersQueryParams,
          },
          signal
        );

        if (shouldFetchAllPages) {
          dispatch(appendLookoutReports(data.results));
        }

        reportsList.push(...data.results);

        pageNumber += 1;
        shouldFetch = !!data.next;
      } catch {
        shouldFetch = false;
      }
    } while (shouldFetch && shouldFetchAllPages);

    return reportsList;
  }
);

export const getHorizonReportsListForMulticolumn = createAsyncThunk<
  Array<ReportResponseItem>,
  {
    focusId: number;
    filtersQueryParams: ReportsListData;
  }
>(
  `${actionPrefix}/getHorizonReportsListForMulticolumn`,
  async ({ focusId, filtersQueryParams }, { signal, dispatch }) => {
    let pageNumber = 1;
    let shouldFetch = true;
    const reportsList: Array<ReportResponseItem> = [];
    const shouldFetchAllPages = !filtersQueryParams.page;

    // We have to fetch all pages in one go as we don't have pagination views on the map
    do {
      try {
        const { data } = await api.getHorizonReportsList(
          {
            page: pageNumber,
            ...filtersQueryParams,
          },
          signal
        );

        if (shouldFetchAllPages) {
          dispatch(
            appendMulticolumnHorizonReport({
              focusId,
              reports: data.results.map(item => ({
                ...item,
                wasOpened: false,
                reportSourceType: ReportSourceType.HORIZON,
              })),
            })
          );
        } else {
          reportsList.push(...data.results);
        }

        pageNumber += 1;
        shouldFetch = !!data.next;
      } catch {
        shouldFetch = false;
      }
    } while (shouldFetch && shouldFetchAllPages);

    return reportsList;
  }
);

export const getLookoutReportsListForMulticolumn = createAsyncThunk<
  Array<ReportResponseItem>,
  {
    focusId: number;
    filtersQueryParams: ReportsListData;
  }
>(
  `${actionPrefix}/getLookoutReportsListForMulticolumn`,
  async ({ focusId, filtersQueryParams }, { signal, dispatch }) => {
    let pageNumber = 1;
    let shouldFetch = true;
    const reportsList: Array<ReportResponseItem> = [];
    const shouldFetchAllPages = !filtersQueryParams.page;

    // We have to fetch all pages in one go as we don't have pagination views on the map
    do {
      try {
        const { data } = await api.getLookoutReportsList(
          {
            page: pageNumber,
            ...filtersQueryParams,
          },
          signal
        );

        if (shouldFetchAllPages) {
          dispatch(
            appendMulticolumnLookoutReport({
              focusId,
              reports: data.results.map(item => ({
                ...item,
                wasOpened: false,
                reportSourceType: ReportSourceType.LOOKOUT,
              })),
            })
          );
        } else {
          reportsList.push(...data.results);
        }

        pageNumber += 1;
        shouldFetch = !!data.next;
      } catch {
        shouldFetch = false;
      }
    } while (shouldFetch && shouldFetchAllPages);

    return reportsList;
  }
);

import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import { Dropdown, DropdownItem } from '@/core/components/Dropdown';
import { Icon } from '@/core/components/Icons';
import { DeleteModal } from '@/core/components/DeleteModal';
import { ExportGeojsonModal } from '@/core/components/ExportGeojsonModal';
import { ColorsModal } from '@/core/components/ColorPicker/components/ColorsModal';
import { colorPickerColors } from '@/core/constants/colors';
import { IconsModal } from '@/core/components/IconPicker/components/IconsModal';
import { IconPickerIcons } from '@/core/constants/icons';
import { GEOJSONData, GEOJSONShapeType } from '@/core/interfaces/geojsons';
import { useAppDispatch } from '@/core/store/store';
import { ExcludeEmpty } from '@/core/interfaces/common';

import { getAllGeofeaturesListSelector } from '@/features/Geofeatures/store';
import { deleteGeofeatures, updateGeofeaturesData } from '@/features/Geofeatures/store/actions';
import {
  GeofeatureItem,
  UpdateGeofeaturesData,
  UpdateRadialGeofeatureData,
} from '@/features/Geofeatures/interfaces';

import { createGeoJSONStringForExport } from '@/utils/helpers';

import { GeofeaturesBulkActionsProps } from './GeofeaturesBulkActions.types';
import {
  BulkActionsButton,
  Container,
  DeleteModalContent,
  DeselectButton,
} from './GeofeaturesBulkActions.styles';

export const GeofeaturesBulkActions = ({
  selectedGeofeaturesIds,
  geofeaturesFolders,
  onSelectAll,
  onDeselectAll,
  setSelectedGeofeatures,
}: GeofeaturesBulkActionsProps) => {
  const geofeaturesList = useSelector(getAllGeofeaturesListSelector);

  const dispatch = useAppDispatch();

  const [isBulkActionsOpen, setIsBulkActionsOpen] = useState(false);
  const [isChangeColorModalVisible, setIsChangeColorModalVisible] = useState(false);
  const [isChangeIconModalVisible, setIsChangeIconModalVisible] = useState(false);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [isDeleteConfirmModalVisible, setIsDeleteConfirmModalVisible] = useState(false);

  const selectedGeofeatures = useMemo<Array<GeofeatureItem>>(
    () =>
      selectedGeofeaturesIds
        .map(id => geofeaturesList.find(item => item.id === id))
        .filter(Boolean as unknown as ExcludeEmpty),
    [geofeaturesList, selectedGeofeaturesIds]
  );

  const updateGeofeaturesProperties = useCallback<
    (properties: Partial<UpdateGeofeaturesData[0]['properties']>) => UpdateGeofeaturesData
  >(
    properties => {
      const updatedGeofeatures = selectedGeofeatures.map<UpdateGeofeaturesData[0]>(geofeature => {
        const additionalProperties: Partial<UpdateGeofeaturesData[0]['properties']> = {};

        if (geofeature.geoJSONData.properties.shape === GEOJSONShapeType.CIRCLE) {
          (additionalProperties as UpdateRadialGeofeatureData['properties']).lat =
            geofeature.geoJSONData.properties.center.lat;
          (additionalProperties as UpdateRadialGeofeatureData['properties']).long =
            geofeature.geoJSONData.properties.center.lng;
        }

        return {
          ...geofeature.geoJSONData,
          id: geofeature.id,
          // @ts-expect-error properties are alligned with the GEOJSONData
          properties: {
            ...geofeature.geoJSONData.properties,
            ...additionalProperties,
            ...properties,
          },
        } satisfies UpdateGeofeaturesData[0] as UpdateGeofeaturesData[0];
      });

      return updatedGeofeatures;
    },
    [selectedGeofeatures]
  );

  const handleGeofeaturesFolderChange = useCallback(
    async (folderId: number) => {
      const itemsToChange = updateGeofeaturesProperties({ folder: folderId });

      unwrapResult(await dispatch(updateGeofeaturesData(itemsToChange)));

      setIsBulkActionsOpen(false);
      setSelectedGeofeatures([]);
    },
    [dispatch, setSelectedGeofeatures, updateGeofeaturesProperties]
  );

  const bulkActionsList = useMemo<Array<DropdownItem>>(
    () => [
      {
        label: 'Move to',
        nestedItems: geofeaturesFolders.map(folder => ({
          id: folder.id,
          label: <span>{folder.name}</span>,
          onClick: () => handleGeofeaturesFolderChange(folder.id),
        })),
        maxWidth: '360px',
      },
      {
        label: 'Change color',
        onClick: () => setIsChangeColorModalVisible(true),
      },
      {
        label: 'Change icon',
        onClick: () => setIsChangeIconModalVisible(true),
      },
      {
        label: 'Export',
        onClick: () => setIsExportModalVisible(true),
      },
      {
        label: 'Delete',
        onClick: () => setIsDeleteConfirmModalVisible(true),
      },
    ],
    [geofeaturesFolders, handleGeofeaturesFolderChange]
  );

  const selectedGeofeaturesGeoJSONsForExport = useMemo<string>(() => {
    const geojsons: Array<GEOJSONData> = [];

    selectedGeofeaturesIds.forEach(id => {
      const geofeature = geofeaturesList.find(item => item.id === id);

      if (geofeature) {
        geojsons.push(geofeature.geoJSONData);
      }
    });

    return createGeoJSONStringForExport(geojsons);
  }, [geofeaturesList, selectedGeofeaturesIds]);

  const handleColorChange = useCallback(
    async (color: string) => {
      const itemsToChange = updateGeofeaturesProperties({ color });

      unwrapResult(await dispatch(updateGeofeaturesData(itemsToChange)));

      setIsChangeColorModalVisible(false);
    },
    [dispatch, updateGeofeaturesProperties]
  );

  const handleIconChange = useCallback(
    async (icon: IconPickerIcons) => {
      const itemsToChange = updateGeofeaturesProperties({ icon });

      unwrapResult(await dispatch(updateGeofeaturesData(itemsToChange)));

      setIsChangeIconModalVisible(false);
    },
    [dispatch, updateGeofeaturesProperties]
  );

  const handleChangeColorModalClose = useCallback(() => {
    setIsChangeColorModalVisible(false);
  }, []);
  const handleChangeIconModalClose = useCallback(() => {
    setIsChangeIconModalVisible(false);
  }, []);
  const handleExportModalClose = useCallback(() => {
    setIsExportModalVisible(false);
  }, []);
  const handleDeleteConfirmModalClose = useCallback(() => {
    setIsDeleteConfirmModalVisible(false);
  }, []);

  const handleDeleteGeofeatures = useCallback(async () => {
    unwrapResult(await dispatch(deleteGeofeatures(selectedGeofeaturesIds)));

    handleDeleteConfirmModalClose();
  }, [dispatch, handleDeleteConfirmModalClose, selectedGeofeaturesIds]);

  return (
    <>
      <Container>
        <Dropdown
          control={
            <BulkActionsButton>
              Bulk actions
              <Icon
                name="ChevronDown"
                size={12}
              />
            </BulkActionsButton>
          }
          isOpen={isBulkActionsOpen}
          menuItems={bulkActionsList}
          setIsOpen={setIsBulkActionsOpen}
          offsetY={42}
        />
        <DeselectButton
          type="button"
          onClick={onSelectAll}
        >
          Select all
        </DeselectButton>
        <DeselectButton
          type="button"
          onClick={onDeselectAll}
        >
          Deselect all
        </DeselectButton>
      </Container>
      <ColorsModal
        isOpen={isChangeColorModalVisible}
        color={colorPickerColors.color1}
        onColorApply={handleColorChange}
        onClose={handleChangeColorModalClose}
      />
      <IconsModal
        isOpen={isChangeIconModalVisible}
        icon="Flag"
        onIconApply={handleIconChange}
        onClose={handleChangeIconModalClose}
      />
      <ExportGeojsonModal
        isOpen={isExportModalVisible}
        onClose={handleExportModalClose}
        geoJSON={selectedGeofeaturesGeoJSONsForExport}
      />
      <DeleteModal
        isOpen={isDeleteConfirmModalVisible}
        onClose={handleDeleteConfirmModalClose}
        onDelete={handleDeleteGeofeatures}
        title="Delete geofeatures"
        content={
          <DeleteModalContent>
            Are you sure to delete the selected {selectedGeofeaturesIds.length} geofeature
            {selectedGeofeaturesIds.length > 1 ? 's' : ''}? This action cannot be undone.
          </DeleteModalContent>
        }
        confirmButtonText="DELETE GEOFEATURES"
      />
    </>
  );
};

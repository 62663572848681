import { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { Paragraph } from '@/core/components/Typography';
import { Badge, BadgeSize } from '@/core/components/Badge';
import { getImpactName, mapCategories } from '@/core/constants/reports';

import {
  CategoriesContainer,
  ReportMeta,
  ReportMetaColumn,
  ReportMetaRow,
} from './MetaSection.styles';
import { MetaSectionProps } from './MetaSection.types';

export const MetaSection = ({
  country,
  locationAddress,
  eventLocalTime,
  eventDate,
  categories,
  postType,
  impact,
  dead,
  wounded,
  hostages,
  missing,
  arrested,
}: MetaSectionProps) => {
  const { colors } = useTheme();

  const victimsText = useMemo(() => {
    const victimsData = {
      dead,
      wounded,
      hostages,
      missing,
      arrested,
    };

    return Object.entries(victimsData)
      .filter(([_, count]) => count !== 0)
      .map(([category, count]) => `${count} ${category}`)
      .join(', ');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dead, wounded, hostages, missing, arrested]);

  return (
    <ReportMeta>
      <ReportMetaColumn>
        <ReportMetaRow>
          <Paragraph
            type="normal"
            color={colors.secondaryText}
          >
            Country:
          </Paragraph>
          <Paragraph type="bold">{country}</Paragraph>
        </ReportMetaRow>
        <ReportMetaRow>
          <Paragraph
            type="normal"
            color={colors.secondaryText}
          >
            Location:
          </Paragraph>
          <Paragraph type="bold">{locationAddress}</Paragraph>
        </ReportMetaRow>
        {eventDate && (
          <ReportMetaRow>
            <Paragraph
              type="normal"
              color={colors.secondaryText}
            >
              Event Date:
            </Paragraph>
            <Paragraph type="bold">{eventDate}</Paragraph>
          </ReportMetaRow>
        )}
        {eventLocalTime && (
          <ReportMetaRow>
            <Paragraph
              type="normal"
              color={colors.secondaryText}
            >
              Local Time:
            </Paragraph>
            <Paragraph type="bold">{eventLocalTime} (local time)</Paragraph>
          </ReportMetaRow>
        )}
      </ReportMetaColumn>
      <ReportMetaColumn>
        <ReportMetaRow>
          <Paragraph
            type="normal"
            color={colors.secondaryText}
          >
            Category:
          </Paragraph>
          <CategoriesContainer>
            {categories.map(category => {
              const mappedCategory = mapCategories(category, 11, colors.categoryIcon);

              if (!mappedCategory.icon) {
                return null;
              }

              return (
                <Badge
                  key={category}
                  text={category}
                  backgroundColor={mappedCategory.color}
                  leadingIcon={mappedCategory.icon}
                  size={BadgeSize.MEDIUM}
                />
              );
            })}
          </CategoriesContainer>
        </ReportMetaRow>
        <ReportMetaRow>
          <Paragraph
            type="normal"
            color={colors.secondaryText}
          >
            Report:
          </Paragraph>
          <Paragraph type="bold">{postType}</Paragraph>
        </ReportMetaRow>
        <ReportMetaRow>
          <Paragraph
            type="normal"
            color={colors.secondaryText}
          >
            Impact:
          </Paragraph>
          <Badge
            text={getImpactName(impact)}
            color={colors.impact[impact].textColor}
            backgroundColor={colors.impact[impact].backgroundColor}
          />
        </ReportMetaRow>
        {victimsText && (
          <ReportMetaRow>
            <Paragraph
              type="normal"
              color={colors.secondaryText}
            >
              Victims:
            </Paragraph>
            <Paragraph type="bold">{victimsText}</Paragraph>
          </ReportMetaRow>
        )}
      </ReportMetaColumn>
    </ReportMeta>
  );
};

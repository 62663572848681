import { useCallback, useState } from 'react';
import { useTheme } from 'styled-components';

import { InputLabel } from '@/core/components/FormElements.styles';
import { Icon } from '@/core/components/Icons';

import { IconPickerProps } from './IconPicker.types';
import { PickedIcon, PickedIconContainer } from './IconPicker.styles';
import { IconsModal } from './components/IconsModal';

export const IconPicker = ({ icon, label, onIconChange }: IconPickerProps) => {
  const { colors } = useTheme();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onPickerClick = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const onModalClose = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  return (
    <div>
      {label && <InputLabel>{label}</InputLabel>}
      <PickedIconContainer>
        <PickedIcon
          type="button"
          onClick={onPickerClick}
        >
          <Icon
            size={20}
            name={icon}
            color={colors.secondaryText}
          />
        </PickedIcon>
      </PickedIconContainer>
      <IconsModal
        isOpen={isModalVisible}
        icon={icon}
        onIconApply={onIconChange}
        onClose={onModalClose}
      />
    </div>
  );
};

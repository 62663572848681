import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 676px;
  box-shadow: 0 4px 24px 0 #040d1c38;
  border-radius: 22px;
  padding: 32px 37px;
  background: ${({ theme }) => theme.colors.modalBackground};
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 4px;
  background: none;
  cursor: pointer;
`;

export const Header = styled.header`
  display: flex;
`;

export const ColorsList = styled.div`
  display: flex;
  gap: 1px;
`;

export const ColorButton = styled.button<{ color: string; isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border: 1px solid transparent;
  border-color: ${({ isActive, color }) => (isActive ? color : 'transparent')};
  border-radius: 6px;
  padding: 0;
  background: none;
  cursor: pointer;

  &::before {
    content: '';
    width: 29px;
    height: 29px;
    border-radius: 4px;
    background: ${({ color }) => color};
  }
`;

export const ButtonsContainer = styled.footer`
  display: flex;
  gap: 10px;
  margin-top: 70px;
`;

import { Request, request } from '@/utils/request';
import { makeServerSideRequest } from '@/utils/serverSideRequest';

import { CategoriesResponse } from './interfaces/categories';
import { CountriesResponse } from './interfaces/countries';
import { AddressDetailsResponse, AddressPredictionResponse } from './interfaces/common';

export const makeApi = (instance = request) => ({
  getCategoires: () => instance.get<CategoriesResponse>('categories/'),
  getCountries: () => instance.get<CountriesResponse>('countries/'),
  getAddressPrediction: (input: string) =>
    new Request({ useDefaultBackendUrl: false }).get<AddressPredictionResponse>(
      `/api/address-prediction`,
      { params: { input } }
    ),
  getAddressDetails: (placeId: string) =>
    new Request({ useDefaultBackendUrl: false }).get<AddressDetailsResponse>(
      `/api/address-details`,
      { params: { placeId } }
    ),
});

export const api = makeApi();
export const makeServerSideApi = (token: string) => makeApi(makeServerSideRequest(token));

import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from '@/core/interfaces/store';
import {
  AppliedFilterData,
  AppliedFilters,
  ApplyFilterPayload,
  FilterType,
} from '@/core/interfaces/filters';
import { DEFAULT_FILTERS_DATA } from '@/core/constants/filters';

export interface FiltersState {
  openedFilter: FilterType | null;
  appliedFilters: AppliedFilters;
}

const initialState: FiltersState = {
  openedFilter: null,
  appliedFilters: DEFAULT_FILTERS_DATA,
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    openFilter: (state, { payload }: PayloadAction<FilterType | null>) => {
      state.openedFilter = payload;
    },
    applyFilter: (state, { payload }: PayloadAction<ApplyFilterPayload>) => {
      state.appliedFilters[payload.filterType] = payload.filterData;
    },
    applyAllFilters: (state, { payload }: PayloadAction<AppliedFilters>) => {
      state.appliedFilters = payload;
    },
    resetFilters: state => {
      state.appliedFilters = DEFAULT_FILTERS_DATA;
    },
  },
});

export const { applyFilter, applyAllFilters, openFilter, resetFilters } = filtersSlice.actions;

const filterSelector = (state: RootState): FiltersState => state.filters;

export const getOpenedFilterSelector = createSelector(filterSelector, state => state.openedFilter);
export const isFilterOpenedSelector = createSelector(
  [filterSelector, (_state, filter: FilterType) => filter],
  (state, filter) => state.openedFilter === filter
);

export const getAppliedFilterSelector = createSelector(
  [filterSelector, (_state, filter: FilterType) => filter],
  (state, filter) => state.appliedFilters[filter] || ([] as AppliedFilterData)
);

export const getAllAppliedFiltersSelector = createSelector(
  filterSelector,
  state => state.appliedFilters
);

export default filtersSlice.reducer;

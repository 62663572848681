import { useState } from 'react';
import { useTheme } from 'styled-components';

import { Icon } from '@/core/components/Icons';

import { MenuSectionProps } from './MenuSection.types';
import { Container, FiltersWrapper, GroupNameContainer, NameButton } from './MenuSection.styles';

export const MenuSection = ({
  sectionName,
  children,
  isInitialyExpanded = false,
}: MenuSectionProps) => {
  const { colors } = useTheme();

  const [isExpanded, setIsExpanded] = useState(isInitialyExpanded);

  return (
    <Container>
      <NameButton onClick={() => setIsExpanded(prevState => !prevState)}>
        <GroupNameContainer>{sectionName}</GroupNameContainer>
        <Icon
          name={isExpanded ? 'Minus' : 'Plus'}
          size={16}
          color={colors.secondaryText}
        />
      </NameButton>
      <FiltersWrapper isExpanded={isExpanded}>{children}</FiltersWrapper>
    </Container>
  );
};

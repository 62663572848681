import { HYDRATE } from 'next-redux-wrapper';
import { diff, DiffPatcher, patch } from 'jsondiffpatch';
import { AnyAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '@/core/interfaces/store';

export const initialState = {} as RootState;

const hydrateSlice = createSlice({
  extraReducers: builder =>
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      if (state?.config?.appInitialized) return state;

      const jsondiffpatch = new DiffPatcher();
      const stateDiff = diff(state, action.payload);
      const clonedPayload = jsondiffpatch.clone(state) as typeof state;

      if (stateDiff) {
        patch(clonedPayload, stateDiff);
      }

      return {
        ...state,
        ...clonedPayload,
      };
    }),
  initialState,
  name: 'HYDRATE',
  reducers: {},
});

export default hydrateSlice.reducer;

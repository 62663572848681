import { useState, useCallback } from 'react';
import { useTheme } from 'styled-components';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { Icon } from '@/core/components/Icons/Icon';
import { Button, ButtonVariant } from '@/core/components/Button';
import { TextField } from '@/core/components/TextField';
import { Coworker, ShareInput } from '@/core/components/ShareInput';
import { InputLabel } from '@/core/components/FormElements.styles';
import { useAppDispatch } from '@/core/store/store';
import { webAppRoles } from '@/core/constants/constants';

import { FolderModalType } from '@/features/Geofeatures/components/GeofeatureFolderModal/GeofeatureFolderModal.types';
import {
  createGeofeaturesFolder,
  editGeofeaturesFolder,
} from '@/features/Geofeatures/store/actions';
import {
  GeofeatureCreateFolderErrorReponse,
  GeofeatureCreateFolderResponse,
} from '@/features/Geofeatures/interfaces';
import { isAdvancedFeaturesEnabledSelector } from '@/features/Auth/store';

import {
  FormField,
  DeleteButton,
  ButtonWrapper,
  FormContainer,
} from './GeofeatureFolderForm.styles';
import { GeofeatureFolderFormProps, FormData } from './GeofeatureFolderForm.types';

const schema = yup.object().shape({
  folderName: yup
    .string()
    .required('Folder name is required')
    .max(255, 'Focus name cannot exceed 255 characters'),
  prioritize: yup.boolean(),
  coworkers: yup.array(),
});

export const GeofeatureFolderForm = ({
  type,
  folder,
  onSubmit,
  onCancel,
  onDelete,
}: GeofeatureFolderFormProps) => {
  const { colors } = useTheme();
  const isAdvancedFeaturesEnabled = useSelector(isAdvancedFeaturesEnabledSelector);
  const [selectedCoworkers, setSelectedCoworkers] = useState<Array<Coworker>>(
    folder?.user.map(user => ({
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      id: user.id,
      photoUrl: user.profilePicture || '/assets/avatar-placeholder.png',
      role: user.groups.filter(role => webAppRoles.includes(role))[0],
    })) || []
  );
  const [selectedOrganizations, setSelectedOrganizations] = useState<Array<Coworker>>(
    folder?.organization.map(organization => ({
      email: organization.description,
      name: organization.name,
      id: organization.id,
      photoUrl: '/assets/avatar-placeholder.png',
      role: 'Organization',
    })) || []
  );

  const dispatch = useAppDispatch();

  const formMethods = useForm<FormData>({
    defaultValues: {
      folderName: type === FolderModalType.EDIT ? folder?.name : '',
      prioritize: folder?.isPrioritize || false,
      share: {
        users:
          folder?.user.map(user => ({
            email: user.email,
            name: `${user.firstName} ${user.lastName}`,
            id: user.id,
            photoUrl: user.profilePicture || '/assets/avatar-placeholder.png',
            role: user.groups.filter(role => webAppRoles.includes(role))[0],
          })) || [],
        organizations:
          folder?.organization.map(organization => ({
            email: organization.description,
            name: organization.name,
            id: organization.id,
            photoUrl: '/assets/avatar-placeholder.png',
            role: 'Organization',
          })) || [],
      },
    },
    resolver: yupResolver(schema),
  });

  const { handleSubmit, setError } = formMethods;

  const onFormSubmit = useCallback(
    async (data: FormData) => {
      try {
        let folderData: GeofeatureCreateFolderResponse | undefined = undefined;

        if (type === FolderModalType.ADD) {
          folderData = unwrapResult(
            await dispatch(
              createGeofeaturesFolder({
                name: data.folderName,
                isPrioritize: data.prioritize,
                user: selectedCoworkers.map(coworker => coworker.id),
                organization: selectedOrganizations.map(organization => organization.id),
              })
            )
          );
        } else if (type === FolderModalType.EDIT && folder?.id) {
          folderData = unwrapResult(
            await dispatch(
              editGeofeaturesFolder({
                folderId: folder?.id,
                folderData: {
                  name: data.folderName,
                  isPrioritize: data.prioritize,
                  ...(selectedCoworkers.length
                    ? { user: selectedCoworkers.map(user => user.id) }
                    : {}),
                  ...(selectedOrganizations.length
                    ? { organization: selectedOrganizations.map(organization => organization.id) }
                    : {}),
                },
              })
            )
          );
        }

        if (folderData) {
          onSubmit(folderData);
        }
      } catch (error) {
        // @ts-expect-error error is not defined
        if (error.formErrors) {
          // @ts-expect-error error is not defined
          const formErrors = error.formErrors as GeofeatureCreateFolderErrorReponse;

          if (formErrors.name) {
            setError('folderName', {
              message: formErrors.name.join(' '),
            });
          }
        }
      }
    },
    [dispatch, folder?.id, onSubmit, selectedCoworkers, selectedOrganizations, setError, type]
  );

  const handleSelectCoworker = useCallback(
    (user: Coworker) => {
      setSelectedCoworkers([...selectedCoworkers, user]);
    },
    [selectedCoworkers]
  );

  const handleSelectOrganization = useCallback(
    (organization: Coworker) => {
      setSelectedOrganizations([...selectedOrganizations, organization]);
    },
    [selectedOrganizations]
  );

  const handleRemoveSelectedCoworker = useCallback(
    (coworkerId: string) => {
      setSelectedCoworkers(selectedCoworkers.filter(coworker => coworker.id !== coworkerId));
    },
    [selectedCoworkers]
  );

  const handleRemoveSelectedOrganization = useCallback(
    (organizationId: string) => {
      setSelectedOrganizations(
        selectedOrganizations.filter(organization => organization.id !== organizationId)
      );
    },
    [selectedOrganizations]
  );

  return (
    <FormProvider {...formMethods}>
      <FormContainer onSubmit={handleSubmit(onFormSubmit)}>
        <div>
          <FormField>
            <TextField
              name="folderName"
              placeholder="Folder 1"
              autoComplete="off"
              label="Folder Name"
            />
          </FormField>
          {isAdvancedFeaturesEnabled && (
            <FormField>
              <InputLabel>Share folder</InputLabel>
              <ShareInput
                onUserSelect={handleSelectCoworker}
                onOrganizationSelect={handleSelectOrganization}
                selectedCoworkers={selectedCoworkers}
                selectedOrganizations={selectedOrganizations}
                handleRemoveSelectedCoworker={handleRemoveSelectedCoworker}
                handleRemoveSelectedOrganization={handleRemoveSelectedOrganization}
              />
            </FormField>
          )}
        </div>

        {type === FolderModalType.EDIT && (
          <DeleteButton
            type="button"
            onClick={onDelete}
          >
            <Icon
              name="Trash"
              size={12}
              color={colors.warning}
            />
            Delete folder
          </DeleteButton>
        )}
        <ButtonWrapper>
          <Button type="submit">SAVE CHANGES</Button>
          <Button
            variant={ButtonVariant.TERTIARY}
            onClick={onCancel}
          >
            CANCEL
          </Button>
        </ButtonWrapper>
      </FormContainer>
    </FormProvider>
  );
};

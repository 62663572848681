import { useTheme } from 'styled-components';

import { Avatar } from '@/core/components/Layout/Avatar';
import { Paragraph } from '@/core/components/Typography';

import { AddButton, RoleBadge, Suggestion, SuggestionContent } from './SuggestionItem.styles';
import { SuggestionItemProps } from './SuggestionItem.types';

export const SuggestionItem = ({
  id,
  email,
  name,
  photoUrl,
  role,
  onSelectSuggestion,
}: SuggestionItemProps) => {
  const { colors } = useTheme();

  return (
    <Suggestion>
      <SuggestionContent>
        <Avatar src={photoUrl} />
        <Paragraph
          type="normal"
          color={colors.secondaryText}
        >
          {name}
        </Paragraph>
        <Paragraph
          type="small-normal"
          color={colors.secondaryText}
        >
          {email}
        </Paragraph>
        <RoleBadge>{role}</RoleBadge>
      </SuggestionContent>
      <AddButton
        onClick={() => onSelectSuggestion(id)}
        type="button"
      >
        Add
      </AddButton>
    </Suggestion>
  );
};

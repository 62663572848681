import styled, { css } from 'styled-components';

export const SuggestionsList = styled.ul<{
  offsetX?: number;
  offsetY?: number;
  $width?: number;
}>`
  position: absolute;
  top: ${({ offsetY }) => (offsetY ? `${offsetY}px` : 'calc(100% + 4px)')};
  left: ${({ offsetX }) => (offsetX ? `${offsetX}px` : 'auto')};
  z-index: 100;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
  max-height: 250px;
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.21);
  margin: 0;
  border-radius: 6px;
  padding: 10px 0;
  background-color: ${({ theme }) => theme.colors.background.light};
  list-style: none;
`;

export const Suggestion = styled.li<{ withSeparator?: boolean }>`
  width: 100%;
  padding: 10px 30px;
  cursor: pointer;
  ${({ withSeparator, theme }) =>
    withSeparator &&
    css`
      border-top: 1px solid ${theme.colors.border};
    `}
`;

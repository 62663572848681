import styled from 'styled-components';

import { getVariant } from '@/themes/typographyUtilis';

export const FolderModalWrapper = styled.div`
  overflow: auto;
  scrollbar-width: thin;
  width: 720px;
  max-height: 100vh;
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.21);
  border-radius: 22px;
  padding: 40px;
  background-color: ${({ theme }) => theme.colors.modalBackground};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
  color: ${({ theme }) => theme.colors.primaryText};
  ${getVariant({ variant: 'h2' })}
`;

export const CloseIconWrapper = styled.button`
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
`;

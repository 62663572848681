import { useCallback, useMemo, useState } from 'react';

import { Modal } from '@/core/components/Modal';
import { Heading } from '@/core/components/Typography';
import { Icon } from '@/core/components/Icons';
import { Button, ButtonVariant } from '@/core/components/Button';
import { colorPickerColors } from '@/core/constants/colors';

import { ColorsModalProps } from './ColorsModal.types';
import {
  ButtonsContainer,
  ColorsList,
  CloseButton,
  Container,
  ColorButton,
} from './ColorsModal.styles';

export const ColorsModal = ({ isOpen, color, onColorApply, onClose }: ColorsModalProps) => {
  const [pickedColor, setPickedColor] = useState(color);

  const colorsToPick = useMemo(() => Object.values(colorPickerColors), []);

  const onColorPick = useCallback((colorToPick: string) => {
    setPickedColor(colorToPick);
  }, []);

  const onApplyClick = useCallback(() => {
    onColorApply(pickedColor);
    onClose();
  }, [onClose, onColorApply, pickedColor]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <Container>
        <CloseButton onClick={onClose}>
          <Icon
            size={19}
            name="Close"
          />
        </CloseButton>
        <Heading type="h3">Change color</Heading>
        <ColorsList>
          {colorsToPick.map(colorToPick => (
            <ColorButton
              key={colorToPick}
              isActive={colorToPick === pickedColor}
              color={colorToPick}
              onClick={() => onColorPick(colorToPick)}
            />
          ))}
        </ColorsList>
        <ButtonsContainer>
          <Button onClick={onApplyClick}>Apply changes</Button>
          <Button
            variant={ButtonVariant.TERTIARY}
            onClick={onClose}
          >
            Cancel
          </Button>
        </ButtonsContainer>
      </Container>
    </Modal>
  );
};

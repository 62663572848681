import { MapRef } from 'react-map-gl/maplibre';

import { PolygonGEOJSONData } from '@/core/interfaces/geojsons';

export const getPolygonDataName = (dataId: string) => `polygonData-${dataId}`;
export const getPolygonFillLayerName = (dataId: string) => `polygon-fill-${dataId}`;
export const getPolygonStrokeLayerName = (dataId: string) => `polygon-stroke-${dataId}`;

export const drawPolygon = (
  mapRef: MapRef | null,
  {
    color,
    polygonData,
    polygonDataName,
    polygonFillLayerName,
    polygonStrokeLayerName,
  }: {
    polygonData: PolygonGEOJSONData;
    color: string;
    polygonDataName: string;
    polygonFillLayerName: string;
    polygonStrokeLayerName: string;
  }
) => {
  if (!mapRef) {
    return;
  }

  const map = mapRef.getMap();

  const polygonSource = map.getSource(polygonDataName);
  const polygonFillLayer = map.getLayer(polygonFillLayerName);
  const polygonStrokeLayer = map.getLayer(polygonStrokeLayerName);

  const data = {
    type: polygonData.type,
    geometry: {
      ...polygonData.geometry,
    },
  };

  if (polygonSource) {
    // @ts-expect-error - setData is not available in the types
    polygonSource.setData(data);
  } else {
    map.addSource(polygonDataName, {
      type: 'geojson',
      data,
    });
  }

  if (polygonFillLayer) {
    map.setPaintProperty(polygonFillLayerName, 'fill-color', color);
  } else {
    map.addLayer({
      id: polygonFillLayerName,
      type: 'fill',
      source: polygonDataName,
      paint: {
        'fill-color': color,
        'fill-opacity': 0.2,
      },
    });
  }

  if (polygonStrokeLayer) {
    map.setPaintProperty(polygonStrokeLayerName, 'line-color', color);
  } else {
    map.addLayer({
      id: polygonStrokeLayerName,
      type: 'line',
      source: polygonDataName,
      paint: {
        'line-color': color,
        'line-width': 2,
      },
    });
  }
};

export const removePolygonGeofeature = (
  mapRef: MapRef | null,
  {
    polygonDataName,
    polygonFillLayerName,
    polygonStrokeLayerName,
  }: {
    polygonDataName: string;
    polygonFillLayerName: string;
    polygonStrokeLayerName: string;
  }
) => {
  if (!mapRef) {
    return;
  }

  const map = mapRef.getMap();

  const dataNamesToRemove = [polygonDataName, polygonFillLayerName, polygonStrokeLayerName];

  dataNamesToRemove.forEach(dataName => {
    // We have to check style because `getLayer` will throw an error on page change because `style` is undefined
    if (dataName && map.style && map.getLayer(dataName)) {
      map.removeLayer(dataName);
    }
  });

  dataNamesToRemove.forEach(dataName => {
    // We have to check style because `getSource` will throw an error on page change because `style` is undefined
    if (dataName && map.style && map.getSource(dataName)) {
      map.removeSource(dataName);
    }
  });
};

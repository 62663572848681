import styled from 'styled-components';

export const DatepickerWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  margin-top: 32px;
`;

import { useMemo } from 'react';

import { StyledInput } from '@/core/components/Input/Input.styles';

import { DatepickerCustomInputProps } from './DatepickerCustomInput.types';

export const DatepickerCustomInput = ({ dateValue, onClick }: DatepickerCustomInputProps) => {
  const customInputStyles = useMemo(
    () => ({
      width: '126px',
      cursor: 'pointer',
    }),
    []
  );

  return (
    <StyledInput
      type="text"
      centered
      style={customInputStyles}
      readOnly
      value={dateValue}
      onClick={onClick}
    />
  );
};

DatepickerCustomInput.displayName = 'DatepickerCustomInput';

import styled from 'styled-components';

import { getVariant } from '@/themes/typographyUtilis';

export const NewGeofenceButtonContainer = styled.div`
  margin-top: 18px;
`;

export const ImportButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  border: 1px dashed ${({ theme }) => theme.colors.border};
  border-radius: 6px;
  padding: 15px 25px;
  background: none;
  color: ${({ theme }) => theme.colors.secondaryText};
  ${getVariant({ variant: 'sidebarTwo' })}
  text-transform: uppercase;
  cursor: pointer;
`;

import ReactModal from 'react-modal';

import { ModalStyle, ModalWrapper, StyledOverlay } from './Modal.styles';
import { ModalProps } from './Modal.types';

export const Modal = ({
  children,
  fullWidth,
  isOpen,
  overlayStyle,
  overlayPosition,
  onClose,
  height,
  zIndex = 1000,
  matchContentSize = false,
  positionX = 'center',
  positionY = 'center',
  ...rest
}: ModalProps) => {
  return (
    <ReactModal
      ariaHideApp={false}
      className="modal"
      closeTimeoutMS={350}
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName="modal-overlay"
      contentElement={(props, content) => (
        <ModalWrapper
          positionY={positionY}
          positionX={positionX}
        >
          <ModalStyle
            fullWidth={fullWidth}
            height={height}
            {...props}
          >
            {content}
          </ModalStyle>
        </ModalWrapper>
      )}
      overlayElement={(props, contentElement) => (
        <StyledOverlay
          {...props}
          overlayStyle={overlayStyle}
          matchContentSize={matchContentSize}
          overlayPosition={overlayPosition}
          zIndex={zIndex}
        >
          {contentElement}
        </StyledOverlay>
      )}
      {...rest}
    >
      {children}
    </ReactModal>
  );
};

import { useMemo } from 'react';
import { useTheme } from 'styled-components';

export const polygonFillPropertyName = 'geofeaturePolygonFillColorProperty';
export const polygonStrokePropertyName = 'geofeaturePolygonStrokeColorProperty';

export const usePolygonDefaultStyles = () => {
  const { colors } = useTheme();

  const polygonDefaultStyles = useMemo(
    () => [
      {
        id: 'gl-draw-polygon-fill-inactive',
        type: 'fill',
        filter: [
          'all',
          ['==', 'active', 'false'],
          ['==', '$type', 'Polygon'],
          ['!=', 'mode', 'static'],
        ],
        paint: {
          'fill-color': {
            type: 'identity',
            property: `user_${polygonFillPropertyName}`,
          },
          'fill-opacity': 0.4,
        },
      },
      {
        id: 'gl-draw-polygon-fill-active',
        type: 'fill',
        filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
        paint: {
          'fill-color': {
            type: 'identity',
            property: `user_${polygonFillPropertyName}`,
          },
          'fill-opacity': 0.12,
        },
      },
      {
        id: 'gl-draw-polygon-midpoint',
        type: 'circle',
        filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
        paint: {
          'circle-radius': 3,
          'circle-color': '#fbb03b',
        },
      },
      {
        id: 'gl-draw-polygon-stroke-inactive',
        type: 'line',
        filter: [
          'all',
          ['==', 'active', 'false'],
          ['==', '$type', 'Polygon'],
          ['!=', 'mode', 'static'],
        ],
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
        },
        paint: {
          'line-color': colors.geofenceDrawing.editStroke,
          'line-width': 2,
        },
      },
      {
        id: 'gl-draw-polygon-stroke-active',
        type: 'line',
        filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
        },
        paint: {
          'line-color': colors.geofenceDrawing.editStroke,
          'line-dasharray': [0.2, 2],
          'line-width': 2,
        },
      },
      {
        id: 'gl-draw-line-inactive',
        type: 'line',
        filter: [
          'all',
          ['==', 'active', 'false'],
          ['==', '$type', 'LineString'],
          ['!=', 'mode', 'static'],
        ],
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
        },
        paint: {
          'line-color': '#3bb2d0',
          'line-width': 2,
        },
      },
      {
        id: 'gl-draw-line-active',
        type: 'line',
        filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
        },
        paint: {
          'line-color': colors.geofenceDrawing.editStroke,
          'line-dasharray': [0.2, 2],
          'line-width': 2,
        },
      },
      {
        id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
        type: 'circle',
        filter: [
          'all',
          ['==', 'meta', 'vertex'],
          ['==', '$type', 'Point'],
          ['!=', 'mode', 'static'],
        ],
        paint: {
          'circle-radius': 10,
          'circle-color': colors.geofenceDrawing.editVertexBackground,
          'circle-stroke-color': '#80CEDD',
          'circle-stroke-width': 10,
          'circle-stroke-opacity': 0.19,
        },
      },
      {
        id: 'gl-draw-polygon-and-line-vertex-inactive',
        type: 'circle',
        filter: [
          'all',
          ['==', 'meta', 'vertex'],
          ['==', '$type', 'Point'],
          ['!=', 'mode', 'static'],
        ],
        paint: {
          'circle-radius': 4,
          'circle-color': '#fff',
        },
      },
      {
        id: 'gl-draw-point-point-stroke-inactive',
        type: 'circle',
        filter: [
          'all',
          ['==', 'active', 'false'],
          ['==', '$type', 'Point'],
          ['==', 'meta', 'feature'],
          ['!=', 'mode', 'static'],
        ],
        paint: {
          'circle-radius': 5,
          'circle-opacity': 1,
          'circle-color': '#fff',
        },
      },
      {
        id: 'gl-draw-point-inactive',
        type: 'circle',
        filter: [
          'all',
          ['==', 'active', 'false'],
          ['==', '$type', 'Point'],
          ['==', 'meta', 'feature'],
          ['!=', 'mode', 'static'],
        ],
        paint: {
          'circle-radius': 3,
          'circle-color': '#3bb2d0',
        },
      },
      {
        id: 'gl-draw-point-stroke-active',
        type: 'circle',
        filter: [
          'all',
          ['==', '$type', 'Point'],
          ['==', 'active', 'true'],
          ['!=', 'meta', 'midpoint'],
        ],
        paint: {
          'circle-radius': 10,
          'circle-color': colors.geofenceDrawing.editVertexBackground,
          'circle-stroke-color': '#80CEDD',
          'circle-stroke-width': 10,
          'circle-stroke-opacity': 0.19,
        },
      },
      {
        id: 'gl-draw-point-active',
        type: 'circle',
        filter: [
          'all',
          ['==', '$type', 'Point'],
          ['!=', 'meta', 'midpoint'],
          ['==', 'active', 'true'],
        ],
        paint: {
          'circle-radius': 4,
          'circle-color': '#fff',
        },
      },
      {
        id: 'gl-draw-polygon-fill-static',
        type: 'fill',
        filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
        paint: {
          'fill-color': {
            type: 'identity',
            property: `user_${polygonFillPropertyName}`,
          },
          'fill-outline-color': '#404040',
          'fill-opacity': 0.1,
        },
      },
      {
        id: 'gl-draw-polygon-stroke-static',
        type: 'line',
        filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
        },
        paint: {
          'line-color': {
            type: 'identity',
            property: `user_${polygonStrokePropertyName}`,
          },
          'line-width': 2,
        },
      },
      {
        id: 'gl-draw-line-static',
        type: 'line',
        filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
        },
        paint: {
          'line-color': '#404040',
          'line-width': 2,
        },
      },
      {
        id: 'gl-draw-point-static',
        type: 'circle',
        filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
        paint: {
          'circle-radius': 5,
          'circle-color': '#404040',
        },
      },
    ],
    [colors.geofenceDrawing.editStroke, colors.geofenceDrawing.editVertexBackground]
  );

  return polygonDefaultStyles;
};
// https://jsfiddle.net/rosLzqec/50/

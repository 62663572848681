import { GeofenceType } from '@/core/components/MapFilters/LocationFilter/components/CreateGeofenceView';
import { LocationSubViewType } from '@/core/components/MapFilters/LocationFilter/LocationFilter.types';
import { AppliedFilters, FilterType } from '@/core/interfaces/filters';
import { timeframeRadioButtonData } from '@/core/components/MapFilters/TimeframeFilter/timeframeRadioButtonData';
import { MultiButtonItem } from '@/core/components/MultiButton';
import { ReportImpact } from '@/core/interfaces/common';

export const geofenceTypeToLocationSubViewType: { [key in GeofenceType]: LocationSubViewType } = {
  [GeofenceType.RADIAL]: LocationSubViewType.GEOFENCE_CREATE_RADIAL,
  [GeofenceType.POLYGON]: LocationSubViewType.GEOFENCE_CREATE_POLYGON,
  [GeofenceType.LINE]: LocationSubViewType.GEOFENCE_CREATE_LINE,
};

const defaultTimeframe = timeframeRadioButtonData[0];

export const DEFAULT_FILTERS_DATA: AppliedFilters = {
  [FilterType.TIMEFRAME]: [
    {
      label: defaultTimeframe.name as string,
      value: String(defaultTimeframe.value),
    },
  ],
};

export const impactFilterOptions: Array<MultiButtonItem<ReportImpact>> = [
  {
    label: 'Low',
    value: ReportImpact.LOW,
  },
  {
    label: 'Medium',
    value: ReportImpact.MEDIUM,
  },
  {
    label: 'High',
    value: ReportImpact.HIGH,
  },
  {
    label: 'Extreme',
    value: ReportImpact.EXTREME,
  },
];

export const impactFilterOptionsG4S: Array<MultiButtonItem<ReportImpact>> = [
  {
    label: 'Low',
    value: ReportImpact.LOW,
  },
  {
    label: 'Moderate',
    value: ReportImpact.MEDIUM,
  },
  {
    label: 'High',
    value: ReportImpact.HIGH,
  },
  {
    label: 'Very high',
    value: ReportImpact.EXTREME,
  },
];

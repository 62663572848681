import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import assignIn from 'lodash.assignin';

import { TextBodyBold } from '@/core/components/Typography/Typography.styles';
import {
  CategoryFilterCheckboxesWrapper,
  CategoryFilterContentContainer,
  CategoryFilterIconCircle,
  CategoryFilterIconWrapper,
  CategoryFilterSectionWrapper,
  CategoryFilterSubCategoryList,
} from '@/core/components/MapFilters/CategoryFilter/CategoryFilter.styles';
import { mapCategories } from '@/core/constants/reports';
import { ReportCategory } from '@/core/interfaces/common';
import { Checkbox } from '@/core/components/Checkbox';
import {
  CategoryFilterItem,
  CategoryFilters,
} from '@/core/components/MapFilters/CategoryFilter/CategoryFilter.types';
import { FilterContainer } from '@/core/components/FilterContainer';
import {
  applyFilter,
  getAppliedFilterSelector,
  isFilterOpenedSelector,
} from '@/core/store/reducers/filtersSlice';
import { AppliedFilterData, AppliedFilterDataItem, FilterType } from '@/core/interfaces/filters';
import { useAppDispatch } from '@/core/store/store';
import { getCategoriesSelector } from '@/core/store/reducers/config';

import { transformCategoriesForFilters } from '@/utils/helpers';

import { categoryColors } from '@/themes/main';

const iconsMap = {
  Cybersecurity: ReportCategory.DATA_BREACH,
  Economy: ReportCategory.ECONOMIC_TREND,
  'Natural disaster': ReportCategory.EARTHQUAKE,
  Politics: ReportCategory.DIPLOMACY,
  Safety: ReportCategory.MIGRATION,
  Sector: ReportCategory.ELECTRICITY,
  Security: ReportCategory.CIVIL_UNREST,
  Travel: ReportCategory.TRAVEL,
};

export const CategoryFilter = () => {
  const categoryFilters = useSelector(getCategoriesSelector);

  const dispatch = useAppDispatch();
  const isFilterVisible = useSelector(state => isFilterOpenedSelector(state, FilterType.CATEGORY));
  const appliedFilters = useSelector(state => getAppliedFilterSelector(state, FilterType.CATEGORY));

  const allAvailableCategories = useMemo(() => {
    const categoriesList = new Set<string>();

    categoryFilters.forEach(category => {
      categoriesList.add(category.mainCategory);
    });

    return Array.from(categoriesList).sort();
  }, [categoryFilters]);

  const [filters, setFilters] = useState<CategoryFilters | null>(null);

  const { colors } = useTheme();

  const setAppliedFilters = (filtersToApply: AppliedFilterData) => {
    dispatch(
      applyFilter({
        filterType: FilterType.CATEGORY,
        filterData: filtersToApply,
      })
    );
  };

  const revertFiltersToPreviousState = () => {
    setFilters(prevFilters => {
      if (!prevFilters) {
        return prevFilters;
      }

      const updatedFilters = { ...prevFilters };

      Object.keys(updatedFilters).forEach(category => {
        updatedFilters[category] = updatedFilters[category].map(item => {
          const isChecked = appliedFilters.some(filterItem => filterItem.value === item.value);

          return {
            ...item,
            isChecked,
          };
        });
      });

      return updatedFilters;
    });
  };

  const updateFilters = (categoryName: string, value: number | string) => {
    if (!filters) {
      return filters;
    }

    return {
      ...filters,
      [categoryName]: filters[categoryName].map(item => {
        if (item.value === value) {
          return {
            ...item,
            isChecked: !item.isChecked,
          };
        }

        return item;
      }),
    };
  };

  const handleAllChange = (event: ChangeEvent<HTMLInputElement>, categoryName: string) => {
    if (!filters) {
      return;
    }

    const updatedFilters = {
      ...filters,
      [categoryName]: filters[categoryName].map(item => ({
        ...item,
        isChecked: event.target.checked,
      })),
    };

    setFilters(updatedFilters);
  };

  const handleIndividualChange = (value: number, categoryName: string) => {
    const updatedFilters = updateFilters(categoryName, value);

    setFilters(updatedFilters);
  };

  const getCheckedFilters = (filtersToApply: CategoryFilters): AppliedFilterData => {
    return Object.values(filtersToApply).flatMap(categoryItems =>
      Array.isArray(categoryItems)
        ? categoryItems
            .filter(item => item.isChecked)
            .map(item => ({
              label: item.label,
              value: item.value,
            }))
        : []
    );
  };

  const getCategoryName = (item: AppliedFilterDataItem) => {
    if (!filters) {
      return undefined;
    }

    return allAvailableCategories.find(category =>
      filters[category].some(filterItem => filterItem.value === item.value)
    );
  };

  const removeSelectedFilterFromList = (item: AppliedFilterDataItem) => {
    const categoryName = getCategoryName(item);

    if (!categoryName) {
      return;
    }

    const updatedFilters = updateFilters(categoryName, item.value);

    if (!updatedFilters) {
      return;
    }

    setFilters(updatedFilters);
    setAppliedFilters(getCheckedFilters(updatedFilters));
  };

  const applyFilters = () => {
    if (!filters) {
      return;
    }

    setFilters(filters);
    setAppliedFilters(getCheckedFilters(filters));
  };

  const checkAppliedFilters = () => {
    if (!filters) {
      return;
    }

    const filtersToCheck: { [key: string]: Array<CategoryFilterItem> } = assignIn({}, filters);

    appliedFilters.forEach(item => {
      Object.entries(filters).forEach(([category, categoryItems]) => {
        const categoryItemIndex = categoryItems.findIndex(
          categoryItem => categoryItem.value === item.value
        );

        if (categoryItemIndex > -1) {
          filtersToCheck[category] = [
            ...filtersToCheck[category].slice(0, categoryItemIndex),
            {
              ...filtersToCheck[category][categoryItemIndex],
              isChecked: true,
            },
            ...filtersToCheck[category].slice(categoryItemIndex + 1),
          ];
        }
      });
    });

    const newFiltersToCheck = assignIn({}, filtersToCheck);

    setFilters(newFiltersToCheck);
  };

  useEffect(() => {
    if (!isFilterVisible) {
      revertFiltersToPreviousState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterVisible]);

  useEffect(() => {
    if (categoryFilters.length) {
      const transformedCategories = transformCategoriesForFilters(categoryFilters);

      setFilters(transformedCategories);
    }
  }, [categoryFilters]);

  useEffect(() => {
    if (categoryFilters.length) {
      const transformedCategories = transformCategoriesForFilters(categoryFilters);

      if (appliedFilters.length === 0) {
        setFilters(transformedCategories);
      } else if (filters) {
        checkAppliedFilters();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilters]);

  return (
    <FilterContainer
      filterType={FilterType.CATEGORY}
      filterName="Category"
      onFiltersApply={applyFilters}
      onAppliedFilterRemove={removeSelectedFilterFromList}
    >
      <CategoryFilterContentContainer>
        {filters &&
          allAvailableCategories.map(category => {
            const categoryIcon = mapCategories(
              iconsMap[category as keyof typeof iconsMap],
              16,
              colors.categoryIcon
            );
            const categoryColor = colors.category[category as keyof typeof categoryColors];
            const initialCategoryItem = filters[category];
            const categoryItem = initialCategoryItem.sort((a, b) => a.label.localeCompare(b.label));

            const isAllChecked = (): boolean => categoryItem.every(item => item.isChecked);

            const selectAllCheckbox = {
              [category]: {
                id: 0,
                isChecked: isAllChecked(),
                label: 'All',
                value: `${category}-all`,
              },
            };

            return (
              <CategoryFilterSubCategoryList key={category}>
                <CategoryFilterSectionWrapper>
                  <CategoryFilterIconCircle backgroundColor={categoryColor}>
                    <CategoryFilterIconWrapper>{categoryIcon.icon}</CategoryFilterIconWrapper>
                  </CategoryFilterIconCircle>
                  <TextBodyBold style={{ textTransform: 'uppercase' }}>{category}</TextBodyBold>
                </CategoryFilterSectionWrapper>
                <CategoryFilterCheckboxesWrapper>
                  <Checkbox
                    label={selectAllCheckbox[category].label}
                    value={selectAllCheckbox[category].value}
                    isChecked={selectAllCheckbox[category].isChecked}
                    onChange={event => handleAllChange(event, category)}
                  />
                  {categoryItem.map(item => {
                    return (
                      <Checkbox
                        key={item.id}
                        label={item.label}
                        value={item.value}
                        isChecked={item.isChecked}
                        onChange={() => handleIndividualChange(item.value, category)}
                      />
                    );
                  })}
                </CategoryFilterCheckboxesWrapper>
              </CategoryFilterSubCategoryList>
            );
          })}
      </CategoryFilterContentContainer>
    </FilterContainer>
  );
};

import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from '@/core/interfaces/common';

import { AuthState, User } from '@/features/Auth/interfaces';
import { getUser, updateUser } from '@/features/Auth/store';

import { getActionPrefix } from '@/utils/helpers';

const actionPrefix = getActionPrefix('auth');

export const initialState: AuthState = {
  hydrateUserStatus: RequestStatus.IDLE,
  user: null,
};

const authSlice = createSlice({
  extraReducers: (builder: ActionReducerMapBuilder<AuthState>) => {
    builder
      .addCase(getUser.pending, state => {
        state.hydrateUserStatus = RequestStatus.LOADING;
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        state.hydrateUserStatus = RequestStatus.SUCCEEDED;
        state.user = payload;
      })
      .addCase(getUser.rejected, state => {
        state.hydrateUserStatus = RequestStatus.FAILED;
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        state.user!.profilePicture = payload.profilePicture;
      });
  },
  initialState,
  name: actionPrefix,
  reducers: {
    logout: state => {
      state.user = null;
    },
    setUser: (state, { payload }: PayloadAction<User>) => {
      state.user = payload;
    },
  },
});

export const { logout, setUser } = authSlice.actions;

export default authSlice.reducer;

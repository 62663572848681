export enum DropdownValues {
  AND = 'AND',
  OR = 'OR',
  EXCL = 'EXCL',
}

export type KeywordsFilterRowProps = {
  onRemoveRow: () => void;
  onValuesChange: (inputValue: string, dropdownValue: DropdownValues) => void;
  onAddRow?: () => void;
  onDropdownValueChange: (dropdownValue: DropdownValues) => void;
  inputInitialValue?: string;
  dropdownInitialValue?: DropdownValues;
};

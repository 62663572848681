import React, { ChangeEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Input, InputProps } from '@/core/components/Input';

export type TextFieldProps = InputProps & {
  name: string;
};

const getNumberValue = (e: ChangeEvent<HTMLInputElement>) => {
  const value = e.target.value.replace(/[^0-9.,]/, '').replace(/,/g, '.');

  if (value.split('.').length > 2) {
    return value.slice(0, -1);
  }

  return value;
};

export const TextField = ({ name, type, ...props }: TextFieldProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref: _ref, onChange, ...fieldProps }, fieldState: { error } }) => (
        <div>
          <Input
            {...props}
            {...fieldProps}
            type={type}
            onChange={event => {
              if (type === 'number') {
                event.target.value = getNumberValue(event);
              }

              onChange(event);
            }}
            errorMessage={error?.message}
          />
        </div>
      )}
    />
  );
};

import React from 'react';

import { ButtonProps, ButtonSize, ButtonVariant } from './Button.types';
import { StyledButton } from './Button.styles';

export const Button = ({
  type = 'button',
  children,
  onClick,
  variant = ButtonVariant.PRIMARY,
  buttonSize = ButtonSize.MEDIUM,
  ...props
}: ButtonProps) => (
  <StyledButton
    type={type}
    onClick={onClick}
    variant={variant}
    buttonSize={buttonSize}
    {...props}
  >
    {children}
  </StyledButton>
);

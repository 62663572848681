import { GenericAbortSignal } from 'axios';

import { ReportsListData, ReportsListResponse } from '@/features/Reports/interfaces';

import { request } from '@/utils/request';
import { makeServerSideRequest } from '@/utils/serverSideRequest';

export const makeApi = (instance = request) => ({
  getHorizonReportsList: ({ page, ...data }: ReportsListData = {}, signal: GenericAbortSignal) =>
    instance.post<ReportsListResponse>('report/horizon/', data, {
      params: {
        page,
      },
      signal,
    }),
  getLookoutReportsList: ({ page, ...data }: ReportsListData = {}, signal: GenericAbortSignal) =>
    instance.post<ReportsListResponse>('report/lookout/', data, {
      params: {
        page,
      },
      signal,
    }),
});

export const api = makeApi();
export const makeServerSideApi = (token: string) => makeApi(makeServerSideRequest(token));

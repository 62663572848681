import styled from 'styled-components';

import { ModalStyleProps, OverlayStyle, OverlayPosition } from './Modal.types';

export const StyledOverlay = styled.div<{
  overlayStyle?: OverlayStyle;
  matchContentSize?: boolean;
  overlayPosition?: OverlayPosition;
  zIndex: number;
}>`
  position: fixed;
  top: ${props => props.overlayPosition?.top || '0'};
  right: ${props => props.overlayPosition?.right || 'auto'};
  bottom: ${props => props.overlayPosition?.bottom || 'auto'};
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  left: ${props => props.overlayPosition?.left || '0'};
  z-index: ${props => props.zIndex};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: baseline;
  width: ${props => (props.matchContentSize ? 'auto' : '100%')};
  height: ${props => (props.matchContentSize ? 'auto' : '100%')};
  background: ${props =>
    props.overlayStyle === OverlayStyle.BLUR ? 'rgba(0, 0, 0, 0.5)' : 'transparent'};
  transition: opacity 0.33s ease;
  ${props =>
    props.overlayStyle === OverlayStyle.BLUR
      ? `
    backdrop-filter: blur(6px);
  `
      : ''}

  &.ReactModal__Overlay--after-open {
    opacity: 1;
  }

  &.ReactModal__Overlay--before-close {
    opacity: 0;
  }
`;

export const ModalWrapper = styled.div<{ positionY?: string; positionX?: string }>`
  display: flex;
  justify-content: ${props => props.positionX};
  align-items: ${props => props.positionY};
  width: 100%;
  min-height: 100%;
`;

export const ModalStyle = styled.div<ModalStyleProps>`
  position: relative;
  height: ${props => props.height || 'undefined'};
`;

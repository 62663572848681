import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 676px;
  max-height: 90vh;
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.21);
  border-radius: 12px;
  padding: 40px;
  background-color: ${({ theme }) => theme.colors.modalBackground};
`;

export const CloseModalButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border: none;
  padding: 0;
  background: none;
  cursor: pointer;
`;

import styled, { css } from 'styled-components';

import { TextBodyBold, TextBodyNormal } from '@/core/components/Typography/Typography.styles';

export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
`;

export const TabsListContainer = styled.ul<{ withMore?: boolean }>`
  max-width: ${({ withMore }) => (withMore ? 'calc(100% - 62px)' : '100%')};
  margin: 0 -12px;
  padding: 0;
  white-space: nowrap;
`;

const activeItemElementStyles = css`
  content: '';
  position: absolute;
  right: 0;
  bottom: -10px;
  left: 0;
  height: 4px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.active};
  transition: background-color 0.25s ease;
  pointer-events: none;
`;

export const StyledTab = styled.li<{ isActive: boolean }>`
  position: relative;
  display: inline-block;
  max-width: 100%;
  padding: 0 12px 4px;
  list-style: none;
  white-space: nowrap;
  cursor: pointer;

  &::after {
    ${({ isActive }) => isActive && activeItemElementStyles}
    right: 12px;
    left: 12px;
  }
`;

export const StyledTabLabel = styled(TextBodyNormal)`
  overflow: hidden;
  color: ${({ theme }) => theme.colors.secondaryText};
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledTabBoldLabel = styled(TextBodyBold)`
  overflow: hidden;
  color: ${({ theme }) => theme.colors.primaryText};
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const MoreTabsContainer = styled.div<{ isActive: boolean }>`
  position: relative;
  height: 24px;
  margin-left: auto;

  &::after {
    ${({ isActive }) => isActive && activeItemElementStyles}
  }
`;

export const MoreTabItemlabel = styled.span<{ isActive: boolean }>`
  font-weight: ${({ isActive }) => (isActive ? '700' : '400')};
`;

import { useTheme } from 'styled-components';

import { Paragraph } from '@/core/components/Typography';

import { Suggestion, SuggestionsList } from './SearchSuggestionsList.styles';
import { SearchSuggestionsListProps, SuggestionItem } from './SearchSuggestionsList.types';

export const SearchSuggestionsList = <Item extends SuggestionItem = SuggestionItem>({
  width,
  offsetX,
  offsetY,
  suggestionsList,
  onSelectSuggestion,
}: SearchSuggestionsListProps<Item>) => {
  const { colors } = useTheme();

  return (
    <SuggestionsList
      offsetX={offsetX}
      offsetY={offsetY}
      $width={width}
    >
      {suggestionsList.map((suggestion, index) => (
        <Suggestion
          key={index}
          withSeparator={suggestion.withSeparator}
          onClick={() => onSelectSuggestion(suggestion)}
        >
          <Paragraph
            type="normal"
            color={colors.secondaryText}
          >
            {suggestion.name}
          </Paragraph>
        </Suggestion>
      ))}
    </SuggestionsList>
  );
};

import { forwardRef, useMemo, RefObject } from 'react';
import MaplibreMap, { MapRef } from 'react-map-gl/maplibre';
import { useTheme } from 'styled-components';

import 'maplibre-gl/dist/maplibre-gl.css';

import { ThemeVariant } from '@/core/interfaces/common';
import { MAP_STYLE_DARK, MAP_STYLE_LIGHT, DEFAULT_VIEWSTATE } from '@/core/constants/map';

import { MapProps } from './Map.types';

export const Map = forwardRef(
  (
    {
      viewState,
      children,
      mapStyle,
      onMapLoad,
      onMapHover,
      onMapClick,
      style = {
        width: '100%',
        height: '100%',
      },
      renderWorldCopies = true,
      hideAttributionControl = false,
    }: MapProps,
    ref
  ) => {
    const { theme } = useTheme();

    const mapStyleSource = useMemo(() => {
      return `${mapStyle || (theme === ThemeVariant.LIGHT ? MAP_STYLE_LIGHT : MAP_STYLE_DARK)}?key=${process.env.NEXT_PUBLIC_MAPTILER_API_KEY}`;
    }, [mapStyle, theme]);

    if (!process.env.NEXT_PUBLIC_MAPTILER_API_KEY) {
      return <h2>Missing MapTiler API key</h2>;
    }

    return (
      <MaplibreMap
        ref={ref as RefObject<MapRef> | null}
        initialViewState={viewState || DEFAULT_VIEWSTATE}
        style={style}
        mapStyle={mapStyleSource}
        onLoad={onMapLoad}
        onMouseMove={onMapHover}
        onClick={onMapClick}
        renderWorldCopies={renderWorldCopies}
        attributionControl={!hideAttributionControl}
      >
        {children}
      </MaplibreMap>
    );
  }
);
Map.displayName = 'Map';

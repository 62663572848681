import styled from 'styled-components';

import { ASIDE_WIDTH, HEADER_HEIGHT } from '@/core/components/Layout/Layout.styles';
import { TextBodyNormal } from '@/core/components/Typography/Typography.styles';
import { StyledIcon } from '@/core/components/Icons/Icon.styles';
import { FILTERS_MODAL_Z_INDEX } from '@/core/constants/zIndex';

import { TOOLBAR_ANIMATION_TIME } from '@/features/Map/components/Toolbar/Toolbar.styles';

export const FilterButton = styled(TextBodyNormal).attrs({ as: 'button' })<{
  isActive: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  height: 48px;
  border: none;
  padding: 0 20px;
  background-color: transparent;
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.primaryText : theme.colors.secondaryText};
  transition: color 0.2s ease-in-out;
  cursor: pointer;

  path {
    fill: ${({ isActive, theme }) =>
      isActive ? theme.colors.primaryText : theme.colors.secondaryText};
    transition: fill 0.2s ease-in-out;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primaryText};

    path {
      fill: ${({ theme }) => theme.colors.primaryText};
    }
  }
`;

export const FilterListItemWrapper = styled.ul`
  padding: 0 20px;
  list-style: none;
`;

export const FilterListItem = styled(TextBodyNormal).attrs({ as: 'li' })`
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  margin: 0 0.5ex 0.5ex 0;
  border-radius: 6px;
  padding: 6px 10px;
  background-color: ${({ theme }) => theme.colors.filter.filterBadgeBgColor};
  color: ${({ theme }) => theme.colors.white};
`;

export const FilterListItemRemoveIcon = styled(StyledIcon).attrs({ name: 'Close' })<{
  color?: string;
}>`
  cursor: pointer;
  fill: ${({ theme, color }) => (color !== undefined ? color : theme.colors.primaryText)};

  /* stylelint-disable-next-line no-descending-specificity */
  path {
    fill: ${({ theme, color }) => (color !== undefined ? color : theme.colors.primaryText)};
  }
`;

export const FiltersModal = styled.div`
  position: fixed;
  bottom: 0;
  left: ${ASIDE_WIDTH};
  z-index: ${FILTERS_MODAL_Z_INDEX};
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: max-content;
  height: calc(100% - ${HEADER_HEIGHT});
  box-shadow: inset 16px 0 24px ${({ theme }) => `${theme.colors.background.dark}`};
  padding: 24px 40px;
  background-color: ${({ theme }) => theme.colors.modalBackground};
  scrollbar-width: thin;
  transition: transform ${({ theme }) => (theme.useAnimations ? TOOLBAR_ANIMATION_TIME : 0)}s
    ease-in-out;
  transform: translateX(-100%);

  &.enter,
  &.enter-done {
    transform: translateX(0);
  }
`;

export const FilterModalViewBackButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
`;

export const FilterHeader = styled.header`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 24px;
  text-transform: uppercase;
`;

export const FilterModalCloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  margin-left: auto;
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;

  /* stylelint-disable-next-line no-descending-specificity */
  path {
    fill: ${({ theme }) => theme.colors.primaryText};
  }
`;

export const FilterApplyButtonWrapper = styled.div`
  width: 90px;
  height: 46px;
  margin-top: 40px;
`;

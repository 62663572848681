import { IconNames } from '@/core/components/Icons/Icon.types';

export type IconPickerIcons = Extract<
  IconNames,
  | 'AlertAttentionDangerShield'
  | 'AlertAttentionDangerTriangle'
  | 'BiohazardHazard'
  | 'BuildingBank'
  | 'BuildingEmbassy'
  | 'BuildingGovernment'
  | 'BuildingHospital'
  | 'BuildingHotel'
  | 'BuildingHouseHome'
  | 'BuildingHouseHomeUser'
  | 'BuildingOffice'
  | 'BuildingSchoolUniversityCollege'
  | 'Car'
  | 'ChemicalPharmaceuticalLaboratory'
  | 'ConstructionSiteExcavator'
  | 'CourtSportStadium'
  | 'DatabaseDataDataCenter'
  | 'Explosion'
  | 'FenceBorderFrontier'
  | 'Flag'
  | 'Globe'
  | 'GlobeEarth'
  | 'IndustryFactoryProduction'
  | 'LocationDotPinMap'
  | 'NuclearPowerplant'
  | 'NuclearPowerplantAtom'
  | 'NuclearRadiationHazard'
  | 'OilGas'
  | 'OilPlatformGasRig'
  | 'PassportBorderFrontier'
  | 'PersonPeopleUsers'
  | 'PersonPeopleUsersGroup'
  | 'PersonPoliceSecurity'
  | 'PersonUser'
  | 'PetrolGasPump'
  | 'PlaneAirportDeparture'
  | 'Plane'
  | 'PlaneUp'
  | 'RefugeeCampVillage'
  | 'ShipBoat'
  | 'ShipCranePortTerminal'
  | 'ShipPortHarborHarbourFerryBoatCargo'
  | 'StarFavourite'
  | 'TargetCrosshairsDot'
  | 'TargetCrosshairsSimple'
  | 'TrainTrainstationSubwayRailway'
  | 'Truck'
  | 'WarehouseStorageFullfillmentSupplier'
  | 'WarehouseSupplier'
>;

export type IconPickerIcon = { iconName: IconPickerIcons; iconPath: string };

export const iconPickerIcons: Array<IconPickerIcon> = [
  {
    iconName: 'AlertAttentionDangerShield',
    iconPath: '/assets/map/icons/alert-attention-danger-shield-exclamation.svg',
  },
  {
    iconName: 'AlertAttentionDangerTriangle',
    iconPath: '/assets/map/icons/alert-attention-danger-triangle-exclamation.svg',
  },
  {
    iconName: 'BiohazardHazard',
    iconPath: '/assets/map/icons/biohazard-hazard.svg',
  },
  {
    iconName: 'BuildingBank',
    iconPath: '/assets/map/icons/building-bank.svg',
  },
  {
    iconName: 'BuildingEmbassy',
    iconPath: '/assets/map/icons/building-embassy.svg',
  },
  {
    iconName: 'BuildingGovernment',
    iconPath: '/assets/map/icons/building-government.svg',
  },
  {
    iconName: 'BuildingHospital',
    iconPath: '/assets/map/icons/building-hospital.svg',
  },
  {
    iconName: 'BuildingHotel',
    iconPath: '/assets/map/icons/building-hotel.svg',
  },
  {
    iconName: 'BuildingHouseHome',
    iconPath: '/assets/map/icons/building-house-home.svg',
  },
  {
    iconName: 'BuildingHouseHomeUser',
    iconPath: '/assets/map/icons/building-house-home-user.svg',
  },
  {
    iconName: 'BuildingOffice',
    iconPath: '/assets/map/icons/building-office.svg',
  },
  {
    iconName: 'BuildingSchoolUniversityCollege',
    iconPath: '/assets/map/icons/building-school-university-college.svg',
  },
  {
    iconName: 'Car',
    iconPath: '/assets/map/icons/car.svg',
  },
  {
    iconName: 'ChemicalPharmaceuticalLaboratory',
    iconPath: '/assets/map/icons/chemical-pharmaceutical-laboratory.svg',
  },
  {
    iconName: 'ConstructionSiteExcavator',
    iconPath: '/assets/map/icons/construction-site-excavator.svg',
  },
  {
    iconName: 'CourtSportStadium',
    iconPath: '/assets/map/icons/court-sport-stadium.svg',
  },
  {
    iconName: 'DatabaseDataDataCenter',
    iconPath: '/assets/map/icons/database-data-data-center.svg',
  },
  {
    iconName: 'Explosion',
    iconPath: '/assets/map/icons/explosion.svg',
  },
  {
    iconName: 'FenceBorderFrontier',
    iconPath: '/assets/map/icons/fence-border-frontier.svg',
  },
  {
    iconName: 'Flag',
    iconPath: '/assets/map/icons/flag.svg',
  },
  {
    iconName: 'Globe',
    iconPath: '/assets/map/icons/globe.svg',
  },
  {
    iconName: 'GlobeEarth',
    iconPath: '/assets/map/icons/globe-earth.svg',
  },
  {
    iconName: 'IndustryFactoryProduction',
    iconPath: '/assets/map/icons/industry-factory-production.svg',
  },
  {
    iconName: 'LocationDotPinMap',
    iconPath: '/assets/map/icons/location-dot-pin-map.svg',
  },
  {
    iconName: 'NuclearPowerplant',
    iconPath: '/assets/map/icons/nuclear-powerplant.svg',
  },
  {
    iconName: 'NuclearPowerplantAtom',
    iconPath: '/assets/map/icons/nuclear-powerplant-atom.svg',
  },
  {
    iconName: 'NuclearRadiationHazard',
    iconPath: '/assets/map/icons/nuclear-radiation-hazard.svg',
  },
  {
    iconName: 'OilGas',
    iconPath: '/assets/map/icons/oil-gas.svg',
  },
  {
    iconName: 'OilPlatformGasRig',
    iconPath: '/assets/map/icons/oil-platform-gas-rig.svg',
  },
  {
    iconName: 'PassportBorderFrontier',
    iconPath: '/assets/map/icons/passport-border-frontier.svg',
  },
  {
    iconName: 'PersonPeopleUsers',
    iconPath: '/assets/map/icons/person-people-users.svg',
  },
  {
    iconName: 'PersonPeopleUsersGroup',
    iconPath: '/assets/map/icons/person-people-users-group.svg',
  },
  {
    iconName: 'PersonPoliceSecurity',
    iconPath: '/assets/map/icons/person-police-security.svg',
  },
  {
    iconName: 'PersonUser',
    iconPath: '/assets/map/icons/person-user.svg',
  },
  {
    iconName: 'PetrolGasPump',
    iconPath: '/assets/map/icons/petrol-gas-pump.svg',
  },
  {
    iconName: 'PlaneAirportDeparture',
    iconPath: '/assets/map/icons/plane-airport-departure.svg',
  },
  {
    iconName: 'Plane',
    iconPath: '/assets/map/icons/plane.svg',
  },
  {
    iconName: 'PlaneUp',
    iconPath: '/assets/map/icons/plane-up.svg',
  },
  {
    iconName: 'RefugeeCampVillage',
    iconPath: '/assets/map/icons/refugee-camp-village.svg',
  },
  {
    iconName: 'ShipBoat',
    iconPath: '/assets/map/icons/ship-boat.svg',
  },
  {
    iconName: 'ShipCranePortTerminal',
    iconPath: '/assets/map/icons/ship-crane-port-terminal.svg',
  },
  {
    iconName: 'ShipPortHarborHarbourFerryBoatCargo',
    iconPath: '/assets/map/icons/ship-port-harbor-harbour-ferry-boat-cargo.svg',
  },
  {
    iconName: 'StarFavourite',
    iconPath: '/assets/map/icons/star-favourite.svg',
  },
  {
    iconName: 'TargetCrosshairsDot',
    iconPath: '/assets/map/icons/target-crosshairs-dot.svg',
  },
  {
    iconName: 'TargetCrosshairsSimple',
    iconPath: '/assets/map/icons/target-crosshairs-simple.svg',
  },
  {
    iconName: 'TrainTrainstationSubwayRailway',
    iconPath: '/assets/map/icons/train-trainstation-subway-railway.svg',
  },
  {
    iconName: 'Truck',
    iconPath: '/assets/map/icons/truck.svg',
  },
  {
    iconName: 'WarehouseStorageFullfillmentSupplier',
    iconPath: '/assets/map/icons/warehouse-storage-fullfillment-supplier.svg',
  },
  {
    iconName: 'WarehouseSupplier',
    iconPath: '/assets/map/icons/warehouse-supplier.svg',
  },
];

import styled from 'styled-components';

export const SearchWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const SuggestionsList = styled.ul`
  position: absolute;
  top: calc(100% + 4px);
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 0;
  border-radius: 6px;
  padding: 10px 0;
  background-color: ${({ theme }) => theme.colors.background.light};
  list-style: none;
`;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '@/core/api';

import { getActionPrefix } from '@/utils/helpers';

const actionPrefix = getActionPrefix('auth');

export const getCategories = createAsyncThunk(`${actionPrefix}/getCategories`, async () => {
  const { data } = await api.getCategoires();

  return data;
});

export const getCountries = createAsyncThunk(`${actionPrefix}/getCountries`, async () => {
  const { data } = await api.getCountries();

  return data;
});

export const getAddressPrediction = createAsyncThunk(
  `${actionPrefix}/getGoogleAutocomplete`,
  async (input: string) => {
    const { data } = await api.getAddressPrediction(input);

    return data;
  }
);

export const getAddressDetails = createAsyncThunk(
  `${actionPrefix}/getGooglePlaceDetails`,
  async (placeId: string) => {
    const { data } = await api.getAddressDetails(placeId);

    return data;
  }
);

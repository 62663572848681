import { useEffect, useState, useRef, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { unwrapResult } from '@reduxjs/toolkit';

import { useOnClickOutside } from '@/core/hooks/useOnClickOutside';
import { Input } from '@/core/components/Input';
import { SearchSuggestionsList } from '@/core/components/SearchSuggestionsList';
import { useAppDispatch } from '@/core/store/store';
import { getAddressDetails, getAddressPrediction } from '@/core/store/actions/config';

import { SearchWrapper } from './LocationSearch.styles';
import { LocationSearchProps, SuggestionType } from './LocationSearch.types';

const coordinatesRegex =
  /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

const getSearchTermToSet = ({
  location,
  searchTerm,
  suggestions,
}: Pick<LocationSearchProps, 'location' | 'suggestions'> & {
  searchTerm?: string;
}) => {
  let searchTermToSet = '';

  if (location?.name && !searchTerm) {
    searchTermToSet = location.name;
  } else if (!suggestions || suggestions.length === 0) {
    searchTermToSet = location?.name || '';
  } else if (location?.coordinates) {
    const suggestionWithName = suggestions.find(
      suggestion =>
        suggestion.coordinates?.lat === location.coordinates?.lat &&
        suggestion.coordinates?.lng === location.coordinates?.lng
    );

    if (suggestionWithName) {
      searchTermToSet = suggestionWithName.name;
    } else {
      searchTermToSet = `${location.coordinates.lat}, ${location.coordinates.lng}`;
    }
  }

  return searchTermToSet;
};

export const LocationSearch = ({
  label,
  location,
  placeholder,
  suggestions,
  usePortal = false,
  onSelectLocation,
}: LocationSearchProps) => {
  const isMounted = useRef(false);
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState(
    getSearchTermToSet({
      location,
      suggestions,
    })
  );
  const [filteredSuggestions, setFilteredSuggestions] = useState<Array<SuggestionType>>([]);

  const locationSearchRef = useRef<HTMLDivElement>(null);

  const getInputOffsetX = useCallback(() => {
    return usePortal ? locationSearchRef.current?.getBoundingClientRect().left || 0 : 0;
  }, [usePortal]);
  const getInputOffsetY = useCallback(() => {
    return usePortal
      ? (locationSearchRef.current?.getBoundingClientRect().top || 0) +
          (locationSearchRef.current?.getBoundingClientRect().height || 0) +
          4
      : 0;
  }, [usePortal]);
  const getInputWidth = useCallback(() => {
    return usePortal ? locationSearchRef.current?.getBoundingClientRect().width : undefined;
  }, [usePortal]);

  const [suggestionsOffsetX, setSuggestionsOffsetX] = useState(getInputOffsetX());
  const [suggestionsOffsetY, setSuggestionsOffsetY] = useState(getInputOffsetY());
  const [suggestionsWidth, setSuggestionsWidth] = useState(getInputWidth());

  useOnClickOutside(locationSearchRef, () => {
    setTimeout(() => {
      setFilteredSuggestions([]);
    }, 250);
  });

  useEffect(() => {
    setSuggestionsOffsetX(getInputOffsetX());
    setSuggestionsOffsetY(getInputOffsetY());
    setSuggestionsWidth(getInputWidth());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredSuggestions.length]);

  useEffect(() => {
    const handleResize = () => {
      setSuggestionsOffsetX(getInputOffsetX());
      setSuggestionsOffsetY(getInputOffsetY());
      setSuggestionsWidth(getInputWidth());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = async (term: string) => {
    if (coordinatesRegex.test(term)) {
      const [lat, lng] = term.split(',').map(Number);

      setFilteredSuggestions([
        {
          name: term,
          coordinates: {
            lat,
            lng,
          },
        },
      ]);
    } else if (!suggestions || suggestions.length === 0) {
      const autocompleteResults = unwrapResult(await dispatch(getAddressPrediction(term)));

      setFilteredSuggestions(
        autocompleteResults.predictions.map(prediction => ({
          name: prediction.description,
          id: prediction.placeId,
          source: 'autocomplete',
          types: prediction.types,
        }))
      );
    } else {
      const filtered = suggestions.filter(suggestion =>
        suggestion.name.toLowerCase().includes(term.toLowerCase())
      );

      if (term.length >= 2 && filtered) {
        setFilteredSuggestions(filtered);
      } else {
        setFilteredSuggestions([]);
      }
    }
  };

  const onSelectSuggestion = async (suggestion: SuggestionType) => {
    setSearchTerm(suggestion.name);

    let suggestionItem = suggestion;

    if (suggestion.source === 'autocomplete') {
      const placeDetails = unwrapResult(await dispatch(getAddressDetails(suggestion.id as string)));

      suggestionItem = {
        name: placeDetails.result.formattedAddress,
        coordinates: placeDetails.result.geometry.location,
        types: suggestion.types,
      };
    }
    onSelectLocation(suggestionItem);
    setFilteredSuggestions([]);
  };

  useEffect(() => {
    if (isMounted.current) {
      handleSearch(searchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    if (location?.name === searchTerm) {
      return;
    }

    setSearchTerm(
      getSearchTermToSet({
        location,
        suggestions,
        searchTerm,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <SearchWrapper ref={locationSearchRef}>
      <Input
        label={label}
        placeholder={placeholder}
        autoComplete="off"
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
      />
      {filteredSuggestions.length > 0 &&
        createPortal(
          <SearchSuggestionsList
            offsetX={suggestionsOffsetX}
            offsetY={suggestionsOffsetY}
            width={suggestionsWidth}
            suggestionsList={filteredSuggestions}
            onSelectSuggestion={onSelectSuggestion}
          />,
          // @ts-expect-error locationSearchRef is defined
          usePortal ? document.body : locationSearchRef.current
        )}
    </SearchWrapper>
  );
};

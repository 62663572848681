import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { SessionProvider } from 'next-auth/react';
import omit from 'lodash/omit';

import { wrapper } from '@/core/store/store';
import { Layout } from '@/core/components/Layout';
import { Session } from '@/core/components/Session';
import { Translation } from '@/core/components/Translation';
import { initializeApp } from '@/core/store/reducers/config';
import { CustomThemeProvider } from '@/core/context/CustomThemeContext/CustomThemeContext';
import { MapProvider } from '@/core/context/MapContext';
import { GetInitialData } from '@/core/components/GetInitialData';

const MyApp = ({ Component, ...rest }: AppProps) => {
  const { store, props } = wrapper.useWrappedStore(rest);
  const [isAppMounted, setIsAppMounted] = useState(false);

  const { dispatch } = store;

  useEffect(() => {
    dispatch(initializeApp());
    setIsAppMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cleanedPageProps = omit(props.pageProps, ['messages']);

  // @ts-expect-error Page component might have property shouldHideMainSidebar
  const shouldHideMainSidebar = Component.shouldHideMainSidebar || false;
  // @ts-expect-error Page component might have property hideFullLayout
  const hideFullLayout = Component.hideFullLayout || false;

  return (
    <Provider store={store}>
      <SessionProvider session={props.session}>
        <CustomThemeProvider>
          <Head>
            <title key="title">Hozint</title>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1"
            />
          </Head>
          <Translation serverMessages={props?.messages}>
            {isAppMounted ? (
              hideFullLayout ? (
                <Component {...cleanedPageProps} />
              ) : (
                <MapProvider>
                  <Layout shouldHideMainSidebar={shouldHideMainSidebar}>
                    <Session>
                      <GetInitialData />
                      <Component {...cleanedPageProps} />
                    </Session>
                  </Layout>
                </MapProvider>
              )
            ) : null}
          </Translation>
        </CustomThemeProvider>
      </SessionProvider>
    </Provider>
  );
};

export default MyApp;

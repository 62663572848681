import { useCallback, useEffect, useMemo } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';

import { CreateRadialGeofence } from '@/core/components/CreateRadialGeofence';
import { useAppDispatch } from '@/core/store/store';
import {
  GEOJSONData,
  GEOJSONShapeType,
  LineGEOJSONData,
  PointOfInterestGEOJSONData,
  PolygonGEOJSONData,
  RadialGEOJSONData,
} from '@/core/interfaces/geojsons';
import { CreatePolygonGeofence } from '@/core/components/CreatePolygonGeofence';
import { CreateLineGeofence } from '@/core/components/CreateLineGeofence';
import { CreatePointOfInterest } from '@/core/components/CreatePointOfInterest';
import { SelectOptionType } from '@/core/components/Select/SelectComponent.types';

import { changeGeofeatureVisibility } from '@/features/Geofeatures/store';
import {
  createLineGeofeature,
  createPointOfInterestGeofeature,
  createPolygonGeofeature,
  createRadialGeofeature,
  updateLineGeofeatureData,
  updatePointOfInterestGeofeatureData,
  updatePolygonGeofeatureData,
  updateRadialGeofeatureData,
} from '@/features/Geofeatures/store/actions';
import { GeofeatureViewType } from '@/features/Geofeatures/components/GeofeatureModal/GeofeatureModal.types';

import { GeofeatureViewProps } from './GeofeatureView.types';

const isInitialDataRadial = (initialData?: GEOJSONData): initialData is RadialGEOJSONData =>
  initialData?.properties?.shape === GEOJSONShapeType.CIRCLE;
const isInitialDataPolygon = (initialData?: GEOJSONData): initialData is PolygonGEOJSONData =>
  initialData?.properties?.shape === GEOJSONShapeType.POLYGON;
const isInitialDataLine = (initialData?: GEOJSONData): initialData is LineGEOJSONData =>
  initialData?.properties?.shape === GEOJSONShapeType.LINE;
const isInitialDataPointOfInterest = (
  initialData?: GEOJSONData
): initialData is PointOfInterestGEOJSONData =>
  initialData?.properties?.shape === GEOJSONShapeType.POI;

export const GeofeatureView = ({
  visibleGeofeature,
  defaultFolder,
  initialData,
  handleFormClose,
}: GeofeatureViewProps) => {
  const dispatch = useAppDispatch();

  const onRadialFormSubmit = useCallback(
    async (geoJSON: RadialGEOJSONData) => {
      if (initialData) {
        const { center: _center, ...properties } = geoJSON.properties;

        unwrapResult(
          await dispatch(
            updateRadialGeofeatureData({
              geofeatureId: initialData.properties.id,
              data: {
                ...geoJSON,
                properties: {
                  ...properties,
                  color: geoJSON.properties.color,
                  description: geoJSON.properties.description,
                  folder: geoJSON.properties.folder!,
                  icon: geoJSON.properties.icon,
                  name: geoJSON.properties.name,
                  tags: geoJSON.properties.tags,
                  lat: geoJSON.properties.center.lat,
                  long: geoJSON.properties.center.lng,
                },
              },
            })
          )
        );
      } else {
        unwrapResult(
          await dispatch(
            createRadialGeofeature({
              ...geoJSON,
              properties: {
                color: geoJSON.properties.color,
                icon: geoJSON.properties.icon,
                name: geoJSON.properties.name,
                description: geoJSON.properties.description,
                folder: geoJSON.properties.folder!,
                center: {
                  lat: geoJSON.properties.center.lat,
                  lng: geoJSON.properties.center.lng,
                },
                tags: geoJSON.properties.tags,
                radius: geoJSON.properties.radius,
                radiusUnit: geoJSON.properties.radiusUnit,
                radiusConverted: 0,
                shape: GEOJSONShapeType.CIRCLE,
                address: geoJSON.properties.address,
              },
            })
          )
        );
      }

      handleFormClose();
    },
    [dispatch, handleFormClose, initialData]
  );

  const onPolygonFormSubmit = useCallback(
    async (geoJSON: PolygonGEOJSONData) => {
      if (initialData) {
        unwrapResult(
          await dispatch(
            updatePolygonGeofeatureData({
              geofeatureId: initialData.properties.id,
              data: {
                ...geoJSON,
                properties: {
                  color: geoJSON.properties.color,
                  description: geoJSON.properties.description,
                  folder: geoJSON.properties.folder!,
                  icon: geoJSON.properties.icon,
                  name: geoJSON.properties.name,
                  tags: geoJSON.properties.tags,
                  shape: GEOJSONShapeType.POLYGON,
                },
              },
            })
          )
        );
      } else {
        unwrapResult(
          await dispatch(
            createPolygonGeofeature({
              ...geoJSON,
              properties: {
                ...geoJSON.properties,
                folder: geoJSON.properties.folder!,
              },
            })
          )
        );
      }

      handleFormClose();
    },
    [dispatch, handleFormClose, initialData]
  );

  const onLineFormSubmit = useCallback(
    async (geoJSON: LineGEOJSONData) => {
      if (initialData) {
        unwrapResult(
          await dispatch(
            updateLineGeofeatureData({
              geofeatureId: initialData.properties.id,
              data: {
                ...geoJSON,
                properties: {
                  color: geoJSON.properties.color,
                  description: geoJSON.properties.description,
                  folder: geoJSON.properties.folder!,
                  icon: geoJSON.properties.icon,
                  name: geoJSON.properties.name,
                  tags: geoJSON.properties.tags,
                  radius: geoJSON.properties.radius,
                  radiusUnit: geoJSON.properties.radiusUnit,
                  shape: GEOJSONShapeType.LINE,
                },
              },
            })
          )
        );
      } else {
        unwrapResult(
          await dispatch(
            createLineGeofeature({
              ...geoJSON,
              properties: {
                color: geoJSON.properties.color,
                icon: geoJSON.properties.icon,
                name: geoJSON.properties.name,
                description: geoJSON.properties.description,
                folder: geoJSON.properties.folder!,
                tags: geoJSON.properties.tags,
                shape: GEOJSONShapeType.LINE,
                radius: geoJSON.properties.radius,
                radiusUnit: geoJSON.properties.radiusUnit,
              },
            })
          )
        );
      }

      handleFormClose();
    },
    [dispatch, handleFormClose, initialData]
  );

  const onPointOfInterestFormSubmit = useCallback(
    async (geoJSON: PointOfInterestGEOJSONData) => {
      if (initialData) {
        unwrapResult(
          await dispatch(
            updatePointOfInterestGeofeatureData({
              geofeatureId: initialData.properties.id,
              data: {
                ...geoJSON,
                properties: {
                  color: geoJSON.properties.color,
                  description: geoJSON.properties.description,
                  folder: geoJSON.properties.folder!,
                  icon: geoJSON.properties.icon,
                  name: geoJSON.properties.name,
                  tags: geoJSON.properties.tags,
                  shape: GEOJSONShapeType.POI,
                  address: geoJSON.properties.address,
                },
              },
            })
          )
        );
      } else {
        unwrapResult(
          await dispatch(
            createPointOfInterestGeofeature({
              ...geoJSON,
              properties: {
                ...geoJSON.properties,
                folder: geoJSON.properties.folder!,
              },
            })
          )
        );
      }

      handleFormClose();
    },
    [dispatch, handleFormClose, initialData]
  );

  useEffect(() => {
    if (initialData) {
      dispatch(
        changeGeofeatureVisibility({
          geofeatureId: initialData.properties.id,
          visibility: false,
        })
      );
    }

    return () => {
      if (initialData) {
        dispatch(
          changeGeofeatureVisibility({
            geofeatureId: initialData.properties.id,
            visibility: true,
          })
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultFolderAsSelectOption = useMemo<SelectOptionType<number> | undefined>(() => {
    if (!defaultFolder) {
      return undefined;
    }

    return {
      label: defaultFolder.name,
      value: defaultFolder.id,
    };
  }, [defaultFolder]);

  if (
    visibleGeofeature === GeofeatureViewType.RADIAL &&
    (!initialData || isInitialDataRadial(initialData))
  ) {
    return (
      <CreateRadialGeofence
        initialData={initialData}
        defaultFolder={defaultFolderAsSelectOption}
        onFormSubmit={onRadialFormSubmit}
        onFormCancel={handleFormClose}
      />
    );
  }

  if (
    visibleGeofeature === GeofeatureViewType.POLYGON &&
    (!initialData || isInitialDataPolygon(initialData))
  ) {
    return (
      <CreatePolygonGeofence
        initialData={initialData}
        defaultFolder={defaultFolderAsSelectOption}
        onFormSubmit={onPolygonFormSubmit}
        onFormCancel={handleFormClose}
      />
    );
  }

  if (
    visibleGeofeature === GeofeatureViewType.LINE &&
    (!initialData || isInitialDataLine(initialData))
  ) {
    return (
      <CreateLineGeofence
        initialData={initialData}
        defaultFolder={defaultFolderAsSelectOption}
        onFormSubmit={onLineFormSubmit}
        onFormCancel={handleFormClose}
      />
    );
  }

  if (
    visibleGeofeature === GeofeatureViewType.POI &&
    (!initialData || isInitialDataPointOfInterest(initialData))
  ) {
    return (
      <CreatePointOfInterest
        initialData={initialData}
        defaultFolder={defaultFolderAsSelectOption}
        onFormSubmit={onPointOfInterestFormSubmit}
        onFormCancel={handleFormClose}
      />
    );
  }

  return null;
};

import { MergeElementProps } from '@/core/interfaces/common';

export enum ButtonVariant {
  PRIMARY,
  SECONDARY,
  TERTIARY,
  WARNING,
}

export enum ButtonSize {
  SMALL,
  MEDIUM,
}

interface CustomProps {
  type?: 'button' | 'submit' | 'reset';
  variant?: ButtonVariant;
  buttonSize?: ButtonSize;
}

export type ButtonProps = MergeElementProps<'button', CustomProps>;

import styled from 'styled-components';

import { UnstyledIcon } from './UnstyledIcon';

export const StyledIcon = styled(UnstyledIcon)<{
  color?: string;
  size?: number;
  onClick?: () => void;
}>`
  width: ${({ size }) => (size !== undefined ? `${size}px` : '16px')};
  height: ${({ size }) => (size !== undefined ? `${size}px` : '16px')};
  fill: ${({ color, theme }) => color || theme.colors.primaryText};

  path {
    fill: ${({ color }) => color};
  }
`;
